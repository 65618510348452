<template>
  <div>
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf ">
          <div class="widget-head fl">
            <div class="widget-title fl">历史数据(工程价格)</div>
            <div class="fr">
              <div class="fl pr20">单位:元/吨</div>
            </div>
          </div>
          <div class="widget-body am-cf">
            <div>
              <table class="layui-table">
                <thead>
                  <tr>
                    <th colspan="8">{{starttimes}} 至 {{endtimes}} 平均数据</th>
                  </tr>
                  <tr>
                    <th>品牌</th>
                    <th>规格</th>
                    <th>参考价格平均</th>
                    <!-- <th>现货价格平均</th> -->
                    <!-- <th>现货价差平均</th> -->
                    <th>工程价格平均</th>
                    <th>工程价差平均</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in brandlist"
                      :key="item.id">
                    <td>{{item.name}}</td>
                    <td>{{catespec}} {{size}}</td>
                    <td>{{item.avg_wangjia}}</td>
                    <td>{{item.avg_gongchengjia}}</td>
                    <td>{{item.avg_gongchengjiajiaca}}</td>
                    <!-- <td v-if="arrivaltype===1">{{item.avg_quanbudaohuo}}</td>
                    <td v-else-if="arrivaltype===2">{{item.avg_changtidaohuo}}</td>
                    <td v-else-if="arrivaltype===3">{{item.avg_zhisongdaohuo}}</td>
                    <td v-if="arrivaltypey===1">{{item.avg_quanbudaohuojiaca}}</td>
                    <td v-else-if="arrivaltypey===2">{{item.avg_changtidaohuojiaca}}</td>
                    <td v-else-if="arrivaltypey===3">{{item.avg_zhisongdaohuojiaca}}</td> -->
                  </tr>
                </tbody>
              </table>
              <p style=" padding-top:10px">注：因个别日期现货市场缺货，此处可能存在“平均价差≠平均参考价-平均工程价”的偏差，敬请留意。</p>
            </div>
            <el-divider></el-divider>
            <div ref="myChart"
                 class="myChart"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               id="guide-menu"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i></div>
  </div>
</template>

<script>
import { getUserNotice } from '@/api/global'
import Historical from '@/components/Engineerhistory'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      activeName: '1',
      curTabcompnents: {
        1: 'Historical'
      },
      myChart: 'display:block',
      myCharta: 'display:none',
      brands: [],
      brandlist: [],
      dateBetween: [],
      starttimes: '',
      endtimes: '',
      names: [],
      namesa: [],
      min: '',
      mina: '',
      historicallist: [{
        data: []
      }, {
        data: []
      }, {
        data: []
      }],
      historicallists: [],
      arrivaltype: '',
      arrivaltypey: '',
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      catespec: '',
      size: '',
      topnotice: '',
      isstatus: 0
    }
  },
  components: { Historical },
  created () {
    // 注册自定义事件
    globalBus.$on('HistoricalData', (res) => {
      // console.log(res.datatype) // 数据类型 2 工程价格 3 参考价格 5 工程价差
      // console.log(res.arrivaltype) // 到货类型 1 全部 2 纯仓提 3 纯直提
      this.brands = res.brands
      this.brandlist = res.data.brand_data
      this.starttimes = res.StartDate
      this.endtimes = res.EndDate
      this.catespec = res.catespec
      this.size = res.size
      // this.brandlist = res.data.brand_data.map(val => {
      // val.changtidaohuo = this.getEmptyArray(val.changtidaohuo)
      // val.changtidaohuojiaca = this.getEmptyArray(val.changtidaohuojiaca)
      // val.quanbudaohuo = this.getEmptyArray(val.quanbudaohuo)
      // val.quanbudaohuojiaca = this.getEmptyArray(val.quanbudaohuojiaca)
      // val.wangjia = this.getEmptyArray(val.wangjia)
      // val.xianhuojiaca = this.getEmptyArray(val.xianhuojiaca)
      // val.zhisongdaohuo = this.getEmptyArray(val.zhisongdaohuo)
      // val.zhisongdaohuojiaca = this.getEmptyArray(val.zhisongdaohuojiaca)
      // return val
      // })
      this.arrivaltype = res.arrivaltype
      this.arrivaltypey = res.arrivaltype
      if (res.brands.length > 1) {
        this.namesa = res.brands
        // 工程价格
        if (res.datatype === 2) {
          if (res.max_and_min[0] > 4000) {
            this.mina = 4000
          } else {
            this.mina = 3000
          }
          for (var a = 0; a < res.brands.length; a++) {
            this.historicallists[a] = this.getEmptyArray(res.data.brand_data[a].gongchengjia) // 工程价
          }
        }
        // 参考价格
        if (res.datatype === 3) {
          if (res.max_and_min[0] > 4000) {
            this.mina = 4000
          } else {
            this.mina = 3000
          }
          for (var b = 0; b < res.brands.length; b++) {
            this.historicallists[b] = this.getEmptyArray(res.data.brand_data[b].wangjia) // 参考价
          }
        }
        //  到货价差
        if (res.datatype === 5) {
          this.mina = 0
          for (var d = 0; d < res.brands.length; d++) {
            this.historicallists[d] = this.getEmptyArray(res.data.brand_data[d].gongchengjiajiaca) // 工程价差
          }
        }
        // this.myChart = 'display:none'
        // this.myCharta = 'display:block'
        this.drawLinea(res.brands.length, this.mina, res.data.date)
      } else {
        this.brand = res.data.brand_data[0].name
        this.names = ['工程价格', '工程价差', '参考价格']
        this.historicallist[0].data = this.getEmptyArray(res.data.brand_data[0].gongchengjia) // 工程价
        this.historicallist[1].data = this.getEmptyArray(res.data.brand_data[0].gongchengjiajiaca) // 工程价差
        this.historicallist[2].data = this.getEmptyArray(res.data.brand_data[0].wangjia)// 参考价
        this.avg_wangjia = res.data.brand_data[0].avg_wangjia
        if (res.max_and_min[0] > 4000) {
          this.min = 4000
        } else {
          this.min = 3000
        }

        this.myChart = 'display:block'
        this.myCharta = 'display:none'
        this.drawLine(this.min, res.data.date)
      }
    })
  },
  mounted () {
    this.loadgetUserNotice()
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top:92px'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    },
    getEmptyArray (marketData) {
      marketData.forEach((val, key, array) => {
        array[key] = this.formateNullFieldData(val)
      })
      return marketData
    },
    formateNullFieldData (val) {
      // null、字符串为空（注意存在数值为0时），带空格字符串，后台处理成的'-'
      if (!String(val) || String(val) === 'null' || !String(val).replace(/\s+/g, '') || val === '-') {
        return '--'
      }
      return val
    },
    drawLine (min, dateBetween) {
      var echarts = require('echarts')
      var myChart = echarts.init(this.$refs.myChart)
      const xAxisData = dateBetween
      const color = ['#FFC516', '#8B5CFF', '#00CA69', '#fca4bb', '#0690f3']
      const yAxisData1 = this.historicallist[0].data
      const yAxisData2 = this.historicallist[1].data
      const yAxisData3 = this.historicallist[2].data
      // const yAxisData4 = this.historicallist[3].data
      // const yAxisData5 = this.historicallist[4].data
      const option = {
        backgroundColor: '#fff',
        color: color,
        legend: {
          top: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let html = ''
            params.forEach(v => {
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                ${v.seriesName} ${v.name}         
                <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px;margin-left:5px">${v.value}</span>元`
            })
            return html
          },
          extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;'
          // axisPointer: {
          // type: 'shadow',
          // shadowStyle: {
          //  color: '#ffffff',
          // shadowColor: 'rgba(225,225,225,1)',
          // shadowBlur: 5
          // }
          // }
        },
        // 工具栏，内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        toolbox: {
          show: true,
          // toolbox的配置项
          feature: {
            // 辅助线的开关
            mark: { show: true },
            // 数据视图
            dataView: {
              show: false,
              readOnly: false
            },
            // 动态类型切换
            magicType: {
              show: true,
              // line为折线图，bar为柱状图
              type: ['line', 'bar']
            },
            // 配置项还原
            restore: { show: false },
            // 将图标保存为图片
            saveAsImage: { show: true }
          }
        },
        // 可计算特性
        calculable: true,
        grid: {
          left: '3%',
          right: '2%',
          top: 100,
          bottom: '8%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#333'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9'
            }
          },
          data: xAxisData
        }],
        yAxis: [{
          type: 'value',
          name: '价格',
          min: min,
          splitNumber: 8,
          boundaryGap: [0.2, 0.2],
          axisLabel: {
            textStyle: {
              color: '#666'
            }
          },
          nameTextStyle: {
            color: '#666',
            fontSize: 12,
            lineHeight: 40
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }, {
          type: 'value',
          name: '参考价差',
          axisLabel: {
            textStyle: {
              color: '#666'
            }
          },
          nameTextStyle: {
            color: '#666',
            fontSize: 12,
            lineHeight: 40
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: this.names[0],
          type: 'line',
          smooth: true,
          symbolSize: 8,
          yAxisIndex: 0,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式
          data: yAxisData1
        }, {
          name: this.names[1],
          type: 'line',
          smooth: true,
          symbolSize: 8,
          yAxisIndex: 1,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式 (实心点)
          data: yAxisData2
        }, {
          name: this.names[2],
          type: 'line',
          smooth: true,
          symbolSize: 8,
          yAxisIndex: 0,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式 (实心点)
          data: yAxisData3
        }]
      }
      myChart.clear()
      myChart.setOption(option)
      // 监听点击事件
      window.addEventListener('click', function () {
        myChart.resize()
      })
      // 监听浏览器页面缩放事件
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    drawLinea (bands, min, dateBetween) {
      var echarts = require('echarts')
      var myChart = echarts.init(this.$refs.myChart)
      const xAxisData = dateBetween
      const color = ['#0690f3', '#8B5CFF', '#00CA69']
      const optiona = {
        backgroundColor: '#fff',
        color: color,
        legend: {
          top: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let html = ''
            params.forEach(v => {
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                ${v.seriesName} ${v.name}  
                <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px;margin-left:5px">${v.value}</span>元`
            })
            return html
          },
          extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;'
          // axisPointer: {
          // type: 'shadow',
          // shadowStyle: {
          // color: '#ffffff',
          // shadowColor: 'rgba(225,225,225,1)',
          // shadowBlur: 5
          // }
          // }
        },
        // 工具栏，内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        toolbox: {
          show: true,
          // toolbox的配置项
          feature: {
            // 辅助线的开关
            mark: { show: true },
            // 数据视图
            dataView: {
              show: false,
              readOnly: false
            },
            // 动态类型切换
            magicType: {
              show: true,
              // line为折线图，bar为柱状图
              type: ['line', 'bar']
            },
            // 配置项还原
            restore: { show: false },
            // 将图标保存为图片
            saveAsImage: { show: true }
          }
        },
        // 可计算特性
        calculable: true,
        grid: {
          left: '3%',
          right: '2%',
          top: 100,
          bottom: '8%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#2a2a2a'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#2a2a2a'
            }
          },
          data: xAxisData
        }],
        yAxis: [{
          type: 'value',
          // name: '价格',
          min: min,
          splitNumber: 8,
          boundaryGap: [0.2, 0.2],
          axisLabel: {
            textStyle: {
              color: '#2a2a2a'
            }
          },
          nameTextStyle: {
            color: '#2a2a2a',
            fontSize: 12,
            lineHeight: 40
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        series: this.bandsseries(bands)
      }
      myChart.clear()
      myChart.setOption(optiona)
      // 监听点击事件
      window.addEventListener('click', function () {
        myChart.resize()
      })
      // 监听浏览器页面缩放事件
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    bandsseries (bands) {
      // var echarts = require('echarts')
      // const color = ['#0690f3', '#8B5CFF', '#00CA69', '#fca4bb', '#59678c']
      var series = []
      const self = this
      for (var i = 0; i < bands; i++) {
        var serie = {}
        serie.name = self.namesa[i]
        serie.type = 'line'
        serie.smooth = true
        serie.symbolSize = 8
        serie.zlevel = 3
        // serie.lineStyle = {}
        // serie.lineStyle.normal = {}
        // serie.lineStyle.normal.color = color[i]
        // serie.lineStyle.normal.shadowBlure = 3
        // serie.lineStyle.normal.shadowColor = hexToRgba(color[i], 0.5)
        // serie.lineStyle.normal.shadowOffsetY = 8
        serie.symbol = 'none' // 数据交叉点样式 (实心点)
        // serie.areaStyle = {}
        // serie.areaStyle.normal = {}
        // serie.areaStyle.normal.shadowColor = hexToRgba(color[i], 0.1)
        // serie.areaStyle.normal.shadowBlur = 10
        // serie.areaStyle.normal.color = new echarts.graphic.LinearGradient(
        //   0,
        //  0,
        //  0,
        //  1,
        //  [{
        //    offset: 0,
        //    color: hexToRgba(color[i], 0.3)
        //   },
        //  {
        //    offset: 1,
        //    color: hexToRgba(color[i], 0.1)
        //  }
        //  ],
        //  false
        // )
        serie.data = self.historicallists[i]
        series.push(serie)
      }
      return series
    }
  }
}
</script>

<style lang="less" scoped>
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
}
.layui-side {
  margin-right: 20px;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}
.layui-side-content {
  padding: 20px 0px;
}

/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.myChart {
  width: 100%;
  height: 502px;
  margin-top: 80px;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}

@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 999;
  }
  .myChart {
    height: 280px;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
