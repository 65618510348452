const steps = [
  {
    element: '#guide-menu',
    popover: {
      title: ' ',
      description: '<b>点击可收起侧边栏</b>',
      position: 'left'
    }
  },
  {
    element: '#recommendsearch',
    popover: {
      title: ' ',
      description: '<b>可选择自己想看的数据</b>',
      position: 'left'
    }
  }
]
export default steps
