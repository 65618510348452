<template>
  <div class="layui-layout layui-layout-admin">
    <div class="layui-header">
      <div class="layui-logo"><img src="../assets/images/logobg.png"
             width="100"></div>
      <ul class="layui-nav layui-layout-left">
        <el-menu :default-active="$route.path"
                 class="el-menu-demo"
                 mode="horizontal"
                 background-color="#16469e"
                 text-color="#ffffff"
                 router
                 v-if="power===3">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/realtimeprice">实时价格</el-menu-item>
          <el-menu-item index="/engineer">工程指数</el-menu-item>
          <el-submenu index="/steelstores">
            <template slot="title"><span id="Price">价格跟踪</span></template>
            <el-menu-item index="/spotindex">现货指数</el-menu-item>
            <el-menu-item index="/recommend">资源比价</el-menu-item>
            <el-menu-item index="/historicaldata">历史数据</el-menu-item>
            <el-menu-item index="/historicalengineerdata">历史数据(工程版)</el-menu-item>
          </el-submenu>
          <el-submenu index="/steelstore">
            <template slot="title"><span id="market">市场分析</span></template>
            <el-menu-item index="/steelflow">每日出入库</el-menu-item>
            <el-menu-item index="/steelstore">每周库存</el-menu-item>
            <el-menu-item index="/currentbasis">期现基差</el-menu-item>
          </el-submenu>
          <el-menu-item index="/brands">品牌资料</el-menu-item>
        </el-menu>
        <el-menu :default-active="$route.path"
                 class="el-menu-demo"
                 mode="horizontal"
                 background-color="#16469e"
                 text-color="#ffffff"
                 router
                 v-else-if="power===1">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/realtimeprice">实时价格</el-menu-item>
          <el-submenu index="/steelstores">
            <template slot="title"><span id="Price">价格跟踪</span></template>
            <el-menu-item index="/spotindex">现货指数</el-menu-item>
            <el-menu-item index="/recommend">资源比价</el-menu-item>
            <el-menu-item index="/historicaldata">历史数据</el-menu-item>
          </el-submenu>
          <el-submenu index="/steelstore">
            <template slot="title"><span id="market">市场分析</span></template>
            <el-menu-item index="/steelflow">每日出入库</el-menu-item>
            <el-menu-item index="/steelstore">每周库存</el-menu-item>
            <el-menu-item index="/currentbasis">期现基差</el-menu-item>
          </el-submenu>
          <el-menu-item index="/brands">品牌资料</el-menu-item>
        </el-menu>
        <el-menu :default-active="$route.path"
                 class="el-menu-demo"
                 mode="horizontal"
                 background-color="#16469e"
                 text-color="#ffffff"
                 router
                 v-else-if="power===2">
          <el-menu-item index="/engineer">工程指数</el-menu-item>
          <el-submenu index="/steelstores">
            <template slot="title"><span id="Price">价格跟踪</span></template>
            <el-menu-item index="/historicalengineerdata">历史数据</el-menu-item>
          </el-submenu>
          <el-submenu index="/steelstore">
            <template slot="title"><span id="market">市场分析</span></template>
            <el-menu-item index="/steelflow">每日出入库</el-menu-item>
            <el-menu-item index="/steelstore">每周库存</el-menu-item>
            <el-menu-item index="/currentbasis">期现基差</el-menu-item>
          </el-submenu>
          <el-menu-item index="/brands">品牌资料</el-menu-item>
        </el-menu>
      </ul>
      <ul class="layui-nav layui-layout-right"
          style="margin-right: 30px;">
        <li class="layui-nav-item">
          <el-dropdown :hide-on-click="false">
            <a href="javascript:;"> 参考价区域：{{area}}<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for='item in PriceArea'
                                :key='item.id'
                                @click.native="refreshArea(item.id)">{{item.area}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li class="layui-nav-item"
            style="width: 60px;"
            v-if="isnum==true">
          <el-badge :value="num">
            <a href="/#/notice"><i class="el-icon-message messages"></i></a>
          </el-badge>
        </li>

        <li class="layui-nav-item">
          <el-dropdown :hide-on-click="false">
            <a href="javascript:;">
              <img :src="headimgurl"
                   class="layui-nav-img">
              {{username}}<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><a href="/#/personal">个人信息</a></el-dropdown-item>
              <el-dropdown-item @click.native="logout">安全退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <div class="marquee-wrap"
         style="width: 100%"
         v-if="isstatus==1">
      <div class="el-tag el-tag--light"
           style="width: 100%; color: #605d5c; height: 28px;">
        <notice :message="notice"></notice>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import notice from '@/components/notice'
import { getUserProfile, logout, getNoticeList } from '@/api/user'
import { getNowWebPriceArea, setWebPriceArea, getNavTree, getUserNotice } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  name: 'Layout',
  components: { notice },
  data () {
    return {
      username: '',
      area: '',
      headimgurl: '',
      PriceArea: [],
      num: '',
      isnum: '',
      nav: [],
      power: '',
      isstatus: 0,
      notice: ''

    }
  },
  created () {
    // 注册自定义事件
    globalBus.$on('personals', (res) => {
      if (res.status === 2) {
        this.username = res.name
      }
      if (res.status === 1) {
        this.headimgurl = res.headimgurl
      }
      if (res.status === 3) {
        this.num = res.num
        if (res.num === 0) {
          this.isnum = false
        } else {
          this.isnum = true
        }
      }
    })
  },
  mounted () {
    // 组件初始化
    this.loadgetUserNotice()
    this.loadUserProfile()
    this.loadgetNowWebPriceArea()
    this.getNoticeList()
    this.loadgetNavTree()
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.notice = res.data.data.notice
          } else {
            this.isstatus = 0
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 获取用户信息
    loadUserProfile () {
      getUserProfile().then(res => {
        this.username = res.data.data.name
        this.area = res.data.area
        if (res.data.data.headimgurl === undefined || res.data.data.headimgurl <= 0) {
          this.headimgurl = 'https://66gangtie.oss-cn-shanghai.aliyuncs.com/web/uploads/20210106/1657895623edb24ea.jpg'
        } else {
          this.headimgurl = 'https://api.66gangtie.com/' + res.data.data.headimgurl
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 获取导航内容
    loadgetNavTree () {
      getNavTree().then(res => {
        this.nav = res.data.data
        this.power = res.data.power
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 退出登录
    logout () {
      this.$confirm('确认退出吗?', '退出提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logout().then(res => {
          window.localStorage.removeItem('user')
          this.$router.push({
            name: 'Login'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取参考价区域
    loadgetNowWebPriceArea () {
      getNowWebPriceArea().then(res => {
        if (res.data.status === 'success') {
          this.PriceArea = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择参考价区域
    refreshArea (id) {
      setWebPriceArea({ id: id }).then(res => {
        if (res.data.status === 'success') {
          location.reload()
        } else {
          this.$message.error('选择失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getNoticeList () {
      getNoticeList().then(res => {
        this.num = res.data.num
        if (res.data.num === 0) {
          this.isnum = false
        } else {
          this.isnum = true
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    }
  }

}
</script>

<style lang="less" scoped>
.messages {
  font-size: 25px;
  margin-right: 15px;
  margin-top: 20px;
}
.layui-layout-admin .layui-header .layui-nav .layui-nav-item {
  line-height: 60px;
}
/deep/.el-badge__content.is-fixed {
  top: 28px;
  right: 70px;
}
/deep/.el-badge__content {
  font-size: 12px;
  height: 16px;
  line-height: 16px;
}
/deep/.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #409eff;
  color: #ffffff;
  background-color: #1c8fef !important;
}
/deep/.el-submenu__title i {
  color: #ffffff;
}
/deep/.el-menu--horizontal > .el-submenu.is-active {
  border-bottom: 2px solid #409eff;
  color: #ffffff;
  background-color: #1c8fef !important;
}
/deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0px solid #409eff;
  background-color: #1c8fef !important;
  color: #ffffff;
}
/deep/.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #fff;
}
/deep/.el-menu--horizontal > .el-menu-item {
  height: 62px;
  line-height: 62px;
}

.el-menu-item.is-active {
  color: #ffffff !important;
  background-color: #1c8fef !important;
}
.submenu {
  color: #fff !important;
}
</style>
