<template>
  <div class="index-container">
    <div class="layui-body"
         :style="topnotice">
      <div class="layadmin-tabsbody-item layui-show">
        <div class="layui-col-md12">
          <el-carousel trigger="click"
                       :height="bannerHeight + 'px'"
                       :interval="5000">
            <el-carousel-item v-for="item in ad"
                              :key="item.id">
              <img :src=item.image
                   class="carouse_image_type">
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="layui-fluid">
          <div class="layui-row layui-col-space15">
            <div class="layui-col-md12 mt">
              <div class="layui-row layui-col-space15">
                <div class="layui-col-md6">
                  <div class="layui-card">
                    <div class="layui-card-header">
                      实时价格 <span style="font-size:12px ;">(到货地址：广州天河)</span> <span class="fr"><a href="/#/realtimeprice">更多</a></span>
                    </div>
                    <div class="layui-card-body">
                      <el-table v-loading="loading"
                                :data="page_items"
                                border
                                :row-class-name="tableRowClassName"
                                style="width: 100%">
                        <el-table-column prop="brand"
                                         label="品牌">
                          <template slot-scope="scope">
                            <div><span class="active-column2">{{scope.row.brand}}</span> </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="规格">
                          <template slot-scope="scope">
                            <div>{{scope.row.cate_spec}}{{scope.row.size}} </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="warehouse"
                                         label="仓库">
                        </el-table-column>
                        <el-table-column prop="resultPrice"
                                         label="到货价">
                          <template slot-scope="scope">
                            <div><span class="active-column">{{scope.row.resultPrice}}</span> <span v-html="scope.row.market_float"></span></div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="web_price"
                                         label="参考价">
                          <template slot-scope="scope">
                            <div><span style="padding-right: 5px;">{{scope.row.web_price}}</span><span v-html="scope.row.web_float"></span>
                              <el-tag size="small"
                                      v-if="scope.row.web_price">{{area}}</el-tag>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="diffPrice"
                                         label="价差">
                          <template slot-scope="scope">
                            <div><span class="active-column">{{scope.row.diffPrice}}</span> <span v-html="scope.row.diff_price_float"></span></div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
                <div class="layui-col-md6">
                  <div class="layui-card">
                    <div class="layui-card-header">资源比价 <span style="font-size:12px ;">(到货地址：广州天河)</span> <span class="fr"><a href="/#/recommend">更多</a></span></div>
                    <div class="layui-card-body"
                         style="margin-top: -4px;">
                      <el-tabs type="card"
                               v-model="activeNames"
                               @tab-click="handleClick">
                        <el-tab-pane label="螺纹钢 / 18-25E"
                                     name="first"></el-tab-pane>
                        <el-tab-pane label="盘螺 / 8-10E"
                                     name="second"></el-tab-pane>
                        <el-tab-pane label="高线 / 8-10"
                                     name="three"></el-tab-pane>
                      </el-tabs>
                      <div class="currcont"
                           :style="luowengangdisplay">
                        <el-table :data="luowengangData"
                                  v-loading="loadings"
                                  border
                                  :row-class-name="tableRowClassName"
                                  style="width: 100%">
                          <el-table-column prop="brand"
                                           label="品牌">
                          </el-table-column>
                          <el-table-column prop="result_price_avg"
                                           label="平均到货价">
                          </el-table-column>
                          <el-table-column prop="web_price_avg"
                                           label="平均参加价">
                          </el-table-column>
                          <el-table-column prop="diff_price_avg"
                                           label="平均价差">
                          </el-table-column>
                          <el-table-column prop="newest_result_price"
                                           label="最新到货价">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_result_price}}</span> <span v-html="scope.row.newest_result_price_float"></span></div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="newest_web_price"
                                           label="最新参考价">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_web_price}}</span> <span v-html="scope.row.newest_web_price_float"></span></div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="newest_diff_price"
                                           label="最新价差">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_diff_price}}</span> <span v-html="scope.row.newest_diff_price_float"></span></div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div class="currcont"
                           :style="panluodisplay">
                        <el-table :data="panluoData"
                                  v-loading="loading"
                                  border
                                  :row-class-name="tableRowClassName"
                                  style="width: 100%">
                          <el-table-column prop="brand"
                                           label="品牌">
                          </el-table-column>
                          <el-table-column prop="result_price_avg"
                                           label="平均到货价">
                          </el-table-column>
                          <el-table-column prop="web_price_avg"
                                           label="平均参加价">
                          </el-table-column>
                          <el-table-column prop="diff_price_avg"
                                           label="平均价差">
                          </el-table-column>
                          <el-table-column prop="newest_result_price"
                                           label="最新到货价">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_result_price}}</span> <span v-html="scope.row.newest_result_price_float"></span></div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="newest_web_price"
                                           label="最新参考价">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_web_price}}</span> <span v-html="scope.row.newest_web_price_float"></span></div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="newest_diff_price"
                                           label="最新价差">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_diff_price}}</span> <span v-html="scope.row.newest_diff_price_float"></span></div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div class="currcont"
                           :style="gaoxiandisplay">
                        <el-table :data="gaoxianData"
                                  v-loading="loading"
                                  border
                                  :row-class-name="tableRowClassName"
                                  style="width: 100%">
                          <el-table-column prop="brand"
                                           label="品牌">
                          </el-table-column>
                          <el-table-column prop="result_price_avg"
                                           label="平均到货价">
                          </el-table-column>
                          <el-table-column prop="web_price_avg"
                                           label="平均参加价">
                          </el-table-column>
                          <el-table-column prop="diff_price_avg"
                                           label="平均价差">
                          </el-table-column>
                          <el-table-column prop="newest_result_price"
                                           label="最新到货价">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_result_price}}</span> <span v-html="scope.row.newest_result_price_float"></span></div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="newest_web_price"
                                           label="最新参考价">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_web_price}}</span> <span v-html="scope.row.newest_web_price_float"></span></div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="newest_diff_price"
                                           label="最新价差">
                            <template slot-scope="scope">
                              <div><span>{{scope.row.newest_diff_price}}</span> <span v-html="scope.row.newest_diff_price_float"></span></div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="layui-col-md12">
              <div class="layui-card"
                   style="float: left; width: 100%;">
                <div class="layui-card-header">期现基差走势图<span class="fr"><a href="/#/currentbasis">更多</a></span></div>
                <div class="layui-card-body">
                  <div ref="myChart"
                       class="myChart"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-box">
        <div id="csdn-copyright-footer"
             class="column dark">
          <ul class="footer-column-t">
            <li>
              <img src="../assets/images/tel.png"
                   alt="">
              <span> 020-83887466-8056</span>
            </li>
            <li>
              <img src="../assets/images/email.png"
                   alt="">
              <a href="mailto:gzgwzg@yeah.net"
                 target="_blank"> gzgwzg@yeah.net</a>
            </li>
            <li>
              工作时间&nbsp;9:00-17:30
            </li>
            <li>
              <a href="javascript:;"
                 @click="about"> 关于我们</a>
            </li>
          </ul>
          <ul class="footer-column-b">
            <li><img src="../assets/images/beian.png"
                   alt=""><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010402002322"
                 rel="noreferrer"
                 target="_blank">粤公网安备 44010402002322号</a></li>
            <li><a href="http://beian.miit.gov.cn/publish/query/indexFirst.action"
                 rel="noreferrer"
                 target="_blank">粤ICP备17117697号</a></li>
            <li>©2016-2020 智钢数据服务（广州）有限公司</li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="关于我们"
               append-to-body
               :visible.sync="dialogTableVisible">
      <div class="about"> 智钢数据服务（广州）有限公司（简称：智钢），2018年成立于中国国际商贸中心——广州。基于母公司八年的行业经验，乘着“互联网+”的时代浪潮，为传统钢铁行业转型升级贡献一份力量。
        智钢致力于打造华南地区钢铁行业的专业服务平台。旗下目前主打产品有“六六钢铁”和“一拍即点”，六六钢铁——以真实交易数据构建华南现货价格体系，利用大数据提供交易、仓储、物流全方位数据服务，为用户提供高效、安全、透明的钢铁在线交易平台；一拍即点——引入AI智能识别技术代替人工数钢筋，用数字技术提升工地整体精细化管理水平。通过互联网真正实现钢铁行业上下游信息化、数据化、智能化的转型升级。
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import steps from '@/utils/guideindex'
import { getMarketPriceFive, getWebArea, getUsuallySizePriceStatisticsFive, getSetting, getCurrentBasisFive, getAd, getNavTree, getUserNotice } from '@/api/global'
export default {
  data () {
    return {
      ad: [],
      page_items: [],
      area: '',
      date: [],
      dialogTableVisible: false,
      loading: true,
      loadings: true,
      luowengangData: [],
      panluoData: [],
      gaoxianData: [],
      layuithis: 'layui-this',
      layuithiss: '',
      layuithisss: '',
      driver: null,
      activeNames: 'first',
      luowengangdisplay: 'display:block',
      panluodisplay: 'display:none',
      gaoxiandisplay: 'display:none',
      filter: {
        selected: {
          brands: [],
          address: {
            province: '广东省',
            city: '广州市',
            area: '天河区'
          }
        }
      },
      dateBetween: [],
      spotpricemin: '',
      currentbasismax: '',
      currentData: [
        {
          name: '螺纹现货价',
          data: [],
          type: 'line',
          smooth: true,
          smoothMonotone: 'x',
          cursor: 'pointer',
          showSymbol: false,
          yAxisIndex: 0,
          lineStyle: {
            shadowColor: 'rgba(18,61,172,0.5)',
            shadowBlur: 10
          }
        },
        {
          name: '螺纹收盘价',
          data: [],
          type: 'line',
          smooth: true,
          smoothMonotone: 'x',
          cursor: 'pointer',
          showSymbol: false,
          yAxisIndex: 0,
          lineStyle: {
            shadowColor: 'rgba(115,226,226,0.5)',
            shadowBlur: 10
          }
        },
        {
          name: '期现基差',
          data: [],
          type: 'line',
          smooth: true,
          smoothMonotone: 'x',
          cursor: 'pointer',
          showSymbol: false,
          yAxisIndex: 1,
          lineStyle: {
            shadowColor: 'rgba(255,126,133,0.5)',
            shadowBlur: 10
          }
        }
      ],
      currentbasis_min_max: [],
      price_min_max: [],
      // 图片父容器高度
      bannerHeight: 300,
      // 浏览器宽度
      screenWidth: 0,
      isstatus: 0,
      topnotice: ''
    }
  },
  mounted () {
    this.loadgetUserNotice()
    this.loadgetNavTrees()
    // 首次加载时,需要调用一次
    this.screenWidth = window.innerWidth
    this.setSize()
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.screenWidth = window.innerWidth
    }
    this.setSize()
    this.getAd()
    this.getWebArea()
    this.loadgetMarketPrice()
    this.getSetting()
    this.searchData()
    this.driver = new Driver({
      className: 'scoped-class',
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: false,
      stageBackground: '#999', // 引导对话的背景色
      overlayClickNext: false,
      doneBtnText: '完成',
      closeBtnText: '跳过',
      nextBtnText: '下一步',
      prevBtnText: '上一步',
      onReset: () => {
        window.localStorage.setItem('guideindex', 'BVewsJUqylM7BbbX3rkLTcRRuiXNH717')
      }
    })
    if (window.localStorage.getItem('guideindex') !== 'BVewsJUqylM7BbbX3rkLTcRRuiXNH717') {
      this.guide()
    }
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.topnotice = 'top: 92px;'
          } else {
            this.isstatus = 0
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 获取导航内容
    loadgetNavTrees () {
      getNavTree().then(res => {
        if (res.data.power === 2) {
          // 跳转到工程页面
          this.$router.push({
            name: 'Engineer'
          })
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = 300 / 1920 * this.screenWidth
    },
    // 获取参考区域
    getWebArea () {
      getWebArea().then(res => {
        this.area = res.data.area
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 获取最新日期
    getSetting () {
      getSetting().then(res => {
        this.date = res.data.datearr
        this.currentbasis(res.data.lastmonthsSix)
      })
    },
    // 获取广告
    getAd () {
      getAd().then(res => {
        this.ad = res.data.data
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面获取实时价格
    loadgetMarketPrice () {
      getMarketPriceFive({ is_newest: true, page: 1, per_page: 6 }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          // console.log(res.data.data.page_items)
          this.dates = res.data.price_time
          this.pagetotal = res.data.data.page_total
          this.page_items = res.data.data.page_items.map(val => {
            if (val.warehouse === '钢厂直发') {
              val.freight = '-'
              val.price = '-'
            }
            if (val.web_float > 0) {
              val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float < 0) {
              val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float === 0) {
              val.web_float = '<span class="yellow_font">-平</span>'
            }
            if (val.diff_price_float > 0) {
              val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float < 0) {
              val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float === 0) {
              val.diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.market_float > 0) {
              val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float < 0) {
              val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float === 0) {
              val.market_float = '<span class="yellow_font">-平</span>'
            }
            return val
          })
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 资源总览
    searchData () {
      this.updateLoading = true
      getUsuallySizePriceStatisticsFive({
        per_page: 5,
        date_begin: this.date[0],
        date_end: this.date[1],
        brand: this.filter.selected.brands,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area
      }).then(res => {
        if (res.data.status === 'success') {
          this.luowengangData = res.data.data.luowengang_data.map(val => {
            if (val.newest_result_price_float > 0) {
              val.newest_result_price_float = '<span class="red_font">↑' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float < 0) {
              val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float === 0) {
              val.newest_result_price_float = ''
            }
            if (val.newest_web_price_float > 0) {
              val.newest_web_price_float = '<span class="red_font">↑' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float < 0) {
              val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float === '-') {
              val.newest_web_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_diff_price_float > 0) {
              val.newest_diff_price_float = '<span class="red_font">↑' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float < 0) {
              val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float === 0) {
              val.newest_diff_price_float = ''
            }
            return val
          })

          this.panluoData = res.data.data.panluo_data.map(val => {
            if (val.newest_result_price_float > 0) {
              val.newest_result_price_float = '<span class="red_font">↑' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float < 0) {
              val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float === 0) {
              val.newest_result_price_float = ''
            }
            if (val.newest_web_price_float > 0) {
              val.newest_web_price_float = '<span class="red_font">↑' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float < 0) {
              val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float === '-') {
              val.newest_web_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_diff_price_float > 0) {
              val.newest_diff_price_float = '<span class="red_font">↑' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float < 0) {
              val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float === 0) {
              val.newest_diff_price_float = ''
            }
            return val
          })
          this.gaoxianData = res.data.data.gaoxian_data.map(val => {
            if (val.newest_result_price_float > 0) {
              val.newest_result_price_float = '<span class="red_font">↑' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float < 0) {
              val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
            }
            if (val.newest_result_price_float === 0) {
              val.newest_result_price_float = ''
            }
            if (val.newest_web_price_float > 0) {
              val.newest_web_price_float = '<span class="red_font">↑' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float < 0) {
              val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
            }
            if (val.newest_web_price_float === '-') {
              val.newest_web_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.newest_diff_price_float > 0) {
              val.newest_diff_price_float = '<span class="red_font">↑' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float < 0) {
              val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
            }
            if (val.newest_diff_price_float === 0) {
              val.newest_diff_price_float = ''
            }
            return val
          })
          this.loadings = false
          this.updateLoading = false
        } else {
          this.loadings = false
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.loadings = false
        this.updateLoading = false
        console.log('获取数据失败', err)
      })
    },
    handleClick (tab, event) {
      if (tab.label === '螺纹钢 / 18-25E') {
        this.luowengangdisplay = 'display:block'
        this.panluodisplay = 'display:none'
        this.gaoxiandisplay = 'display:none'
      }
      if (tab.label === '盘螺 / 8-10E') {
        this.luowengangdisplay = 'display:none'
        this.panluodisplay = 'display:block'
        this.gaoxiandisplay = 'display:none'
      }
      if (tab.label === '高线 / 8-10') {
        this.luowengangdisplay = 'display:none'
        this.panluodisplay = 'display:none'
        this.gaoxiandisplay = 'display:block'
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    currentbasis (date) {
      getCurrentBasisFive({ startdate: date[0], enddate: date[1] }).then(res => {
        if (res.data.status === 'success') {
          this.dateBetween = res.data.date
          this.spotpricemin = res.data.spotpricemix
          this.currentbasismax = res.data.currentbasismax
          this.price_min_max = res.data.price_min_max
          this.currentbasis_min_max = res.data.currentbasis_min_max
          this.getChartSpotprice(res.data.data)
          this.getChartThreadclosingprice(res.data.data)
          this.getChartCurrentbasis(res.data.data)
          this.drawLine()
        } else {
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        console.log('获取数据失败', err)
      })
    },
    drawLine () {
      var echarts = require('echarts')
      var myChart = echarts.init(this.$refs.myChart)
      const xAxisData = this.dateBetween
      const option = {
        textStyle: {
          fontFamily: 'Din-Light'
        },
        color: ['#123dac', '#73e2e2', '#ff7e85', '#9b52ff', '#fac524', '#46caff', '#a1e867', '#10b2b2', '#ec87f7', '#f4905a', '#00baba', '#facf24', '#e89d67', '#23c6c6', '#fa8699', '#40b7fc', '#006d75', '#595959', '#f4764f', '#a640fc', '#fda23f', '#2d7ae4', '#5092ff', '#9351ed', '#8a89fe', '#df89e8', '#2797ff', '#6ad089', '#7c92e8'],
        title: {
          text: '',
          left: '47%',
          textStyle: {
            fontSize: 32
          }
        },
        legend: {
          data: [
            {
              name: '螺纹现货价'
            },
            {
              name: '螺纹收盘价'
            },
            {
              name: '期现基差'
            }
          ],
          selected: {
            螺纹现货价: true,
            螺纹收盘价: true,
            期现基差: true
          },
          itemWidth: 20,
          itemHeight: 10,
          itemGap: 10,
          textStyle: {
            color: '#898989',
            lineHeight: 15
          },
          type: 'scroll'
        },
        tooltip: {
          backgroundColor: '#fff',
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          textStyle: {
            color: '#565656',
            lineHeight: 28
          },
          confine: true,
          padding: 12,
          extraCssText:
            'box-shadow: 0px 2px 8px 0px #cacaca;border-radius: 4px;opacity: 0.9;max-height: 100%;',
          formatter: {
            _custom: {
              type: 'function',
              display: '<span>ƒ</span> (params)'
            }
          }
        },
        grid: {
          left: '3%',
          right: '2%',
          top: 100,
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLabel: {
            color: '#a0a9bc'
            // X轴标签 label 做了特殊处理，防止左右溢出
            // formatter: (value, index) => {
            // if (index === 0 || index === xAxisData.length - 1) {
            // return ''
            // }
            // return value
            // },
            // interval：0 //横轴信息全部显示
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9'
            }
          }
        },
        yAxis: [
          {
            name: '螺纹期货/现货',
            nameTextStyle: {
              color: 'gray'
            },
            type: 'value',
            scale: true,
            max: this.price_min_max[1] + 100,
            min: this.price_min_max[0] - 1000,
            splitNumber: 10,
            boundaryGap: [0, 0.6],
            axisLabel: {
              color: '#a0a9bc',
              inside: true,
              margin: 0,
              verticalAlign: 'bottom'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }, {
            name: '期现基差',
            nameTextStyle: {
              color: 'gray'
            },
            type: 'value',
            scale: true,
            max: this.currentbasis_min_max[1] + 500,
            min: this.currentbasis_min_max[0] - 50,
            splitNumber: 10,
            boundaryGap: [0, 0.6],
            axisLabel: {
              color: '#a0a9bc',
              inside: true,
              margin: 0,
              verticalAlign: 'bottom'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                width: 2,
                color: 'rgba(255,126,133,0.5)'
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: this.currentData
      }
      myChart.setOption(option)
      // 监听点击事件
      window.addEventListener('click', function () {
        myChart.resize()
      })
      // 监听浏览器页面缩放事件
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 获取统计图现货数据
    getChartSpotprice (marketData) {
      const marketSend = this.currentData[0].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].spotprice.length > 0) {
            marketSend[i].push(marketData[j].spotprice)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    // 获取统计图期货数据
    getChartThreadclosingprice (marketData) {
      const marketSend = this.currentData[1].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].threadclosingprice.length > 0) {
            marketSend[i].push(marketData[j].threadclosingprice)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    // 获取统计图期现基差数据
    getChartCurrentbasis (marketData) {
      const marketSend = this.currentData[2].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].currentbasis.length > 0) {
            marketSend[i].push(marketData[j].currentbasis)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    about () {
      this.dialogTableVisible = true
    },
    // 访问引导页
    guide () {
      this.driver.defineSteps(steps)
      this.driver.start()
    }
  }
}
</script>

<style lang="less" scoped>
.index-container {
  background-color: #f2f2f2;
}
.layui-body {
  background-color: #f2f2f2;
  left: 0;
}
.layadmin-text p {
  text-indent: 2em;
  font-size: 12px;
  line-height: 22px;
}
.mt {
  margin-top: 15px;
}
.myChart {
  height: 500px;
  width: 100%;
  margin-top: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.layui-fluid {
  padding: 20px;
}
.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 60px;
  background-color: black;
}
.copyright-box {
  width: 100%;
  background: #ffffff;
}
.column {
  margin: 0 auto;
  -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  padding: 24px 34px 20px;
}
.column .footer-column-t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.column .footer-column-t li {
  margin: 0 8px;
  color: #999aaa;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column a {
  text-decoration: none;
  color: inherit;
}
.column .footer-column-b {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.column .footer-column-b li {
  font-size: 12px;
  color: #999aaa;
  height: 16px;
  line-height: 16px;
  margin: 4px 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column .footer-column-b li img {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 4px;
}
.layui-card-header {
  font-weight: bold;
}

/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table thead.is-group th {
  background: #707880;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/.el-table th {
  padding: 2px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/.el-table td {
  padding: 10px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}
.active-column {
  color: #e6781e;
  font-weight: 700;
}
.active-column2 {
  font-weight: 700;
}
.fr-title {
  font-size: 14px;
  font-weight: 500;
}
.more {
  text-align: center;
  padding: 10px;
  font-size: 14px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

.luowengang {
  float: left;
  width: 33.33333%;
}
.panluo {
  float: left;
  width: 33.33333%;
}
.gaoxian {
  float: left;
  width: 33.33333%;
}
/deep/ .el-table .cell .red_font {
  color: #aa1010;
}
/deep/ .el-table .cell .green_font {
  color: #10aa10;
}
/deep/ .el-table .cell .yellow_font {
  color: #aaaa10;
}
/deep/ .el-table {
  font-size: 14px;
  color: #606266;
}
.layui-tab-title {
  margin-bottom: 10px;
}
.layui-tab-brief > .layui-tab-title .layui-this {
  color: #1e9fff;
  font-family: '微软雅黑';
  font-size: 14px;
}
.pr20 {
  padding-right: 20px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.carouse_image_type {
  width: 100%;
  height: inherit;
}
.about {
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
}
div#driver-highlighted-element-stage {
  z-index: 0 !important;
}
/deep/ .el-table th > .cell {
  text-align: center;
  font-size: 12px;
}
</style>
