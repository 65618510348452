import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Findpassword from '@/views/Findpassword.vue'
import Layout from '@/views/Layout.vue'
import Index from '@/views/Index.vue'
import Realtimeprice from '@/views/Realtimeprice.vue'
import Recommend from '@/views/Recommend.vue'
import Historicaldata from '@/views/Historicaldata.vue'
import Steelstore from '@/views/Steelstore.vue'
import Steelstorechart from '@/views/Steelstorechart.vue'
import Steelflow from '@/views/Steelflow.vue'
import Brands from '@/views/Brands.vue'
import Personal from '@/views/user/Personal.vue'
import Notice from '@/views/user/Notice.vue'
import Password from '@/views/user/Password.vue'
import Cellphone from '@/views/user/Cellphone.vue'
import Spotindex from '@/views/Spotindex.vue'
import Currentbasis from '@/views/Currentbasis.vue'
import Engineer from '@/views/Engineer.vue'
import Historicalengineerdata from '@/views/Historicalengineerdata.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/findpassword',
    name: 'Findpassword',
    component: Findpassword
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Index',
        component: Index
      },
      {
        path: '/realtimeprice',
        name: 'Realtimeprice',
        component: Realtimeprice
      },
      {
        path: '/engineer',
        name: 'Engineer',
        component: Engineer
      },
      {
        path: '/recommend',
        name: 'Recommend',
        component: Recommend
      },
      {
        path: '/historicaldata',
        name: 'Historicaldata',
        component: Historicaldata
      },
      {
        path: '/steelstore',
        name: 'Steelstore',
        component: Steelstore
      },
      {
        path: '/steelstorechart',
        name: 'Steelstorechart',
        component: Steelstorechart
      },
      {
        path: '/steelflow',
        name: 'Steelflow',
        component: Steelflow
      },
      {
        path: '/brands',
        name: 'Brands',
        component: Brands
      },
      {
        path: '/personal',
        name: 'Personal',
        component: Personal
      },
      {
        path: '/notice',
        name: 'Notice',
        component: Notice
      },
      {
        path: '/password',
        name: 'Password',
        component: Password
      },
      {
        path: '/cellphone',
        name: 'Cellphone',
        component: Cellphone
      },
      {
        path: '/spotindex',
        name: 'Spotindex',
        component: Spotindex
      },
      {
        path: '/currentbasis',
        name: 'Currentbasis',
        component: Currentbasis
      },
      {
        path: '/historicalengineerdata',
        name: 'Historicalengineerdata',
        component: Historicalengineerdata
      }
    ]
  }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  if (to.path !== '/login' && to.path !== '/register' && to.path !== '/findpassword') {
    if (user) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
