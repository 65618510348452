<template>
  <div class="layui-tab-content">
    <div class="layui-form">
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">时间</el-tag>
        </label>
        <div class="layui-input-block">
          <el-date-picker v-model="dates"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </div>
      <div class="layui-form-item">
        <div class="buttongt">
          <el-button type="primary"
                     @click="searchData(dates)"
                     :loading="updateLoading">确定</el-button>
          <el-button type="info"
                     @click="clearChecked">重置</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSteelstoreBasis, getSetting } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      dates: [],
      pickerOptions: {
        disabledDate (time) {
          const curDate = (new Date()).getTime()
          const three = 36 * 30 * 24 * 3600 * 1000
          const threeMonths = curDate - three
          return time.getTime() > Date.now() || time.getTime() < threeMonths
        }
      },
      updateLoading: false
    }
  },
  created () {
    // 注册自定义事件
    this.getSetting()
  },
  mounted () {
  },
  methods: {
    // 获取最新日期
    getSetting () {
      getSetting().then(res => {
        this.dates = res.data.lastmonthsSix
        this.searchData(res.data.lastmonthsSix)
      })
    },
    // 搜索走势图
    searchData (datess) {
      this.updateLoading = true
      if (this.dates.length === 0) {
        this.updateLoading = false
        this.$message.error('请选择日期')
        return false
      }
      getSteelstoreBasis({ startdate: datess[0], enddate: datess[1] }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          globalBus.$emit('Steelstorechart', { data: res.data.data, date: res.data.date, warehousetotalmin: res.data.warehousetotal })
          this.updateLoading = false
        } else {
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('获取数据失败', err)
      })
    },
    // 清空搜索条件
    clearChecked () {
      this.dates = []
    }
  }
}
</script>
<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
.layui-input-block {
  margin-left: 80px;
  min-height: 36px;
  line-height: 36px;
  margin-top: 5px;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 190px;
  margin-right: 25px;
  margin-left: -10px;
}

.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwiths {
  padding: 0 18px;
}
.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
</style>
