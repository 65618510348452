import request from '@/utils/request'

// 用户登录
export const login = data => {
  return request({
    method: 'POST',
    url: '/api/login',
    // data 用来设置 POST 请求体
    data
  })
}
// 用户注册
export const register = data => {
  return request({
    method: 'POST',
    url: '/api/register',
    data
  })
}
// 获取用户信息
export const getUserProfile = data => {
  return request({
    method: 'POST',
    url: '/api/getUserProfile',
    data
  })
}
// 修改用户信息
export const senduserinfo = data => {
  return request({
    method: 'POST',
    url: '/api/sendUserInfo',
    data
  })
}
// 上传头像
export const updateUserPhoto = data => {
  return request({
    method: 'POST',
    url: '/api/updateUserPhoto',
    data
  })
}
// 修改密码
export const modifyPassword = data => {
  return request({
    method: 'POST',
    url: '/api/modifyPassword',
    data
  })
}
// 修改手机号
export const editPhone = data => {
  return request({
    method: 'POST',
    url: '/api/editPhone',
    data
  })
}
// 验证短信
export const verification = data => {
  return request({
    method: 'POST',
    url: '/api/verification',
    data
  })
}
// 获取验证码
export const getUsercode = data => {
  return request({
    method: 'POST',
    url: '/api/getRegSms',
    data
  })
}
// 用户找回密码
export const findpassword = data => {
  return request({
    method: 'POST',
    url: '/api/getFindPassword',
    data
  })
}
// 用户退出
export const logout = data => {
  return request({
    method: 'POST',
    url: '/api/logout',
    data
  })
}
// 用户协议
export const useragreement = data => {
  return request({
    method: 'POST',
    url: '/api/getUserAgreement',
    data
  })
}
// 获取未阅读通知
export const getNoticeList = data => {
  return request({
    method: 'POST',
    url: '/api/getNoticeList',
    data
  })
}
// 点击阅读通知
export const getNoticeIn = data => {
  return request({
    method: 'POST',
    url: '/api/getNoticeIn',
    data
  })
}
// 阅读过通知
export const getNoticeRead = data => {
  return request({
    method: 'POST',
    url: '/api/getNoticeRead',
    data
  })
}
