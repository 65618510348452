<template>
  <div class="layui-tab-content">
    <div class="layui-form">
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">日期</el-tag>
        </label>
        <div class="layui-input-inline"
             style="margin-left: 0px;">
          <el-date-picker v-model="StartDate"
                          type="date"
                          placeholder="选择开始日期"
                          value-format="yyyy-MM-dd"
                          @change="changeStartDate"
                          :picker-options="pickerOptionsa"> </el-date-picker>
        </div>
        <div class="layui-input-inline"
             style="margin-left: 0px;">
          <el-date-picker v-model="EndDate"
                          type="date"
                          placeholder="选择结束日期"
                          value-format="yyyy-MM-dd"
                          @change="changeEndDate"
                          :picker-options="pickerOptionsb"> </el-date-picker>
        </div>
      </div>
      <div class="layui-form-item">
        <div class="buttongt">
          <el-button type="primary"
                     @click="searchData"
                     :loading="updateLoading">确定</el-button>
          <el-button type="warning"
                     @click="searchimgData">获取截图</el-button>
          <el-button type="info"
                     @click="clearChecked">重置</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="每日出入库截图"
               append-to-body
               width="90%"
               :visible.sync="dialogTableVisible">
      <div class="fr">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">每日出入库</div>
        <el-table :data="steelflowbasisdatas"
                  v-loading="loading"
                  border
                  :row-class-name="tableRowClassName"
                  highlight-current-row
                  :cell-style="columnStyle"
                  style="width: 100%">
          <el-table-column prop="date"
                           sortable
                           label="日期"></el-table-column>
          <el-table-column label="螺纹"
                           header-align="center"
                           align="center">
            <el-table-column prop="threadwarehousing"
                             sortable
                             label="入库"></el-table-column>
            <el-table-column prop="threadwarehouseout"
                             sortable
                             label="出库"></el-table-column>
            <el-table-column prop="threadstock"
                             sortable
                             label="库存"></el-table-column>
          </el-table-column>
          <el-table-column label="盘螺"
                           header-align="center"
                           align="center">
            <el-table-column prop="snailwarehousing"
                             sortable
                             label="入库"></el-table-column>
            <el-table-column prop="snailwarehouseout"
                             sortable
                             label="出库"></el-table-column>
            <el-table-column prop="snailstock"
                             sortable
                             label="库存"></el-table-column>
          </el-table-column>
          <el-table-column label="高线"
                           header-align="center"
                           align="center">
            <el-table-column prop="highlinewarehousing"
                             sortable
                             label="入库"></el-table-column>
            <el-table-column prop="highlinwarehouseout"
                             sortable
                             label="出库"></el-table-column>
            <el-table-column prop="highlinstock"
                             sortable
                             label="库存"></el-table-column>
          </el-table-column>
          <el-table-column label="合计"
                           header-align="center"
                           align="center">
            <el-table-column prop="entersubtotal"
                             sortable
                             label="入库"></el-table-column>
            <el-table-column prop="outsubtotal"
                             sortable
                             label="出库"></el-table-column>
            <el-table-column prop="stocktotal"
                             sortable
                             label="库存"></el-table-column>
          </el-table-column>
          <el-table-column prop="wholeturnover"
                           sortable
                           label="全国成交量"></el-table-column>
        </el-table>
        <div class="bg"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSteelflowBasis, getSetting } from '@/api/global'
import globalBus from '@/utils/global-bus'
import domtoimage from 'dom-to-image'
export default {
  data () {
    return {
      pickerOptionsa: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      pickerOptionsb: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      StartDate: '',
      EndDate: '',
      loading: false,
      updateLoading: false,
      dialogTableVisible: false,
      steelflowbasisdatas: []
    }
  },
  mounted () {
    this.getSetting()
  },
  methods: {
    // 获取最新日期
    getSetting () {
      getSetting().then(res => {
        this.StartDate = res.data.datearr[0]
        this.EndDate = res.data.datearr[1]
        this.searchData()
      })
    },
    // 选择开始日期
    changeStartDate (callback) {
      this.StartDate = callback
      if (this.compare(callback, this.EndDate) === true) {
        this.EndDate = null
        return false
      }
    },
    // 选择结束日期
    changeEndDate (callback) {
      if (this.compare(this.StartDate, callback) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.EndDate = null
        return false
      } else {
        this.EndDate = callback
      }
    },
    searchData () {
      this.updateLoading = true
      if (this.StartDate === null) {
        this.$message({
          message: '请选择开始日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.EndDate === null) {
        this.$message({
          message: '请选择的结束日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.compare(this.StartDate, this.EndDate) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.StartDate = ''
        this.EndDate = ''
        this.updateLoading = false
        return
      }
      getSteelflowBasis({ startdate: this.StartDate, enddate: this.EndDate }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          globalBus.$emit('SteelflowBasisData', { data: res.data.data, date: res.data.date, max: res.data.max })
          this.updateLoading = false
        } else {
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('获取数据失败', err)
      })
    },
    // 清空搜索条件
    clearChecked () {
      this.date = []
    },
    // 提交搜索截图
    searchimgData () {
      this.updateLoading = true
      if (this.StartDate === null) {
        this.$message({
          message: '请选择开始日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.EndDate === null) {
        this.$message({
          message: '请选择的结束日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.compare(this.StartDate, this.EndDate) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.StartDate = ''
        this.EndDate = ''
        this.updateLoading = false
        return
      }
      this.dialogTableVisible = true
      getSteelflowBasis({ startdate: this.StartDate, enddate: this.EndDate }).then(res => {
        if (res.data.status === 'success') {
          this.steelflowbasisdatas = res.data.data
          this.updateLoading = false
        } else {
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('获取数据失败', err)
      })
    },
    // 获取截图
    getImg () {
      const loading = this.$loading({
        lock: true,
        text: '正在截图中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      var seft = this
      var node = this.$refs.mynode
      domtoimage.toPng(node).then(function (dataUrl) {
        var img = new Image()
        img.src = dataUrl
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = seft.createPic() + '.png' || '下载图片名称'
        a.href = dataUrl
        a.dispatchEvent(event)
        loading.close()
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    },
    // 产生随机图片名称
    createPic () {
      var now = new Date()
      var year = now.getFullYear()
      var month = now.getMonth()
      var date = now.getDate()
      var hour = now.getHours()
      var minu = now.getMinutes()
      month = month + 1
      if (month < 10) month = '0' + month
      if (date < 10) date = '0' + date
      var number = now.getSeconds() % 43 // 这将产生一个基于目前时间的0到42的整数。
      var time = year + month + date + hour + minu
      return time + '_' + number
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    // 日期比较的方法
    compare (date1, date2) {
      const dates1 = new Date(date1)
      const dates2 = new Date(date2)
      if (dates1 > dates2) {
        return true
      } else {
        return false
      }
    },
    columnStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        return 'background:rgba(225, 243, 216,0.3);'
      }
      if (columnIndex === 4 || columnIndex === 5 || columnIndex === 6) {
        return 'background:rgba(250, 236, 216,0.3);'
      }
      if (columnIndex === 7 || columnIndex === 8 || columnIndex === 9) {
        return 'background:rgba(253, 226, 226,0.3);'
      }
      if (columnIndex === 10 || columnIndex === 11 || columnIndex === 12) {
        return 'background:rgba(217, 236, 255,0.3);'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
.layui-input-block {
  margin-left: 80px;
  min-height: 50px;
  line-height: 50px;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 155px;
  margin-top: 5px;
}

.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwiths {
  padding: 0 18px;
}
.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
.fr {
  float: right;
}
.bgs {
  background-color: #ffffff;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table thead.is-group th {
  background: #707880;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}
/deep/.el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/.el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

/deep/ .el-table .sort-caret.ascending {
  border-bottom-color: rgb(245, 108, 108);
  top: 5px;
}
/deep/ .el-table .sort-caret.descending {
  border-top-color: rgb(103, 194, 58);
  bottom: 7px;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.titles {
  clear: both;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
}
.bg {
  width: 100%;
  height: 90%;
  z-index: 10;
  top: 20%;
  position: absolute;
  background: url('../assets/images/back.png');
}
</style>
