const steps = [
  {
    element: '#guide-menu',
    popover: {
      title: ' ',
      description: '<b>点击可收起侧边栏</b>',
      position: 'left'
    }
  },
  {
    element: '#brand-box',
    popover: {
      title: ' ',
      description: '<b>可切换选择过往日期</b>',
      position: 'left'
    }
    // padding: 5
  }
  // {
  //  element: '#tagsView',
  //  popover: {
  //   title: '最近打开任务',
  //  description: '最近打开任务菜单，点击可快速切换',
  //  position: 'bottom'
  //  }
// }
]
export default steps
