<template>
  <div class="layui-tab-content"
       id="recommendsearch">
    <div class="layui-form">
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwiths">品牌</el-tag>
        </label>
        <div class="layui-input-block">
          <el-select v-model="filter.selected.brands"
                     multiple
                     filterable
                     remote
                     reserve-keyword
                     placeholder="请输入品牌"
                     :loading="loading"
                     @change="selectedbands">
            <el-option v-for="item in brandlist"
                       :key="item.label"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="layui-form-item">
        <el-collapse v-model="activeName"
                     @change="changebrand">
          <el-collapse-item>
            <template slot="title">
              <span v-if="isband==false">选择品牌</span>
              <span v-else>
                <font v-for="item in filter.selected.brands"
                      :key="item">{{item}}、</font>
              </span>
            </template>
            <el-checkbox-group v-model="filter.selected.brands"
                               @change="brandlists">
              <el-checkbox-button v-for="item in brandlist"
                                  :label="item.name"
                                  :key="item.label">{{item.zname}}</el-checkbox-button>
            </el-checkbox-group>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">日期</el-tag>
        </label>
        <div class="layui-input-inline"
             style="margin-left: 0px;">
          <el-date-picker v-model="StartDate"
                          type="date"
                          placeholder="选择开始日期"
                          value-format="yyyy-MM-dd"
                          @change="changeStartDate"
                          :picker-options="pickerOptionsa"> </el-date-picker>
        </div>
        <div class="layui-input-inline"
             style="margin-left: 0px;">
          <el-date-picker v-model="EndDate"
                          type="date"
                          placeholder="选择结束日期"
                          value-format="yyyy-MM-dd"
                          @change="changeEndDate"
                          :picker-options="pickerOptionsb"> </el-date-picker>
        </div>
      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwiths">地址</el-tag>
        </label>
        <div class="layui-input-block">
          <div class="block">
            <el-cascader v-model="addressValue"
                         :options="addressList"
                         @change="changeProvinceCity"
                         :props="addressListProps"></el-cascader>
          </div>
        </div>

      </div>
      <div class="layui-form-item">
        <div class="buttongt">
          <el-button type="primary"
                     @click="searchData"
                     :loading="updateLoading">确定</el-button>
          <el-button type="info"
                     @click="clearChecked">重置</el-button>
        </div>
      </div>
    </div>
    <div class="retract"
         v-if="isretract==true">
      <el-button type="primary"
                 @click="
                 retract">收起品牌</el-button>
    </div>
  </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import steps from '@/utils/guiderecommend'
import { getMarketPricePIdAndBrands, getSetting, getaddress, getUsuallySizePriceStatistics } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      pickerOptionsa: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      pickerOptionsb: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      StartDate: '',
      EndDate: '',
      activeName: [],
      driver: null,
      brandlist: [],
      filter: {
        selected: {
          brands: [],
          address: {
            province: '广东省',
            city: '广州市',
            area: '天河区'
          }
        }
      },
      isband: false,
      isretract: false,
      brands: [],
      addressValue: ['广东省', '广州市', '天河区'],
      addressList: [],
      addressListProps: {
        value: 'name',
        label: 'name',
        children: 'children'
      },
      updateLoading: false,
      loading: false
    }
  },
  created () {
    this.loadbrandlist()
    this.loadgetaddress()
    this.getSetting()
    this.searchData()
  },
  mounted () {
    this.driver = new Driver({
      className: 'scoped-class',
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: false,
      // stageBackground: '#fff', // 引导对话的背景色
      overlayClickNext: false,
      doneBtnText: '完成',
      closeBtnText: '跳过',
      nextBtnText: '下一步',
      prevBtnText: '上一步',
      onReset: () => {
        window.localStorage.setItem('guiderecommend', 'BVewsJUqylM7BbbX3rkLTcRRuiXNH716')
      }
    })
    if (window.localStorage.getItem('guiderecommend') !== 'BVewsJUqylM7BbbX3rkLTcRRuiXNH716') {
      this.guide()
    }
  },
  methods: {
    // 获取最新日期
    getSetting () {
      getSetting().then(res => {
        this.StartDate = res.data.datearr[0]
        this.EndDate = res.data.datearr[1]
      })
    },
    // 选择开始日期
    changeStartDate (callback) {
      this.StartDate = callback
      if (this.compare(callback, this.EndDate) === true) {
        this.EndDate = null
        return false
      }
    },
    // 选择结束日期
    changeEndDate (callback) {
      if (this.compare(this.StartDate, callback) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.EndDate = null
        return false
      } else {
        this.EndDate = callback
      }
    },
    // 搜索数据
    searchData () {
      this.updateLoading = true
      if (this.StartDate === null) {
        this.$message({
          message: '请选择开始日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.EndDate === null) {
        this.$message({
          message: '请选择的结束日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.compare(this.StartDate, this.EndDate) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.StartDate = ''
        this.EndDate = ''
        this.updateLoading = false
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在获取数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      globalBus.$emit('recommendstatus', { status: 1 })
      getUsuallySizePriceStatistics({
        date_begin: this.StartDate,
        date_end: this.EndDate,
        brand: this.filter.selected.brands,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area
      }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          loading.close()
          globalBus.$emit('recommend', { luowengang_data: res.data.data.luowengang_data, panluo_data: res.data.data.panluo_data, gaoxian_data: res.data.data.gaoxian_data, StartDate: this.StartDate, EndDate: this.EndDate, newest_price_time: res.data.data.newest_price_time })
          globalBus.$emit('recommendstatus', { status: 2 })
          this.updateLoading = false
        } else {
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('获取数据失败', err)
      })
    },
    // 进入页面就获取品牌
    loadbrandlist () {
      getMarketPricePIdAndBrands().then(res => {
        if (res.data.status === 'success') {
          this.priceTimes = res.data.data.index
          this.brandlist = res.data.data.brand_group
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取地址
    loadgetaddress () {
      getaddress().then(res => {
        if (res.data.status === 'success') {
          this.addressList = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择品牌
    brandlists () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
    },
    // 选择地址
    changeProvinceCity (value) {
      this.filter.selected.address.province = value[0]
      this.filter.selected.address.city = value[1]
      this.filter.selected.address.area = value[2]
    },
    // 清空搜索条件
    clearChecked () {
      this.date = []
      this.addressValue = []
      this.filter.selected.brands = []
      this.filter.selected.address.province = ''
      this.filter.selected.address.city = ''
      this.filter.selected.address.area = ''
    },
    // 展开品牌
    changebrand () {
      if (this.activeName === undefined || this.activeName.length <= 0) {
        this.isretract = false
      } else {
        this.isretract = true
      }
    },
    // 收起品牌
    retract () {
      this.isretract = false
      this.activeName = []
    },
    // 访问引导页
    guide () {
      this.driver.defineSteps(steps)
      this.driver.start()
    },
    // 日期比较的方法
    compare (date1, date2) {
      const dates1 = new Date(date1)
      const dates2 = new Date(date2)
      if (dates1 > dates2) {
        return true
      } else {
        return false
      }
    },
    selectedbands () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
.layui-input-block {
  margin-left: 80px;
  min-height: 50px;
  line-height: 50px;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 155px;
}

.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwiths {
  padding: 0 18px;
}
/deep/.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border: none;
}
/deep/.el-checkbox-button__inner {
  border: none;
  padding: 8px 8px;
  font-size: 12px;
}
/deep/.el-checkbox-button,
.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
  margin: 5px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-drawer__body {
  padding: 30px;
}
/deep/.el-collapse-item__header {
  line-height: 20px;
}
.retract {
  position: fixed;
  bottom: 2%;
  right: 20px;
  z-index: 9999;
}
</style>
