const steps = [
  {
    element: '#guide-menu',
    popover: {
      title: ' ',
      description: '<b>点击可收起侧边栏</b>',
      position: 'left'
    }
  },
  {
    element: '#historicaldatasearch',
    popover: {
      title: ' ',
      description: '<b>可选择多个品牌</b>',
      position: 'left'
    }
  }
]
export default steps
