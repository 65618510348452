<template>
  <div class="advert-top"
       v-if="message">
    <div class="ico-horn">

    </div>
    <!-- 滚动文字区域 -->
    <div class="marquee-wrap">
      <ul class="marquee-box"
          id="marquee-box">
        <li class="marquee-list"
            v-for="i in 3"
            :key=i
            v-html="message"
            :id="i==1?'marquee':''"></li>
      </ul>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  mounted: function () {
    // 延时滚动
    setTimeout(() => {
      this.runMarquee()
    }, 1000)
  },
  methods: {
    runMarquee () {
      // 获取文字 计算后宽度
      var width = document.getElementById('marquee').getBoundingClientRect().width
      var marquee = document.getElementById('marquee-box')
      var disx = 0 // 位移距离
      // 设置位移
      setInterval(() => {
        disx-- // disx-=1; 滚动步长
        if (-disx >= width) {
          disx = 10
        }
        // marquee.style.transform = 'translateX(' + disx + 'px)'
        marquee.style.left = disx + 'px'
      }, 30)
    }
  }
};
// JavaScript Document
(function px2rem (doc, win) {
  var docEl = doc.documentElement
  var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  const recalc = function () {
    var clientWidth = docEl.clientWidth
    if (!clientWidth) return
    docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
  }
  if (!doc.addEventListener) return
  // 窗口大小发生变化，初始化
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
  setTimeout(function () {
    px2rem(doc, win)
  }, 200)
})(document, window)
</script>

<style>
li {
  list-style: none;
}
.advert-top {
  position: relative;
  display: flex;
  width: 25%;
  height: 28px;
  color: #5b5c5e;
  font-size: 12px;
  align-items: center;
  margin: 0 auto;
}
.ico-horn {
  display: flex;
  margin-right: 10px;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
}
.ico-horn > img {
  width: 30px;
  height: 30px;
}
/* 以下代码与滚动相关 */
.marquee-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.marquee-box {
  position: absolute;
  top: 50%;
  display: flex;
  white-space: nowrap;
  transform: translateY(-50%);
}
.marquee-list {
  margin-right: 10px;
}
.marquee-list span {
  padding: 0 0.04rem;
  color: #ffe17b;
  font-weight: 700;
}
</style>
