<template>
  <div class="layui-tab-content">
    <div class="layui-form">
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">日期</el-tag>
        </label>
        <div class="layui-input-inline"
             style="margin-left: 0px;">
          <el-date-picker v-model="StartDate"
                          type="date"
                          placeholder="选择开始日期"
                          value-format="yyyy-MM-dd"
                          @change="changeStartDate"
                          :picker-options="pickerOptionsa"> </el-date-picker>
        </div>
        <div class="layui-input-inline"
             style="margin-left: 0px;">
          <el-date-picker v-model="EndDate"
                          type="date"
                          placeholder="选择结束日期"
                          value-format="yyyy-MM-dd"
                          @change="changeEndDate"
                          :picker-options="pickerOptionsb"> </el-date-picker>
        </div>
      </div>
      <div class="layui-form-item"
           id="historicaldatasearch">
        <label class="layui-form-label">
          <el-tag class="butwith">品牌</el-tag>
        </label>
        <div class="layui-input-block">
          <el-select v-model="filter.selected.brands"
                     multiple
                     filterable
                     remote
                     placeholder="请输入品牌"
                     :loading="loading"
                     @change="selectedbands">
            <el-option v-for="item in brandlist"
                       :key="item.label"
                       :label="item.zname"
                       :value="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="layui-form-item">
        <el-collapse v-model="activeName"
                     @change="changebrand">
          <el-collapse-item>
            <template slot="title">
              <span v-if="isband==false">选择品牌</span>
              <span v-else>
                <font v-for="item in filter.selected.brands"
                      :key="item">{{item}}、</font>
              </span>
            </template>
            <el-checkbox-group v-model="filter.selected.brands"
                               @change="brandlists">
              <el-checkbox-button v-for="item in brandlist"
                                  :label="item.name"
                                  :key="item.label">{{item.zname}}</el-checkbox-button>
            </el-checkbox-group>
          </el-collapse-item>

        </el-collapse>
      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">规格</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model="specs"
                          @change="specslist">
            <el-radio label="螺纹钢">螺纹钢</el-radio>
            <el-radio label="高线">高线</el-radio>
            <el-radio label="盘螺">盘螺</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="layui-form-item"
           :style="luowengangdisplay">
        <label class="layui-form-label">
          <el-tag class="butwiths">螺纹钢</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model="filter.selected.sizes">
            <el-radio v-for='item in filter.options.LWG_sizes'
                      :key='item.label'
                      :label='item'
                      :value='item'></el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="layui-form-item"
           :style="gaoxiandisplay">
        <label class="layui-form-label">
          <el-tag class="butwith">高线</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model="filter.selected.sizes">
            <el-radio v-for='item in filter.options.GX_sizes'
                      :key="item.label"
                      :label='item'
                      :value='item'></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="layui-form-item"
           :style="panluodisplay">
        <label class="layui-form-label">
          <el-tag class="butwith">盘螺</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model="filter.selected.sizes">
            <el-radio v-for='item in filter.options.PL_sizes'
                      :key='item.label'
                      :label='item'
                      :value='item'></el-radio>
          </el-radio-group>
        </div>
      </div>
      <!-- <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwithh">到货类型</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model="arrivaltype">
            <el-radio :label="1">全部</el-radio>
            <el-radio :label="2">纯仓提</el-radio>
            <el-radio :label="3">纯直送</el-radio>
          </el-radio-group>
        </div>
      </div> -->
      <!-- 以下是多品牌单规格显示 -->
      <div class="layui-form-item"
           :style="datatypedisplay">
        <label class="layui-form-label">
          <el-tag class="butwithh">数据类型</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model="datatype"
                          @change="datatypelist">
            <el-radio :label="2">工程价格</el-radio>
            <!-- <el-radio :label="1">现货价格</el-radio> -->
            <el-radio :label="3">参考价格</el-radio>
            <!-- <el-radio :label="4">现货价差</el-radio> -->
            <el-radio :label="5">工程价差</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">地址</el-tag>
        </label>
        <div class="layui-input-block">
          <div class="block">
            <el-cascader v-model="addressValue"
                         :options="addressList"
                         @change="changeProvinceCity"
                         :props="addressListProps"></el-cascader>
          </div>
        </div>

      </div>

      <div class="layui-form-item">
        <div class="buttongt">
          <el-button type="primary"
                     @click="searchData"
                     :loading="updateLoading">确定</el-button>
          <el-button type="info"
                     @click="clearChecked">重置</el-button>
        </div>
      </div>
    </div>
    <div class="retract"
         v-if="isretract==true">
      <el-button type="primary"
                 @click="retract">收起品牌</el-button>
    </div>
  </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import steps from '@/utils/guidehistoricaldata'
import { getStandardTree, getaddress, getMarketPricePIdAndBrands, getSetting, getHistorySizeEngineerPrice } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      pickerOptionsa: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      pickerOptionsb: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      StartDate: '',
      EndDate: '',
      datatype: 2,
      specs: '螺纹钢',
      arrivaltype: 1,
      luowengangdisplay: 'display:block',
      gaoxiandisplay: 'display:none',
      panluodisplay: 'display:none',
      datatypedisplay: 'display:none',
      activeName: [],
      is_newest: '1',
      is_newests: false,
      priceDate: '',
      priceTime: null,
      priceTimes: [],
      brandlist: [],
      checkList: [],
      filter: {
        selected: {
          brands: ['广钢'],
          sizes: 20,
          address: {
            province: '广东省',
            city: '广州市',
            area: '天河区'
          }
        },
        options: {
          brands: ['广钢'],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: []
        }
      },
      addressValue: ['广东省', '广州市', '天河区'],
      addressList: [],
      addressListProps: {
        value: 'name',
        label: 'name',
        children: 'children'
      },
      drawer: false,
      direction: 'rtl',
      modals: false,
      isband: true,
      isretract: false,
      driver: null,
      updateLoading: false,
      loading: false
    }
  },
  created () {
    // 组件初始化好，请求获取用户资料
    this.getSetting()
    this.loadgetStandardTree()
    this.loadgetaddress()
    this.loadbrandlist()
  },
  mounted () {
    this.driver = new Driver({
      className: 'scoped-class',
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: false,
      // stageBackground: '#fff', // 引导对话的背景色
      overlayClickNext: false,
      doneBtnText: '完成',
      closeBtnText: '跳过',
      nextBtnText: '下一步',
      prevBtnText: '上一步',
      onReset: () => {
        window.localStorage.setItem('guidehistoricaldata', 'BVewsJUqylM7BbbX3rkLTcRRuiXNH719')
      }
    })
    if (window.localStorage.getItem('guidehistoricaldata') !== 'BVewsJUqylM7BbbX3rkLTcRRuiXNH719') {
      this.guide()
    }
  },
  methods: {
    // 获取最新日期
    getSetting () {
      getSetting().then(res => {
        this.StartDate = res.data.datearr[0]
        this.EndDate = res.data.datearr[1]
        this.searchData()
      })
    },
    // 选择开始日期
    changeStartDate (callback) {
      this.StartDate = callback
      if (this.compare(callback, this.EndDate) === true) {
        this.EndDate = null
        return false
      }
    },
    // 选择结束日期
    changeEndDate (callback) {
      if (this.compare(this.StartDate, callback) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.EndDate = null
        return false
      } else {
        this.EndDate = callback
      }
    },
    // 进入页面就获取品牌
    loadbrandlist () {
      getMarketPricePIdAndBrands().then(res => {
        if (res.data.status === 'success') {
          this.priceTimes = res.data.data.index
          this.brandlist = res.data.data.brand_group
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取规格
    loadgetStandardTree () {
      getStandardTree().then(res => {
        if (res.data.status === 'success') {
          for (const item of res.data.data) {
            if (item.cate_spec === '螺纹钢') {
              this.filter.options.LWG_sizes = item.size
            }
            if (item.cate_spec === '盘螺') {
              this.filter.options.PL_sizes = item.size
            }
            if (item.cate_spec === '高线') {
              this.filter.options.GX_sizes = item.size
            }
          }
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取地址
    loadgetaddress () {
      getaddress().then(res => {
        if (res.data.status === 'success') {
          this.addressList = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择地址
    changeProvinceCity (value) {
      this.filter.selected.address.province = value[0]
      this.filter.selected.address.city = value[1]
      this.filter.selected.address.area = value[2]
    },
    // 提交搜索
    searchData () {
      this.updateLoading = true
      if (this.filter.selected.brands.length > 5) {
        this.$message({
          message: '最多选择5个品牌',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.StartDate === null) {
        this.$message({
          message: '请选择开始日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.EndDate === null) {
        this.$message({
          message: '请选择的结束日期',
          type: 'warning'
        })
        this.updateLoading = false
        return
      }
      if (this.compare(this.StartDate, this.EndDate) === true) {
        this.$message({
          message: '你选择的开始日期大于结束日期',
          type: 'warning'
        })
        this.StartDate = ''
        this.EndDate = ''
        this.updateLoading = false
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '数据正在加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })

      getHistorySizeEngineerPrice({
        cate_spec: [this.specs],
        size: [this.filter.selected.sizes],
        brand: this.filter.selected.brands,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area,
        date_begin: this.StartDate,
        date_end: this.EndDate
      }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          globalBus.$emit('HistoricalData', { data: res.data.data, brands: this.filter.selected.brands, datatype: this.datatype, arrivaltype: this.arrivaltype, max_and_min: res.data.max_and_min, StartDate: this.StartDate, EndDate: this.EndDate, catespec: this.specs, size: this.filter.selected.sizes })
          this.updateLoading = false
          loading.close()
        } else {
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
          loading.close()
        }
      }).catch(err => {
        this.updateLoading = false
        loading.close()
        console.log('获取数据失败', err)
      })
    },
    // 选择品牌
    brandlists () {
      this.isband = true
      if (this.filter.selected.brands.length > 5) {
        this.$message({
          message: '最多选择5个品牌',
          type: 'warning'
        })
      }
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
      if (this.filter.selected.brands.length > 1) {
        this.pricetypedisplay = 'display:block'
        this.datatypedisplay = 'display:block'
      } else {
        this.pricetypedisplay = 'display:none'
        this.datatypedisplay = 'display:none'
      }
    },
    // 选择规格
    specslist () {
      if (this.specs === '螺纹钢') {
        this.luowengangdisplay = 'display:block'
        this.gaoxiandisplay = 'display:none'
        this.panluodisplay = 'display:none'
      }
      if (this.specs === '高线') {
        this.luowengangdisplay = 'display:none'
        this.gaoxiandisplay = 'display:block'
        this.panluodisplay = 'display:none'
      }
      if (this.specs === '盘螺') {
        this.luowengangdisplay = 'display:none'
        this.gaoxiandisplay = 'display:none'
        this.panluodisplay = 'display:block'
      }
    },
    // 选择数据类型
    datatypelist () {
      if (this.datatype === 2) {
        this.addressdisplay = 'display:block'
      } else {
        this.addressdisplay = 'display:none'
      }
    },
    // 清空搜索条件
    clearChecked () {
      this.addressValue = []
      this.StartDate = []
      this.EndDate = []
      this.specs = ''
      this.arrivaltype = ''
      this.datatype = ''
      this.filter.selected.brands = []
      this.filter.selected.LWG_sizes = []
      this.filter.selected.GX_sizes = []
      this.filter.selected.PL_sizes = []
    },
    // 展开品牌
    changebrand () {
      if (this.activeName === undefined || this.activeName.length <= 0) {
        this.isretract = false
      } else {
        this.isretract = true
      }
    },
    // 收起品牌
    retract () {
      this.isretract = false
      this.activeName = []
    },
    // 访问引导页
    guide () {
      this.driver.defineSteps(steps)
      this.driver.start()
    },
    // 日期比较的方法
    compare (date1, date2) {
      const dates1 = new Date(date1)
      const dates2 = new Date(date2)
      if (dates1 > dates2) {
        return true
      } else {
        return false
      }
    },
    selectedbands () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
      this.brandlists()
    }
  }
}
</script>
<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -10px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox {
  margin-right: 20px;
}

/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-radio {
  margin-right: 20px;
  margin-top: 15px;
}
/deep/.el-radio__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-radio {
  margin-right: 20px;
}

/deep/.el-radio__inner::after {
  left: 8px;
  top: 8px;
  width: 8px;
  height: 8px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
.layui-input-block {
  margin-left: 80px;
  min-height: 36px;
  line-height: 36px;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 155px;
}

.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwithh {
  padding: 0 10px;
}
.butwiths {
  padding: 0 15px;
}
.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
/deep/.el-switch__label {
  height: 50px;
  line-height: 50px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border: none;
}
/deep/.el-checkbox-button__inner {
  border: none;
  padding: 8px 8px;
  font-size: 12px;
}
/deep/.el-checkbox-button,
.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
  margin: 5px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-drawer__body {
  padding: 30px;
}
/deep/.el-collapse-item__header {
  line-height: 20px;
}
.retract {
  position: fixed;
  bottom: 2%;
  right: 20px;
}
</style>
