<template>
  <div>
    <div class="header">
      <div class="layui-container">
        <div class="nav-right">
          <span class="login-btn">已有帐号？<a href="/#/login">立即登录</a></span>
        </div>
        <a class="logo"
           href=""></a>
      </div>
    </div>
    <div class="layui-col-xs12 layui-col-md12 layui-tab-height"
         style="background-color: #16469e;background-image: linear-gradient(to left, rgb(0, 192, 243), rgb(92, 159, 247) 60%, rgb(22, 70, 158) 80%); ">
      <div class="layui-container">
        <div class="layui-col-xs6 layui-col-md8 mb mt">
          <img src="../assets/images/bglogin.png"
               width="750">
        </div>
        <div class="layui-col-xs6 layui-col-md4 mb mt">
          <div class="login-link">
            <div class="layui-tab layui-tab-brief"
                 lay-filter="docDemoTabBrief">
              <ul class="layui-tab-title">
                <li class="layui-this">欢迎注册</li>
              </ul>
            </div>
            <div class="layui-tab-content">
              <div class="layui-tab-item layui-show"
                   style="margin-top:25px;">
                <el-form ref="login-form"
                         :model="user"
                         :rules="formRules">
                  <el-form-item prop="account">
                    <el-input v-model="user.account"
                              placeholder="请输入手机号"
                              prefix-icon="el-icon-user"></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-tooltip class="item"
                                effect="dark"
                                placement="top-start">
                      <div slot="content">长度为6~14个字符<br />字母/数字以及标点符号至少包含2种<br>不允许有空格、中文</div>
                      <el-input v-model="user.password"
                                show-password
                                placeholder="请输入密码"
                                prefix-icon="el-icon-lock"></el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item prop="name">
                    <el-input v-model="user.name"
                              placeholder="请输入姓名"
                              prefix-icon="el-icon-s-custom"></el-input>
                  </el-form-item>
                  <el-form-item prop="company">
                    <el-input v-model="user.company"
                              placeholder="请输入公司名称"
                              prefix-icon="el-icon-office-building"></el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-input class="code"
                              v-model="user.code"
                              placeholder="请输入验证码"
                              prefix-icon="el-icon-key"></el-input>
                    <el-button class="codebutton"
                               @click="getcode"
                               :disabled="disabled"
                               v-if="disabled==false">获取验证码</el-button>
                    <el-button class="codebutton"
                               @click="getcode"
                               :disabled="disabled"
                               v-if="disabled==true">{{btntxt}}</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button class="login-btn"
                               type="primary"
                               :loading="loginLoading"
                               @click="onRegister">注册</el-button>
                  </el-form-item>
                  <el-form-item prop="agree">
                    <el-checkbox v-model="user.agree">我已阅读并同意
                    </el-checkbox>
                  </el-form-item>
                </el-form>
                <div class="agree">
                  <el-link type="primary"
                           :underline="false"
                           @click="useragreement(1)">《用户协议》</el-link>和<el-link type="primary"
                           :underline="false"
                           @click="useragreement(2)">《隐私条款》</el-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-col-xs12 layui-col-md12 containers">
      <div class="layui-container">
        <div class="copyright-box">
          <div id="csdn-copyright-footer"
               class="column dark">
            <ul class="footer-column-t">
              <li>
                <img src="../assets/images/tel.png"
                     alt="">
                <span> 020-83887466-8056</span>
              </li>
              <li>
                <img src="../assets/images/email.png"
                     alt="">
                <a href="mailto:gzgwzg@yeah.net"
                   target="_blank"> gzgwzg@yeah.net</a>
              </li>
              <li>
                工作时间&nbsp;9:00-17:30
              </li>
              <li>
                <a href="javascript:;"
                   @click="about"> 关于我们</a>
              </li>
            </ul>
            <ul class="footer-column-b">
              <li><img src="../assets/images/beian.png"
                     alt=""><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010402002322"
                   rel="noreferrer"
                   target="_blank">粤公网安备 44010402002322号</a></li>
              <li><a href="http://beian.miit.gov.cn/publish/query/indexFirst.action"
                   rel="noreferrer"
                   target="_blank">粤ICP备17117697号</a></li>
              <li>©2016-2020 智钢数据服务（广州）有限公司</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="agreement.title"
               append-to-body
               :visible.sync="centerDialogVisible"
               center
               :lock-scroll="locks"
               class="abow_dialog">
      <div class="el-dialog-div"
           v-html="agreement.content"></div>
      <span slot="footer"
            class="dialog-footer">

      </span>
    </el-dialog>
    <el-dialog title="关于我们"
               append-to-body
               :visible.sync="dialogTableVisible">
      <div class="about"> 智钢数据服务（广州）有限公司（简称：智钢），2018年成立于中国国际商贸中心——广州。基于母公司八年的行业经验，乘着“互联网+”的时代浪潮，为传统钢铁行业转型升级贡献一份力量。
        智钢致力于打造华南地区钢铁行业的专业服务平台。旗下目前主打产品有“六六钢铁”和“一拍即点”，六六钢铁——以真实交易数据构建华南现货价格体系，利用大数据提供交易、仓储、物流全方位数据服务，为用户提供高效、安全、透明的钢铁在线交易平台；一拍即点——引入AI智能识别技术代替人工数钢筋，用数字技术提升工地整体精细化管理水平。通过互联网真正实现钢铁行业上下游信息化、数据化、智能化的转型升级。</div>
    </el-dialog>
  </div>
</template>

<script>
import { register, getUsercode, useragreement } from '@/api/user'
export default {
  name: 'Register',
  data () {
    return {
      user: {
        account: '',
        password: '',
        name: '',
        company: '',
        code: '',
        agree: true
      },
      locks: false,
      centerDialogVisible: false,
      dialogTableVisible: false,
      agreement: [],
      disabled: false,
      time: 0,
      btntxt: '重新发送',
      loginLoading: false,
      formRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,14}$/, message: '密码由数字+字母形式组合', trigger: 'blur' }

        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '验证码不能为空', trigger: 'blur' },
          { pattern: /^\d{4}$/, message: '请输入正确的验证码格式', trigger: 'blur' }
        ],

        agree: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                // 验证通过
                callback()
              } else {
                // 验证失败
                callback(new Error('请同意用户协议'))
              }
            },
            // message: '请勾选同意用户协议',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    // 绑定事件
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    onRegister () {
      this.$refs['login-form'].validate(valid => {
        // 如果表单验证失败，停止请求提交
        if (!valid) {
          return false
        }
        this.register()
      })
    },
    register () {
      this.loginLoading = true
      register(this.user).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.$router.push({
            name: 'Login'
          })
        } else {
          this.$message.error(res.data.message)
        }
        this.loginLoading = false
      }).catch(err => {
        console.log('注册失败', err)
        this.$message.error('注册失败，请稍后再试')
        this.loginLoading = false
      })
    },
    getcode () {
      this.$refs['login-form'].validateField('account', (err) => {
        if (err) {
          return false
        } else {
          getUsercode({ account: this.user.account }).then(res => {
            if (res.data.status === 'success') {
              this.$message({
                message: res.data.message,
                type: 'success'
              })
              this.time = 60
              this.disabled = true
              this.timer()
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('发送失败，请确保手机号正确或等待60秒后再发')
          })
        }
      })
    },
    timer () {
      if (this.time > 0) {
        this.time--
        this.btntxt = this.time + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '获取验证码'
        this.disabled = false
      }
    },
    useragreement (id) {
      this.centerDialogVisible = true
      useragreement({ id: id }).then(res => {
        this.agreement = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    keyDown (e) {
      // 如果是回车则执行登录方法
      if (e.keyCode === 13) {
        this.onRegister()
      }
    },
    about () {
      this.dialogTableVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.containers {
  width: 100%;
  height: 100%;
}
.layui-container {
  max-width: 1440px;
}
.layui-tab-content {
  padding: 0px;
}
.layui-tab-height {
  min-height: 708px;
}
.mb {
  margin-bottom: 60px;
}
.mt {
  margin-top: 60px;
}
.pl {
  padding-left: 60px;
}
.el-button {
  width: 100%;
}
.el-form-item {
  margin-bottom: 32px;
}
.code {
  width: 60%;
  float: left;
}
.codebutton {
  width: 35%;
  float: left;
  margin-left: 5%;
}
.login {
  color: #409eff;
}
.agree {
  position: absolute;
  bottom: 86px;
  left: 160px;
}
/deep/.el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  height: 90%;
}
/deep/.el-dialog__body {
  overflow: auto;
}
.el-dialog-div {
  line-height: 25px;
}
.copyright-box {
  width: 100%;
  margin-top: 15px;
}
.column {
  margin: 0 auto;
  padding: 24px 34px 20px;
}
.column .footer-column-t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.column .footer-column-t li {
  margin: 0 8px;
  color: #999aaa;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column a {
  text-decoration: none;
  color: inherit;
}
.column .footer-column-b {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.column .footer-column-b li {
  font-size: 12px;
  color: #999aaa;
  height: 16px;
  line-height: 16px;
  margin: 4px 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column .footer-column-b li img {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 4px;
}
.about {
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
}
</style>
