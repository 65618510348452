export default (Vue) => {
  // Vue.directive('loadmore', {
  // bind (el, binding, vnode) {
  // .el-table__body-wrapper 是表格组件中body标签的class样式名，也就是下拉框的父级class
  // const tableWrap = el.querySelector('.el-table__body-wrapper')
  // tableWrap.addEventListener('scroll', function () {
  // 定义小于多少px时执行刷新
  // const sign = 20
  // const scrollHeight = this.scrollHeight - this.scrollTop - this.clientHeight
  // if (scrollHeight <= sign) {
  // 指令要触发的事件
  // vnode.context.getNewData()
  // }
  // })
  // }
  // })
  Vue.directive('loadmore', {
    bind (el, binding, vnode) {
      var p = 0
      var t = 0
      var down = true
      var selectWrap = el.querySelector('.el-table__body-wrapper')
      selectWrap.addEventListener('scroll', function () {
        // 判断是否向下滚动
        p = this.scrollTop
        if (t < p) {
          down = true
        } else {
          down = false
        }
        t = p
        // 判断是否到底
        const sign = 0
        const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
        if (scrollDistance <= sign && down) {
          // 指令要触发的事件
          vnode.context.getNewData()
        }
      })
    }
  })
}
