const steps = [
  {
    element: '#Price',
    popover: {
      title: ' ',
      description: '<b>这里是每日现货指数、资料比价和历史数据，资源比价即为原版本的资源推荐<b>',
      position: 'bottom'
    }
  },
  {
    element: '#market',
    popover: {
      title: ' ',
      description: '<b>每日出入库、每周库存、期现基差在这里查看</b>',
      position: 'bottom'
    }
  }
]
export default steps
