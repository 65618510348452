<template>
  <div class="realtimeprice">
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf ">
          <div class="widget-head fl">
            <div class="widget-title fl">品牌资料</div>
            <div class="fr fr-title">
              <div class="fl pr20">
                <el-button type="primary"
                           :loading="updateLoading"
                           @click="getBrandInfoToWord">下载品牌资料</el-button>
              </div>
            </div>
          </div>
          <div class="widget-body am-cf">
            <div v-if="isimg==true">
              <el-carousel :interval="40000"
                           height="calc(100vh - 248px)"
                           trigger="click"
                           type="card">
                <el-carousel-item v-for="(item,index) in img"
                                  :key="index">
                  <viewer :images="img"> <img :src="item"
                         :onerror="errorImg"></viewer>
                </el-carousel-item>
              </el-carousel>
            </div>
            <el-carousel height="calc(100vh - 220px)"
                         v-else>
              <div class="content">
                <div class="title">
                  <el-tag>{{title}}</el-tag>
                </div>
                {{content}}
              </div>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>
          </div>
          <el-tabs v-model="activeName"
                   @tab-click="handleClick">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i></div>
  </div>
</template>

<script>
import Brandc from '@/components/Brandc'
import globalBus from '@/utils/global-bus'
import { getBrandInfoToWord, getUserNotice } from '@/api/global'
export default {
  data () {
    return {
      activeName: '1',
      curTabcompnents: {
        1: 'Brandc'
      },
      isimg: false,
      content: '',
      title: '',
      typeid: '',
      img: [],
      brandid: '',
      brand: '',
      updateLoading: false,
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      topnotice: '',
      isstatus: 0
    }
  },
  components: { Brandc },
  created () {
    // 注册自定义事件
    globalBus.$on('brandinfo', (data) => {
      this.isimg = data.isimg
      this.typeid = data.id
      this.title = data.title
      this.brandid = data.data.brand_id
      this.brand = data.brand
      if (data.status === 1) {
        this.title = '品牌介绍'
        this.content = data.data.context
      }
      if (data.id === 1) {
        this.content = data.data.context
      }
      if (data.id === 2) {
        this.content = data.data.cate_spec
      }
      if (data.id === 3) {
        this.img = data.data.cate_style
      }
      if (data.id === 4) {
        this.img = data.data.weight_standard
      }
      if (data.id === 5) {
        this.content = data.data.transport_way
      }
      if (data.id === 6) {
        this.img = data.data.steel_certificate
      }
      if (data.id === 7) {
        this.img = data.data.quality_certificate
      }
      if (data.id === 8) {
        this.img = data.data.production_license
      }
      if (data.id === 9) {
        this.img = data.data.environmental_certificate
      }
      if (data.id === 10) {
        this.img = data.data.healthy_certificate
      }
      if (data.id === 11) {
        this.img = data.data.national_inspection_report
      }
      if (data.id === 12) {
        this.img = data.data.other
      }
    })
  },
  mounted () {
    this.loadgetUserNotice()
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top:92px'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    },
    handleClick (tab) {
      this.activeName = tab.name
    },
    // 下载品牌资料
    getBrandInfoToWord () {
      const loading = this.$loading({
        lock: true,
        text: '正在获取中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      this.updateLoading = true
      getBrandInfoToWord({ brandid: this.brandid }).then(res => {
        if (res.data.status === 'success') {
          this.updateLoading = false
          this.downloadFile(res.data.url)
          loading.close()
        } else {
          this.updateLoading = false
          loading.close()
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('失获取数据败', err)
      })
    },
    downloadFile (url) {
      var iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = url
      document.body.appendChild(iframe)
    }
  }
}
</script>

<style lang="less" scoped>
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
}
.layui-side {
  margin-right: 20px;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}
.layui-side-content {
  padding: 20px 0px;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}
@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 9999;
  }
}
/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
#selectBox {
  position: relative;
  width: 450px;
  height: auto;
  padding: 10px;
  background-color: #409eff;
  color: #fff;
}
#selectBox p {
  margin-top: 20px;
}
#selectBox .el-checkbox {
  color: #fff;
}
.fixWidth {
  display: inline-block;
  width: 90px;
  margin-left: 50px;
}
.fixBox {
  font-size: 14px;
  position: absolute;
  right: 40px;
  top: 200px;
  z-index: 2;
}
#offBox {
  position: absolute;
  right: -40px;
  top: 0;
  display: inline-block;
  width: 20px;
  height: auto;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: #409eff;
}
#onBox {
  position: absolute;
  left: 0;
  top: 25px;
  display: inline-block;
  width: 20px;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  height: auto;
  color: #fff;
  background-color: #409eff;
}
.inline_box {
  display: inline-block;
  width: 40%;
  margin-left: 20px;
}
.same_color {
  color: #f56c6c;
}
table {
  margin-top: 20px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e0e6ed;
  text-align: center;
  font-size: 14px;
}
thead {
  background-color: #eef1f6;
}
thead th {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
table td {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e0e6ed;
  border-right: 1px solid #e0e6ed;
}
table tbody tr:hover {
  background-color: #eff2f7;
}
.sort-icon {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  font-size: 12px;
  color: #97a8be;
}
.sort-icon > div {
  vertical-align: middle;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.addr_box {
  padding: 10px 15px;
  background-color: #fff;
}
.price_box {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #fff;
}
.button_box {
  text-align: center;
}
.button_box button {
  width: 80px;
  text-align: center;
  padding: 8px;
  margin: 5px;
}
.red_font {
  color: #aa1010;
}
.green_font {
  color: #10aa10;
}
.yellow_font {
  color: #aaaa10;
}
.sm_width {
  width: 80px;
}
.top_style {
  font-weight: bold;
  width: 99.9%;
  border: 1px solid #dfe6ec;
  display: inline-block;
  background: #eef1f6;
  margin-bottom: 0px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #656f82;
  opacity: 0.8;
  border-left: none;
}

.overflow_box {
  min-width: 1000px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.overflow_box table {
  min-width: 100%;
  width: auto;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.adds_css {
  width: 60%;
  display: inline-block;
}
.empty_css {
  display: inline-block;
}
.table_box {
  margin-top: 20px;
  clear: both;
}
.same_table {
  background: #fff;
  padding: 15px;
  width: 100%;
  margin-top: 20px;
}
.table_box tr {
  width: 100%;
}
.table_box th,
td {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
.table_box th {
  width: 15%;
}
.table_box tr:hover {
  background-color: #e0e6ed;
}
.img_style {
  display: inline-block;
  width: 100px;
  height: auto;
  cursor: pointer;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 500px;
  margin: 0;
  position: relative;
}

.el-carousel__item:nth-child(2n) {
  background-color: #ffffff;
  text-align: center;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #ffffff;
  text-align: center;
}
/deep/ .el-carousel--horizontal {
  overflow-x: visible;
  margin: 100px 20px 0 20px;
}
.content {
  line-height: 32px;
  font-size: 16px;
  min-height: 846px;
}
.title {
  padding: 20px 0;
}
img {
  width: 75%;
}
</style>
