<template>
  <div>
    <el-collapse>
      <div class="layui-tab-content">
        <div class="layui-form"
             action="">
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">时间</el-tag>
            </label>
            <div class="layui-input-block">
              <el-radio-group v-model="is_newest"
                              @change="changeStatus">
                <el-radio label="1">今日最新</el-radio>
                <el-radio label="0">过往日期</el-radio>
              </el-radio-group>
            </div>

          </div>
          <div class="layui-form-item"
               v-if="is_newest==0">
            <div class="layui-input-inline">
              <el-date-picker v-model="priceDate"
                              type="date"
                              placeholder="选择日期"
                              value-format="yyyy-MM-dd"
                              @change="changeDate"
                              :picker-options="pickerOptions0"> </el-date-picker>
            </div>
            <div class="layui-input-inline"
                 style="margin-left: 10px;">
              <el-select v-model="priceTime"
                         @change="changeTime"
                         placeholder="请选择现货价次序">
                <el-option v-for='item in priceTimes'
                           :label='item.time'
                           :key='item.label'
                           :value='item.id'>
                </el-option>
              </el-select>
            </div>
            <div class="layui-input-inline"
                 style="">
              <el-select v-model="webpriceTime"
                         @change="changewebTime"
                         placeholder="请选择参考价次序">
                <el-option v-for='item in webpriceTimes'
                           :label='item.date'
                           :key='item.id'
                           :value='item.id'>
                </el-option>
              </el-select>
            </div>

          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">列表</el-tag>
            </label>
            <div class="layui-input-block">
              <el-select v-model="interest"
                         @change="changeInterest"
                         placeholder="请选择">
                <el-option v-for="item in userinterestlist"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="interest">
            <div class="layui-form-item">
              <label class="layui-form-label">
                <el-tag class="butwith"
                        style="margin-left: 0px;">品牌</el-tag>
              </label>
              <div class="layui-input-block">
                <el-select v-model="follow.selected.brands"
                           multiple
                           filterable
                           remote
                           placeholder="请输入品牌"
                           :loading="loading"
                           @change="selectedbands"
                           @focus="selectedfocus">
                  <el-option v-for="item in brandlist"
                             :key="item.label"
                             :label="item.zname"
                             :disabled="item.status === 0"
                             :value="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="layui-form-item">
              <el-collapse v-model="activeName"
                           @change="changebrand">
                <el-collapse-item>
                  <template slot="title">
                    <span v-if="isfollowband==false">选择品牌</span>
                    <span v-else>
                      <font v-for="item in follow.selected.brands"
                            :key="item">{{item}}、</font>
                    </span>
                  </template>
                  <el-checkbox-group v-model="follow.selected.brands"
                                     @change="followbrand">
                    <el-checkbox-button v-for="item in brandlist"
                                        :label="item.name"
                                        :disabled="item.status === 0"
                                        :key="item.label">{{item.zname}}</el-checkbox-button>
                  </el-checkbox-group>
                </el-collapse-item>

              </el-collapse>

            </div>
            <div class="layui-form-item">
              <label class="layui-form-label">
                <el-tag class="butwiths">螺纹钢</el-tag>
              </label>
              <div class="layui-input-block">
                <el-checkbox-group v-model="follow.selected.LWG_sizes">
                  <el-checkbox v-for='item in follow.options.LWG_sizes'
                               :key='item.label'
                               :label='item'
                               :value='item'></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

            <div class="layui-form-item">
              <label class="layui-form-label">
                <el-tag class="butwith">高线</el-tag>
              </label>
              <div class="layui-input-block">
                <el-checkbox-group v-model="follow.selected.GX_sizes">
                  <el-checkbox v-for='item in follow.options.GX_sizes'
                               :key="item.label"
                               :label='item'
                               :value='item'></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="layui-form-item">
              <label class="layui-form-label">
                <el-tag class="butwith">盘螺</el-tag>
              </label>
              <div class="layui-input-block">
                <el-checkbox-group v-model="follow.selected.PL_sizes">
                  <el-checkbox v-for='item in follow.options.PL_sizes'
                               :key='item.label'
                               :label='item'
                               :value='item'></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="layui-form-item">
              <label class="layui-form-label">
                <el-tag class="butwith">地址</el-tag>
              </label>
              <div class="layui-input-block">
                <div class="block">
                  <el-cascader v-model="interestaddrs"
                               :options="addressList"
                               @change="changeProvinceCity"
                               :props="addressListProps"></el-cascader>
                </div>
              </div>

            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag>排序方式</el-tag>
            </label>
            <div class="layui-input-block">
              <el-radio-group v-model='follow.selected.sortType'>
                <el-radio label="0">到货价升序</el-radio>
                <el-radio label="1">价差降序</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="layui-form-item">
            <div class="buttongt">
              <el-button type="primary"
                         @click="searchInterestDataAll">确定</el-button>
              <el-button type="success"
                         @click="drawer = true">+新增</el-button>
              <el-button type="warning"
                         @click="searchimgData">获取报价截图</el-button>
              <el-button type="info"
                         @click="openList">查看列表</el-button>
            </div>
          </div>

        </div>
      </div>
    </el-collapse>

    <div class="layui-form layui-forms">
      <el-drawer title="新增我的关注"
                 append-to-body
                 :visible.sync="drawer"
                 :direction="direction"
                 :before-close="handleClose">
        <div class="layui-form-item">
          <label class="layui-form-label">
            <el-tag class="butwith">名称</el-tag>
          </label>
          <div class="layui-input-block">
            <el-input v-model="filter.selected.name"
                      placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label">
            <el-tag class="butwith">品牌</el-tag>
          </label>
          <div class="layui-input-block">

            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  <span v-if="isband==false">选择品牌</span>
                  <span v-else>
                    <font v-for="item in filter.selected.brands"
                          :key="item">{{item}}、</font>
                  </span>
                </template>
                <el-checkbox-group v-model="filter.selected.brands"
                                   @change="brandlists">
                  <el-checkbox-button v-for="item in brandlist"
                                      :label="item.name"
                                      :key="item.label">{{item.zname}}</el-checkbox-button>
                </el-checkbox-group>
              </el-collapse-item>

            </el-collapse>

          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label">
            <el-tag class="butwiths">螺纹钢</el-tag>
          </label>
          <div class="layui-input-block">
            <el-checkbox-group v-model="filter.selected.LWG_sizes">
              <el-checkbox v-for='item in filter.options.LWG_sizes'
                           :key='item.id'
                           :label='item'
                           :value='item'></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label">
            <el-tag class="butwith">高线</el-tag>
          </label>
          <div class="layui-input-block">
            <el-checkbox-group v-model="filter.selected.GX_sizes">
              <el-checkbox v-for='item in filter.options.GX_sizes'
                           :key="item.id"
                           :label='item'
                           :value='item'></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label">
            <el-tag class="butwith">盘螺</el-tag>
          </label>
          <div class="layui-input-block">
            <el-checkbox-group v-model="filter.selected.PL_sizes">
              <el-checkbox v-for='item in filter.options.PL_sizes'
                           :key="item.id"
                           :label='item'
                           :value='item'></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label">
            <el-tag class="butwith">地址</el-tag>
          </label>
          <div class="layui-input-block">
            <div class="block">
              <el-cascader v-model="addressValue"
                           :options="addressList"
                           @change="changeProvinceCity"
                           :props="addressListProps"></el-cascader>
            </div>
          </div>

        </div>
        <div class="layui-form-item">
          <div class="buttongt">
            <el-button type="primary"
                       @click="saveNewInterest">确定</el-button>
            <el-button type="info"
                       @click="clearChecked">重置</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
    <el-drawer title="我的关注列表"
               append-to-body
               :visible.sync="dialogTableVisible"
               direction="rtl"
               size="40%">
      <el-table :data="userinterestlist">
        <el-table-column property="name"
                         label="项目名称"></el-table-column>
        <el-table-column property="updated_at"
                         label="添加日期">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.updated_at }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-drawer title="修改关注"
                   :append-to-body="true"
                   :before-close="handleClose"
                   :visible.sync="innerDrawer">
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">名称</el-tag>
            </label>
            <div class="layui-input-block">
              <el-input v-model='edit_interest.name'
                        placeholder="请输入内容"></el-input>
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">品牌</el-tag>
            </label>
            <div class="layui-input-block">

              <el-collapse>
                <el-collapse-item>
                  <template slot="title">
                    <span v-if="isband==false">选择品牌</span>
                    <span v-else>
                      <font v-for="item in edit_interest.brands"
                            :key="item">{{item}}、</font>
                    </span>
                  </template>
                  <el-checkbox-group v-model="edit_interest.brands">
                    <el-checkbox-button v-for="item in brandlist"
                                        :label="item.name"
                                        :key="item.label">{{item.name}}</el-checkbox-button>
                  </el-checkbox-group>
                </el-collapse-item>

              </el-collapse>

            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwiths">螺纹钢</el-tag>
            </label>
            <div class="layui-input-block">
              <el-checkbox-group v-model="edit_interest.LWG_sizes">
                <el-checkbox v-for='item in filter.options.LWG_sizes'
                             :key='item.id'
                             :label='item'
                             :value='item'></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">高线</el-tag>
            </label>
            <div class="layui-input-block">
              <el-checkbox-group v-model="edit_interest.GX_sizes">
                <el-checkbox v-for='item in filter.options.GX_sizes'
                             :key="item.id"
                             :label='item'
                             :value='item'></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">盘螺</el-tag>
            </label>
            <div class="layui-input-block">
              <el-checkbox-group v-model="edit_interest.PL_sizes">
                <el-checkbox v-for='item in filter.options.PL_sizes'
                             :key="item.id"
                             :label='item'
                             :value='item'></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">
              <el-tag class="butwith">地址</el-tag>
            </label>
            <div class="layui-input-block">
              <div class="block">
                <el-cascader v-model="addressValues"
                             :options="addressList"
                             @change="changeProvinceCity"
                             :props="addressListProps"></el-cascader>
              </div>
            </div>

          </div>
          <div class="layui-form-item">
            <div class="buttongt">
              <el-button type="primary"
                         @click="saveEditInterest">确定</el-button>
              <el-button type="info"
                         @click="clearCheckeds">重置</el-button>
            </div>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
    <el-dialog title="实时磅计价格截图"
               append-to-body
               width="80%"
               :visible.sync="dialogTableVisibles">
      <div class="fr">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">实时价格</div>
        <div class="titles">地址 : 广东省{{citys}}{{areas}}</div>
        <div class="titles"> <span v-if="is_newtimes==1">日期 : {{dates}} {{ gettime }} </span> <span v-if="is_newtimes==2">日期 : {{dates}} {{ times }} </span></div>

        <el-table :data="page_items"
                  border
                  :row-class-name="tableRowClassName"
                  style="width: 100%">
          <el-table-column prop="brand"
                           label="品牌">
            <template slot-scope="scope">
              <div><span class="active-column2">{{scope.row.brand}}</span> </div>
            </template>
          </el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              <div>{{scope.row.cate_spec}}{{scope.row.size}} </div>
            </template>
          </el-table-column>
          <el-table-column prop="warehouse"
                           label="仓库">
          </el-table-column>
          <el-table-column prop="price"
                           label="现货价">
          </el-table-column>
          <el-table-column prop="freight"
                           label="运费">
          </el-table-column>
          <el-table-column prop="resultPrice"
                           label="到货价">
            <template slot-scope="scope">
              <div><span class="active-column">{{scope.row.resultPrice}}</span> <span v-html="scope.row.market_float"></span></div>
            </template>
          </el-table-column>
          <el-table-column prop="web_price"
                           label="参考价"
                           width="150">
            <template slot-scope="scope">
              <div><span>{{scope.row.web_price}}</span> <span v-html="scope.row.web_float"
                      style="padding-right: 5px;"></span>
                <el-tag size="mini"
                        v-if="scope.row.web_price">{{area}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="diffPrice"
                           label="价差">
            <template slot-scope="scope">
              <div><span class="active-column">{{scope.row.diffPrice}}</span> <span v-html="scope.row.diff_price_float"></span></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="实时理计价格截图"
               append-to-body
               width="80%"
               :visible.sync="dialogTableVisibletheory">
      <div class="fr">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">实时价格</div>
        <div class="titles">地址 : 广东省{{citys}}{{areas}}</div>
        <div class="titles"> <span v-if="is_newtimes==1">日期 : {{dates}} {{ gettime }} </span> <span v-if="is_newtimes==2">日期 : {{dates}} {{ times }} </span></div>

        <el-table :data="theorydata"
                  border
                  :row-class-name="tableRowClassName"
                  style="width: 100%">
          <el-table-column prop="brand"
                           label="品牌">
            <template slot-scope="scope">
              <div><span class="active-column2">{{scope.row.brand}}</span> </div>
            </template>
          </el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              <div>{{scope.row.cate_spec}}{{scope.row.size}} </div>
            </template>
          </el-table-column>
          <el-table-column prop="warehouse"
                           label="仓库">
          </el-table-column>
          <el-table-column prop="price"
                           label="现货价">
          </el-table-column>
          <el-table-column prop="freight"
                           label="运费">
          </el-table-column>
          <el-table-column prop="resultPrice"
                           label="到货价">
            <template slot-scope="scope">
              <div><span class="active-column">{{scope.row.resultPrice}}</span> <span v-html="scope.row.market_float"></span></div>
            </template>
          </el-table-column>
          <el-table-column prop="web_price"
                           label="参考价"
                           width="150">
            <template slot-scope="scope">
              <div><span>{{scope.row.web_price}}</span> <span v-html="scope.row.web_float"
                      style="padding-right: 5px;"></span>
                <el-tag size="mini"
                        v-if="scope.row.web_price">{{area}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="diffPrice"
                           label="价差">
            <template slot-scope="scope">
              <div><span class="active-column">{{scope.row.diffPrice}}</span> <span v-html="scope.row.diff_price_float"></span></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStandardTree, getaddress, getMarketPricePIdAndBrands, saveInterest, userInterestList, deleteInterest, getMarketPriceAll, getWebArea, getUpdateNum, getTheoryPrice, updateSearchHits } from '@/api/global'
import globalBus from '@/utils/global-bus'
import domtoimage from 'dom-to-image'
export default {
  data () {
    return {
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e7
        }
      },
      is_newest: '1',
      is_newests: true,
      priceDate: '',
      priceTime: null,
      priceTimes: [],
      webpriceTime: null,
      webpriceTimes: [],
      brandlist: [],
      checkList: [],
      page_items: [],
      theorydata: [],
      filter: {
        selected: {
          brands: [],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: [],
          sortType: '0',
          address: {
            province: '广东省',
            city: '广州市',
            area: '天河区'
          }
        },
        options: {
          brands: [],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: []
        }
      },
      addressValue: ['广东省', '广州市', '天河区'],
      addressValues: ['广东省', '广州市', '天河区'],
      addressList: [],
      interestaddr: '',
      addressListProps: {
        value: 'name',
        label: 'name',
        children: 'children'
      },
      drawer: false,
      direction: 'rtl',
      userinterestlist: [],
      interest: null,
      edit_interest: {
        id: null,
        name: null,
        brands: [],
        LWG_sizes: [],
        GX_sizes: [],
        PL_sizes: [],
        address: {
          province: '广东省',
          city: '广州市',
          area: '天河区'
        }
      },
      area: '广州',
      citys: '广州市',
      areas: '天河区',
      currentTime: null,
      dates: '',
      dialogTableVisible: false,
      dialogTableVisibles: false,
      dialogTableVisibletheory: false,
      innerDrawer: false,
      isband: false,
      interestid: '',
      times: '',
      gettime: '',
      is_newtimes: 1,
      screeninfos: '2',
      timers: '',
      follow: {
        selected: {
          brands: [],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: [],
          sortType: '0',
          address: {
            province: '广东省',
            city: '广州市',
            area: '天河区'
          }
        },
        options: {
          brands: [],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: []
        }
      },
      isfollowband: false,
      loading: false,
      activeName: [],
      interestaddrs: [],
      actualstatus: 'poundmeter'
    }
  },
  created () {
    // 注册自定义事件
    globalBus.$on('screeninfos', (res) => {
      this.screeninfos = res.status
      this.actualstatus = res.actualstatus
    })
    // 组件初始化好，请求获取用户资料
    this.getWebArea()
    this.loadgetStandardTree()
    this.loadgetaddress()
    this.loadbrandlist()
    this.loadgetuserInterestList()
    this.createCurrentTime()
  },
  destroyed () {
    window.clearInterval(this.timers)
  },
  methods: {
    // 获取参考区域
    getWebArea () {
      getWebArea().then(res => {
        this.area = res.data.area
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择最新
    changeStatus: function (callback) {
      this.is_newest = callback
      if (callback === '1') {
        this.is_newtimes = 1
        this.priceTime = ''
        this.is_newests = true
        this.timers = window.setInterval(() => {
          setTimeout(() => {
            this.gets()
          }, 0)
        }, 60000)
      }
      if (callback === '0') {
        window.clearInterval(this.timers)
        //  发布通知
        globalBus.$emit('searchMarketPrice', { status: 2, data: callback })
        this.is_newtimes = 2
        this.is_newests = false
      }
    },
    // 选择日期
    changeDate: function (callback) {
      if (callback !== null) {
        this.priceDate = callback
        this.priceTime = null
        getMarketPricePIdAndBrands({ date: callback }).then(res => {
          if (res.data.status === 'success') {
            this.priceTimes = res.data.data.index
            this.webpriceTimes = res.data.data.index2
            this.brandlist = res.data.data.brand_group
          } else {
            console.log('获取数据失败')
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      } else {
        this.priceTime = null
        this.priceTimes = null
      }
    },
    changewebTime (callback) {
      this.webpriceTime = callback
    },
    // 选择次序
    changeTime: function (callback) {
      this.priceTime = callback
    },
    // 进入页面就获取品牌
    loadbrandlist () {
      getMarketPricePIdAndBrands().then(res => {
        if (res.data.status === 'success') {
          // this.priceTimes = res.data.data.index
          this.brandlist = res.data.data.brand_group
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取品牌
    loadbrandlistdate (date) {
      getMarketPricePIdAndBrands({ date: date }).then(res => {
        if (res.data.status === 'success') {
          // this.priceTimes = res.data.data.index
          this.brandlist = res.data.data.brand_group
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取规格
    loadgetStandardTree () {
      getStandardTree().then(res => {
        if (res.data.status === 'success') {
          for (const item of res.data.data) {
            if (item.cate_spec === '螺纹钢') {
              this.filter.options.LWG_sizes = item.size
              this.follow.options.LWG_sizes = item.size
            }
            if (item.cate_spec === '盘螺') {
              this.filter.options.PL_sizes = item.size
              this.follow.options.PL_sizes = item.size
            }
            if (item.cate_spec === '高线') {
              this.filter.options.GX_sizes = item.size
              this.follow.options.GX_sizes = item.size
            }
          }
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取地址
    loadgetaddress () {
      getaddress().then(res => {
        if (res.data.status === 'success') {
          this.addressList = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择地址
    changeProvinceCity (value) {
      this.filter.selected.address.province = value[0]
      this.filter.selected.address.city = value[1]
      this.filter.selected.address.area = value[2]
    },
    // 新增关注
    saveNewInterest () {
      if (!this.filter.selected.name) {
        this.$message.error('请输入项目名称')
      }
      saveInterest({
        name: this.filter.selected.name,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area,
        brand: this.filter.selected.brands,
        size: [
          { cate_spec: '螺纹钢', size: this.filter.selected.LWG_sizes },
          { cate_spec: '盘螺', size: this.filter.selected.PL_sizes },
          { cate_spec: '高线', size: this.filter.selected.GX_sizes }
        ]
      }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadgetuserInterestList()
          this.handleClose()
        } else {
          console.log('新建数据败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 关注列表
    loadgetuserInterestList () {
      userInterestList().then(res => {
        if (res.data.status === 'success') {
          this.userinterestlist = res.data.data
        } else {
          console.log('新建数据败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getInterest () {
      for (const item of this.userinterestlist) {
        if (item.id === this.interest) {
          return item
        }
      }
      return null
    },
    getSize (catespec, size) {
      for (const item of size) {
        if (item.cate_spec === catespec) {
          return item.size
        }
      }
      return []
    },
    changeInterest () {
      const seft = this
      var interest = this.getInterest()
      if (interest.brand.length > 0) {
        this.follow.selected.brands = interest.brand
        this.isfollowband = true
      }
      if (interest.size.length > 0) {
        interest.size.forEach(function (item, index) {
          if (item.cate_spec === '螺纹钢') {
            seft.follow.selected.LWG_sizes = item.size
          }
          if (item.cate_spec === '盘螺') {
            seft.follow.selected.PL_sizes = item.size
          }
          if (item.cate_spec === '高线') {
            seft.follow.selected.GX_sizes = item.size
          }
        })
      }
      this.gets()
      this.timers = window.setInterval(() => {
        setTimeout(() => {
          this.gets()
        }, 0)
      }, 60000)
      if (interest.brand) {
        for (const item of interest.brand) {
          interest.brand_text += item + ','
        }
      } if (interest) {
        this.interestaddr = '广东省/' + interest.city + '/' + interest.area
        this.interestaddrs = ['广东省', interest.city, interest.area]
        this.interest_select = interest
        this.interestid = this.interest_select.id
        return false
      }
      this.interest_select = null
      return false
    },
    searchInterestDataAll () {
      this.searchInterestData()
      this.searchInteresttheoryData()
    },
    // 磅计确定
    searchInterestData () {
      const interest = this.getInterest()
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
        }
      }
      if (!interest) {
        this.$message.error('请选择你的关注项目')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在获取数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      getMarketPriceAll({
        cate_spec: [
          { name: '螺纹钢', size: this.follow.selected.LWG_sizes },
          { name: '盘螺', size: this.follow.selected.PL_sizes },
          { name: '高线', size: this.follow.selected.GX_sizes }
        ],
        parent_id: this.priceTime,
        web_parent_id: this.webpriceTime,
        is_newest: this.is_newests,
        brands: this.follow.selected.brands,
        sort_value: this.follow.selected.sortType,
        city: this.interestaddrs[1],
        area: this.interestaddrs[2],
        interestid: this.interestid
      }).then(res => {
        if (res.data.status === 'success') {
          loading.close()
          // console.log(res.data.data)
          //  发布通知
          globalBus.$emit('searchMarketPrice', {
            status: 1,
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            is_newestt: this.is_newest,
            is_today: res.data.is_today,
            brands: this.filter.selected.brands,
            city: interest.city,
            area: interest.area,
            dates: this.priceDate,
            page: this.page,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
          this.updateSearchHits()
        } else {
          loading.close()
          console.log('获取数据失败')
        }
      }).catch(err => {
        loading.close()
        console.log('失获取数据败', err)
      })
    },
    // 磅计确定
    searchInterestDatas () {
      const interest = this.getInterest()
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
        }
      }
      if (!interest) {
        this.$message.error('请选择你的关注项目')
        return false
      }
      getMarketPriceAll({
        cate_spec: [
          { name: '螺纹钢', size: this.getSize('螺纹钢', interest.size) },
          { name: '盘螺', size: this.getSize('盘螺', interest.size) },
          { name: '高线', size: this.getSize('高线', interest.size) }
        ],
        parent_id: this.priceTime,
        is_newest: this.is_newests,
        brands: interest.brand,
        sort_value: this.filter.selected.sortType,
        city: interest.city,
        area: interest.area,
        interestid: this.interestid
      }).then(res => {
        if (res.data.status === 'success') {
          // console.log(res.data.data)
          //  发布通知
          globalBus.$emit('searchMarketPrice', {
            status: 1,
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            is_newestt: this.is_newest,
            is_today: res.data.is_today,
            brands: this.filter.selected.brands,
            city: interest.city,
            area: interest.area,
            dates: this.priceDate,
            page: this.page,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 理计确定
    searchInteresttheoryData () {
      const interest = this.getInterest()
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
        }
      }
      if (!interest) {
        this.$message.error('请选择你的关注项目')
        return false
      }
      getTheoryPrice({
        cate_spec: [
          { name: '螺纹钢', size: this.follow.selected.LWG_sizes },
          { name: '盘螺', size: this.follow.selected.PL_sizes },
          { name: '高线', size: this.follow.selected.GX_sizes }
        ],
        parent_id: this.priceTime,
        web_parent_id: this.webpriceTime,
        is_newest: this.is_newests,
        brands: this.follow.selected.brands,
        sort_value: this.follow.selected.sortType,
        city: this.interestaddrs[1],
        area: this.interestaddrs[2],
        interestid: this.interestid
      }).then(res => {
        if (res.data.status === 'success') {
          // console.log(res.data.data)
          //  发布通知
          globalBus.$emit('searchtheoryMarketPrice', {
            status: 1,
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            is_newestt: this.is_newest,
            is_today: res.data.is_today,
            brands: this.filter.selected.brands,
            city: interest.city,
            area: interest.area,
            dates: this.priceDate,
            page: this.page,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 理计确定
    searchInteresttheoryDatas () {
      const interest = this.getInterest()
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
        }
      }
      if (!interest) {
        this.$message.error('请选择你的关注项目')
        return false
      }
      getTheoryPrice({
        cate_spec: [
          { name: '螺纹钢', size: this.getSize('螺纹钢', interest.size) },
          { name: '盘螺', size: this.getSize('盘螺', interest.size) },
          { name: '高线', size: this.getSize('高线', interest.size) }
        ],
        parent_id: this.priceTime,
        is_newest: this.is_newests,
        brands: interest.brand,
        sort_value: this.filter.selected.sortType,
        city: interest.city,
        area: interest.area,
        interestid: this.interestid
      }).then(res => {
        if (res.data.status === 'success') {
          // console.log(res.data.data)
          //  发布通知
          globalBus.$emit('searchtheoryMarketPrice', {
            status: 1,
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            is_newestt: this.is_newest,
            is_today: res.data.is_today,
            brands: this.filter.selected.brands,
            city: interest.city,
            area: interest.area,
            dates: this.priceDate,
            page: this.page,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 查看关注列表
    openList () {
      this.dialogTableVisible = true
    },
    // 修改关注
    handleEdit (interest) {
      this.isband = true
      this.innerDrawer = true
      this.edit_interest.id = interest.id
      this.edit_interest.name = interest.name
      this.edit_interest.brands = interest.brand
      for (const item of interest.size) {
        if (item.cate_spec === '螺纹钢') {
          this.edit_interest.LWG_sizes = item.size
        }
        if (item.cate_spec === '盘螺') {
          this.edit_interest.PL_sizes = item.size
        }
        if (item.cate_spec === '高线') {
          this.edit_interest.GX_sizes = item.size
        }
      }
      this.edit_interest.address.city = interest.city
      this.edit_interest.address.area = interest.area
      this.addressValues = ['广东省', interest.city, interest.area]
    },
    // 提交修改关注
    saveEditInterest () {
      if (!this.edit_interest.name) {
        this.$message.error('请输入项目名称')
      }
      saveInterest({
        id: this.edit_interest.id,
        name: this.edit_interest.name,
        city: this.addressValues[1],
        area: this.addressValues[2],
        brand: this.edit_interest.brands,
        size: [
          { cate_spec: '螺纹钢', size: this.edit_interest.LWG_sizes },
          { cate_spec: '盘螺', size: this.edit_interest.PL_sizes },
          { cate_spec: '高线', size: this.edit_interest.GX_sizes }
        ]
      }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadgetuserInterestList()
          this.handleClose()
        } else {
          console.log('新建数据败')
        }
      }).catch(err => {
        console.log('失获取数据失败', err)
      })
    },
    clearCheckeds () {
      this.isband = false
      this.edit_interest.address.city = ''
      this.edit_interest.address.area = ''
      this.edit_interest.brands = []
      this.edit_interest.LWG_sizes = []
      this.edit_interest.PL_sizes = []
      this.edit_interest.GX_sizes = []
      this.addressValues = []
    },
    // 删除关注
    handleDelete (interest) {
      this.$confirm('确认删除吗,?', '确认删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteInterest({ id: interest.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetuserInterestList()
            this.handleClose()
          } else {
            console.log('删除关注失败')
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 选择品牌
    brandlists () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
      // console.log(this.filter.selected.brands)
    },
    followbrand () {
      this.isfollowband = true
      if (this.follow.selected.brands.length === 0) {
        this.isfollowband = false
      }
    },
    // 关闭抽屉
    handleClose (done) {
      done()
    },
    // 清空条件
    clearChecked () {
      this.filter.selected.name = ''
      this.filter.selected.brands = []
      this.filter.selected.LWG_sizes = []
      this.filter.selected.GX_sizes = []
      this.filter.selected.PL_sizes = []
      this.addressValue = []
    },
    // 提交搜索截图
    searchimgData () {
      const loading = this.$loading({
        lock: true,
        text: '正在获取数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      const interest = this.getInterest()
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
        }
      }
      if (!interest) {
        loading.close()
        this.$message.error('请选择你的关注项目')
        return false
      }
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      this.gettime = hh + ':' + mf + ':' + ss
      getMarketPriceAll({
        cate_spec: [
          { name: '螺纹钢', size: this.getSize('螺纹钢', interest.size) },
          { name: '盘螺', size: this.getSize('盘螺', interest.size) },
          { name: '高线', size: this.getSize('高线', interest.size) }
        ],
        parent_id: this.priceTime,
        is_newest: this.is_newests,
        brands: interest.brand,
        sort_value: this.filter.selected.sortType,
        city: interest.city,
        area: interest.area,
        interestid: this.interestid
      }).then(res => {
        if (res.data.status === 'success') {
          this.citys = interest.city
          this.areas = interest.area
          this.times = res.data.date_time.time
          if (res.data.is_today === 1) {
            this.is_newtimes = 1
            this.dates = res.data.date_time.date
          } else {
            this.is_newtimes = 2
            this.dates = res.data.price_time
          }
          this.page_items = res.data.data.map(val => {
            if (val.warehouse === '钢厂直发') {
              val.freight = '-'
              val.price = '-'
            }
            if (val.web_float > 0) {
              val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float < 0) {
              val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float === 0) {
              val.web_float = '<span class="yellow_font">-平</span>'
            }
            if (val.diff_price_float > 0) {
              val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float < 0) {
              val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float === 0) {
              val.diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.market_float > 0) {
              val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float < 0) {
              val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float === 0) {
              val.market_float = '<span class="yellow_font">-平</span>'
            }
            return val
          })
          //  发布通知
          globalBus.$emit('searchMarketPrice', {
            status: 1,
            cate_spec: [
              { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
              { name: '盘螺', size: this.filter.selected.PL_sizes },
              { name: '高线', size: this.filter.selected.GX_sizes }
            ],
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            brands: this.filter.selected.brands,
            city: interest.city,
            area: interest.area,
            dates: this.priceDate,
            page: this.page,
            is_today: res.data.is_today,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
          loading.close()
        } else {
          this.updateLoading = true
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.updateLoading = true
        console.log('失获取数据败', err)
      })
      getTheoryPrice({
        cate_spec: [
          { name: '螺纹钢', size: this.getSize('螺纹钢', interest.size) },
          { name: '盘螺', size: this.getSize('盘螺', interest.size) },
          { name: '高线', size: this.getSize('高线', interest.size) }
        ],
        parent_id: this.priceTime,
        is_newest: this.is_newests,
        brands: interest.brand,
        sort_value: this.filter.selected.sortType,
        city: interest.city,
        area: interest.area,
        interestid: this.interestid
      }).then(res => {
        if (res.data.status === 'success') {
          this.citys = interest.city
          this.areas = interest.area
          this.times = res.data.date_time.time
          if (res.data.is_today === 1) {
            this.is_newtimes = 1
            this.dates = res.data.date_time.date
          } else {
            this.is_newtimes = 2
            this.dates = res.data.price_time
          }
          this.page_items = res.data.data.map(val => {
            if (val.warehouse === '钢厂直发') {
              val.freight = '-'
              val.price = '-'
            }
            if (val.web_float > 0) {
              val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float < 0) {
              val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float === 0) {
              val.web_float = '<span class="yellow_font">-平</span>'
            }
            if (val.diff_price_float > 0) {
              val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float < 0) {
              val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float === 0) {
              val.diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.market_float > 0) {
              val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float < 0) {
              val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float === 0) {
              val.market_float = '<span class="yellow_font">-平</span>'
            }
            return val
          })
          //  发布通知
          globalBus.$emit('searchtheoryMarketPrice', {
            status: 1,
            cate_spec: [
              { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
              { name: '盘螺', size: this.filter.selected.PL_sizes },
              { name: '高线', size: this.filter.selected.GX_sizes }
            ],
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            brands: this.filter.selected.brands,
            city: interest.city,
            area: interest.area,
            dates: this.priceDate,
            page: this.page,
            is_today: res.data.is_today,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
      if (this.actualstatus === 'poundmeter') {
        this.dialogTableVisibles = true
        this.dialogTableVisibletheory = false
      } else {
        this.dialogTableVisibles = false
        this.dialogTableVisibletheory = true
      }
    },
    selectedfocus () {
      if (this.is_newest === '0') {
        this.loadbrandlistdate(this.priceDate)
      } else {
        this.loadbrandlist()
      }
    },
    // 展开品牌
    changebrand () {
      if (this.is_newest === '0') {
        this.loadbrandlistdate(this.priceDate)
      } else {
        this.loadbrandlist()
      }
      if (this.activeName === undefined || this.activeName.length <= 0) {
        this.isretract = false
      } else {
        this.isretract = true
      }
    },
    // 收起品牌
    retract () {
      this.isretract = false
      this.activeName = []
    },
    // 获取截图
    getImg () {
      const loading = this.$loading({
        lock: true,
        text: '正在截图中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      var node = this.$refs.mynode
      var seft = this
      domtoimage.toPng(node).then(function (dataUrl) {
        var img = new Image()
        img.src = dataUrl
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = seft.dates + 'new-steel-data.png' || '下载图片名称'
        a.href = dataUrl
        a.dispatchEvent(event)
        loading.close()
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    },
    // 当前时间
    createCurrentTime () {
      const date = new Date()
      const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      this.currentTime = hours + ':' + minutes + ':' + seconds
      setTimeout(this.createCurrentTime, 1000)
    },
    // 轮询
    gets () {
      if (this.screeninfos === '2') {
        getUpdateNum().then(res => {
          if (window.localStorage.getItem('UpdateNum') !== res.data.data) {
            if (this.is_newest === 1) {
              this.searchInterestDatas()
              this.searchInteresttheoryDatas()
            }
          }
          window.localStorage.setItem('UpdateNum', res.data.data)
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      }
    },
    selectedbands () {
      this.isfollowband = true
      if (this.follow.selected.brands.length === 0) {
        this.isfollowband = false
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    updateSearchHits () {
      const data = {
        cate_spec: [
          { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
          { name: '盘螺', size: this.filter.selected.PL_sizes },
          { name: '高线', size: this.filter.selected.GX_sizes }
        ],
        parent_id: this.priceTime,
        is_newest: this.is_newests,
        brands: this.filter.selected.brands,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area,
        dates: this.priceDate
      }
      updateSearchHits({ actualstatus: this.actualstatus, data: data, interestid: this.interest }).then(res => {

      }).catch(err => {
        console.log('失获取数据败', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 195px;
  padding-bottom: 10px;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
@media screen and (max-width: 1366px) {
  /deep/.el-button + .el-button {
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .layui-form-item .layui-input-inline {
    float: left;
    width: 146px;
    margin-left: -10px;
  }
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
.layui-input-block {
  margin-left: 80px;
  min-height: 25px;
  line-height: 25px;
}
/deep/ .el-checkbox-group {
  padding-top: 10px;
}

/deep/.el-tag.el-tag--success {
  margin-left: 15px;
}

.v-modal {
  display: none;
}
.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
/deep/.el-date-editor.el-input,
/deep/.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwiths {
  padding: 0 18px;
}
/deep/.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
/deep/.el-drawer__body {
  padding: 30px;
}
/deep/.el-switch__label {
  height: 50px;
  line-height: 50px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border: none;
}
/deep/.el-checkbox-button__inner {
  border: none;
  padding: 8px 8px;
  font-size: 12px;
}
/deep/.el-checkbox-button,
.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
  margin: 5px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-drawer.rtl {
  overflow: scroll;
}
.mylist {
  margin: 10px 20px;
}
.bands {
  margin-right: 15px;
  margin-top: 8px;
  padding: 0 15px;
}
/deep/.el-radio {
  margin-right: 20px;
  margin-top: 15px;
}
/deep/.el-radio__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-radio {
  margin-right: 20px;
}

/deep/.el-radio__inner::after {
  left: 8px;
  top: 8px;
  width: 8px;
  height: 8px;
}
/deep/ .el-collapse-item__header {
  line-height: 20px;
}
/deep/ .el-collapse {
  border-top: 0px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/.el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}
.active-column {
  color: #e6781e;
  font-weight: 600;
}
.active-column2 {
  font-weight: 700;
}
.fr-title {
  font-size: 14px;
  font-weight: 500;
}
.more {
  text-align: center;
  padding: 10px;
  font-size: 14px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

/deep/ .el-table .sort-caret.ascending {
  border-bottom-color: rgb(245, 108, 108);
  top: 5px;
}
/deep/ .el-table .sort-caret.descending {
  border-top-color: rgb(103, 194, 58);
  bottom: 7px;
}
/deep/ .el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fr {
  float: right;
}
.wen {
  position: absolute;
  top: 20px;
  left: 120px;
  font-size: 14px;
}
</style>
