import request from '@/utils/request'

//   获取初始化设置
export const getSetting = data => {
  return request({
    method: 'POST',
    url: '/api/getSetting',
    data
  })
}
// 获取导航内容
export const getNavTree = data => {
  return request({
    method: 'POST',
    url: '/api/getNavTree',
    data
  })
}
//   获取参考价区域
export const getWebArea = data => {
  return request({
    method: 'POST',
    url: '/api/getWebArea',
    data
  })
}
//  获取规格
export const getStandardTree = data => {
  return request({
    method: 'GET',
    url: '/api/getStandardTree',
    data
  })
}
//  获取地址
export const getaddress = data => {
  return request({
    method: 'GET',
    url: '/api/getAddress',
    data
  })
}
//  根据日期 获取次序及品牌
export const getMarketPricePIdAndBrands = data => {
  return request({
    method: 'POST',
    url: '/api/getMarketPricePIdAndBrands',
    data
  })
}
//  实时价格
export const getMarketPrice = data => {
  return request({
    method: 'POST',
    url: '/api/getMarketPrice',
    data
  })
}
//  实时价格首页前5条
export const getMarketPriceFive = data => {
  return request({
    method: 'POST',
    url: '/api/getMarketPriceFive',
    data
  })
}
//  实时价格截图
export const getMarketPriceAll = data => {
  return request({
    method: 'POST',
    url: '/api/getMarketPriceAll',
    data
  })
}
//  搜索实时磅计价格
export const getMarketPrices = data => {
  return request({
    method: 'POST',
    url: '/api/getMarketPrice',
    data
  })
}
//  搜索实时理计价格
export const getTheoryPrice = data => {
  return request({
    method: 'POST',
    url: '/api/getTheoryPrice',
    data
  })
}
//  获取参考价区域
export const getNowWebPriceArea = data => {
  return request({
    method: 'POST',
    url: '/api/getNowWebPriceArea',
    data
  })
}
//  选择参考价区域
export const setWebPriceArea = data => {
  return request({
    method: 'POST',
    url: '/api/setWebPriceArea',
    data
  })
}
// 新建我的关注
export const saveInterest = data => {
  return request({
    method: 'POST',
    url: '/api/saveInterest',
    data
  })
}
// 关注列表
export const userInterestList = data => {
  return request({
    method: 'POST',
    url: '/api/userInterestList',
    data
  })
}
// 删除关注
export const deleteInterest = data => {
  return request({
    method: 'POST',
    url: '/api/deleteInterest',
    data
  })
}
// 获取品牌资料
export const getBrandInfo = data => {
  return request({
    method: 'POST',
    url: '/api/getBrandInfo',
    data
  })
}
// 下载品牌资料
export const getBrandInfoToWord = data => {
  return request({
    method: 'POST',
    url: '/api/getBrandInfoToWord',
    data
  })
}
// 期现基差
export const getCurrentBasis = data => {
  return request({
    method: 'POST',
    url: '/api/getCurrentBasis',
    data
  })
}
// 期现基差首页前一个月
export const getCurrentBasisFive = data => {
  return request({
    method: 'POST',
    url: '/api/getCurrentBasisFive',
    data
  })
}
// 每日出入库
export const getSteelflowBasis = data => {
  return request({
    method: 'POST',
    url: '/api/getSteelflowBasis',
    data
  })
}
// 每周库存统计表
export const getSteelstoreBasis = data => {
  return request({
    method: 'POST',
    url: '/api/getSteelstoreBasis',
    data
  })
}
// 每周库存当前日期
export const getSteelflowWeekDate = data => {
  return request({
    method: 'POST',
    url: '/api/getSteelflowWeekDate',
    data
  })
}
// 每周库存数据
export const getSteelstore = data => {
  return request({
    method: 'POST',
    url: '/api/getSteelstore',
    data
  })
}
// 资源总览
export const getUsuallySizePriceStatistics = data => {
  return request({
    method: 'POST',
    url: '/api/getUsuallySizePriceStatistics',
    data
  })
}
// 资源总览只显示前5条
export const getUsuallySizePriceStatisticsFive = data => {
  return request({
    method: 'POST',
    url: '/api/getUsuallySizePriceStatisticsFive',
    data
  })
}
// 现货指数
export const getUsuallySizePriceNewest = data => {
  return request({
    method: 'POST',
    url: '/api/getUsuallySizePriceNewest',
    data
  })
}
// 现货指数只显示前5条
export const getUsuallySizePriceNewestFive = data => {
  return request({
    method: 'POST',
    url: '/api/getUsuallySizePriceNewestFive',
    data
  })
}
// 历史数据
export const getHistorySizePrice = data => {
  return request({
    method: 'POST',
    url: '/api/getHistorySizePrice',
    data
  })
}
// 首页广告
export const getAd = data => {
  return request({
    method: 'POST',
    url: '/api/getAd',
    data
  })
}
// 轮咨
export const getUpdateNum = data => {
  return request({
    method: 'POST',
    url: '/api/getUpdateNum',
    data
  })
}
// 工程价格
export const getEngineerPrice = data => {
  return request({
    method: 'POST',
    url: '/api/getEngineerPrice',
    data
  })
}
// 获取用户过期
export const getUserNotice = data => {
  return request({
    method: 'POST',
    url: '/api/getUserNotice',
    data
  })
}
// 工程价格(历史数据)
export const getHistorySizeEngineerPrice = data => {
  return request({
    method: 'POST',
    url: '/api/getHistorySizeEngineerPrice',
    data
  })
}
export const updateHits = data => {
  return request({
    method: 'POST',
    url: '/api/updateHits',
    data
  })
}
export const updateSearchHits = data => {
  return request({
    method: 'POST',
    url: '/api/updateSearchHits',
    data
  })
}
