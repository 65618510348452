<template>
  <div>
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf ">
          <div class="widget-head fl">
            <div class="widget-title fl">每日出入库 <span style="font-size: 12px;
color: #909399;font-weight: normal;">（声明：自3月12日开始，因仓库数据问题，暂停统计广州天又天数据，对数据影响量约为12万吨，其中螺纹钢6.4万，盘螺4.4万，线材1.4万吨，请知悉。）</span></div>
            <div class="fr">
              <div class="fl pr20">单位:万吨</div>
            </div>
          </div>
          <div class="widget-body am-cf">
            <el-tabs type="card"
                     v-model="activeNames"
                     @tab-click="handleClick">
              <el-tab-pane label="每日出入库数据表"
                           name="first"></el-tab-pane>
              <el-tab-pane label="每日出入库走势图"
                           name="second"></el-tab-pane>
            </el-tabs>
            <div class="currcont"
                 :style="currcontdisplay">
              <el-table :data="steelflowbasisdata"
                        v-loading="loading"
                        border
                        :row-class-name="tableRowClassName"
                        height="calc(100vh - 245px)"
                        highlight-current-row
                        :cell-style="columnStyle"
                        style="width: 100%">
                <el-table-column prop="date"
                                 sortable
                                 label="日期"></el-table-column>
                <el-table-column label="螺纹"
                                 header-align="center"
                                 align="center">
                  <el-table-column prop="threadwarehousing"
                                   sortable
                                   label="入库"></el-table-column>
                  <el-table-column prop="threadwarehouseout"
                                   sortable
                                   label="出库"></el-table-column>
                  <el-table-column prop="threadstock"
                                   sortable
                                   label="库存"></el-table-column>
                </el-table-column>
                <el-table-column label="盘螺"
                                 header-align="center"
                                 align="center">
                  <el-table-column prop="snailwarehousing"
                                   sortable
                                   label="入库"></el-table-column>
                  <el-table-column prop="snailwarehouseout"
                                   sortable
                                   label="出库"></el-table-column>
                  <el-table-column prop="snailstock"
                                   sortable
                                   label="库存"></el-table-column>
                </el-table-column>
                <el-table-column label="高线"
                                 header-align="center"
                                 align="center">
                  <el-table-column prop="highlinewarehousing"
                                   sortable
                                   label="入库"></el-table-column>
                  <el-table-column prop="highlinwarehouseout"
                                   sortable
                                   label="出库"></el-table-column>
                  <el-table-column prop="highlinstock"
                                   sortable
                                   label="库存"></el-table-column>
                </el-table-column>
                <el-table-column label="合计"
                                 header-align="center"
                                 align="center">
                  <el-table-column prop="entersubtotal"
                                   sortable
                                   label="入库"></el-table-column>
                  <el-table-column prop="outsubtotal"
                                   sortable
                                   label="出库"></el-table-column>
                  <el-table-column prop="stocktotal"
                                   sortable
                                   label="库存"></el-table-column>
                </el-table-column>
                <el-table-column prop="wholeturnover"
                                 sortable
                                 label="全国成交量"></el-table-column>
              </el-table>
            </div>
            <div class="currChart"
                 :style="Chartdisplay">
              <div ref="myChart"
                   class="myChart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i></div>
  </div>
</template>

<script>
import { getUserNotice } from '@/api/global'
import Steelflowc from '@/components/Steelflowc'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      activeName: '1',
      activeNames: 'first',
      curTabcompnents: {
        1: 'Steelflowc'
      },
      layuithis: 'layui-this',
      layuithiss: '',
      currcontdisplay: 'display:block',
      Chartdisplay: 'display:none',
      loading: true,
      steelflowbasisdata: [],
      dateBetween: [],
      steelflowdata: [{
        data: []
      }, {
        data: []
      }, {
        data: []
      }],
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      max: '',
      topnotice: '',
      isstatus: 0
    }
  },
  components: { Steelflowc },
  created () {
    // 注册自定义事件
    globalBus.$on('SteelflowBasisData', (res) => {
      this.loading = false
      this.steelflowbasisdata = res.data
      this.dateBetween = res.date
      this.max = Math.ceil(res.max)
      this.getChartentersubtotal(res.data)
      this.getChartoutsubtotal(res.data)
      this.getChartstocktotal(res.data)
      this.drawLine()
    })
  },
  mounted () {
    this.loadgetUserNotice()
    // 初始化数据
    setTimeout(() => {
      this.drawLine()
    }, 1000)
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top:92px'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    },
    columnStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        return 'background:rgba(225, 243, 216,0.3);'
      }
      if (columnIndex === 4 || columnIndex === 5 || columnIndex === 6) {
        return 'background:rgba(250, 236, 216,0.3);'
      }
      if (columnIndex === 7 || columnIndex === 8 || columnIndex === 9) {
        return 'background:rgba(253, 226, 226,0.3);'
      }
      if (columnIndex === 10 || columnIndex === 11 || columnIndex === 12) {
        return 'background:rgba(217, 236, 255,0.3);'
      }
    },
    drawLine () {
      var echarts = require('echarts')
      var myChart = echarts.init(this.$refs.myChart)
      const color = ['#8B5CFF', '#00CA69', '#027ad7']
      const xAxisData = this.dateBetween
      const option = {
        backgroundColor: '#fff',
        color: color,
        legend: {
          top: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let html = ''
            params.forEach(v => {
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                ${v.seriesName} ${v.name}  
                <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px;margin-left:5px">${v.value}</span>
                万吨`
            })
            return html
          },
          extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;'
          // axisPointer: {
          //  type: 'shadow',
          //   shadowStyle: {
          //     color: '#ffffff',
          //    shadowColor: 'rgba(225,225,225,1)',
          //    shadowBlur: 5
          //  }
          //  }
        },
        // 工具栏，内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        toolbox: {
          show: true,
          // toolbox的配置项
          feature: {
            // 辅助线的开关
            mark: { show: true },
            // 数据视图
            dataView: {
              show: false,
              readOnly: false
            },
            // 动态类型切换
            magicType: {
              show: true,
              // line为折线图，bar为柱状图
              type: ['line', 'bar']
            },
            // 配置项还原
            restore: { show: false },
            // 将图标保存为图片
            saveAsImage: { show: true }
          }
        },
        // 可计算特性
        calculable: true,
        grid: {
          left: '3%',
          right: '2%',
          top: 100,
          bottom: '8%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: '#2a2a2a'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#2a2a2a'
            }
          },
          data: xAxisData
        }],
        yAxis: [{
          type: 'value',
          name: '入库/出库合计（万吨）',
          max: this.max + 1,
          splitNumber: this.max + 1,
          boundaryGap: [0, 0.3],
          axisLabel: {
            textStyle: {
              color: '#2a2a2a'
            }
          },
          nameTextStyle: {
            color: '#2a2a2a',
            fontSize: 12,
            lineHeight: 40
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        {
          type: 'value',
          name: '库存合计（万吨）',
          axisLabel: {
            textStyle: {
              color: '#2a2a2a'
            }
          },
          nameTextStyle: {
            color: '#2a2a2a',
            fontSize: 12,
            lineHeight: 40
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '入库合计',
          type: 'line',
          smooth: true,
          symbol: 'none',
          symbolSize: 8,
          zlevel: 3,
          yAxisIndex: 0,
          data: this.steelflowdata[0].data
        }, {
          name: '出库合计',
          type: 'line',
          smooth: true,
          symbol: 'none',
          symbolSize: 8,
          zlevel: 3,
          yAxisIndex: 0,
          data: this.steelflowdata[1].data
        }, {
          name: '库存合计',
          type: 'line',
          smooth: true,
          symbol: 'none',
          symbolSize: 8,
          zlevel: 3,
          yAxisIndex: 1,
          data: this.steelflowdata[2].data
        }]
      }
      myChart.setOption(option)
      // 监听点击事件
      window.addEventListener('click', function () {
        myChart.resize()
      })
      // 监听浏览器页面缩放事件
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 获取统计图入库小计数据
    getChartentersubtotal (marketData) {
      const marketSend = this.steelflowdata[0].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].entersubtotal.length > 0) {
            // marketSend[i].push(parseInt(marketData[j].entersubtotal))
            marketSend[i].push(marketData[j].entersubtotal)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        // marketSend[i] = parseInt(sum / (marketSend[i].length))
        marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    // 获取统计图出库小计数据
    getChartoutsubtotal (marketData) {
      const marketSend = this.steelflowdata[1].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].outsubtotal.length > 0) {
            // marketSend[i].push(parseInt(marketData[j].entersubtotal))
            marketSend[i].push(marketData[j].outsubtotal)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        // marketSend[i] = parseInt(sum / (marketSend[i].length))
        marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    // 获取统计图库存合计数据
    getChartstocktotal (marketData) {
      const marketSend = this.steelflowdata[2].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].stocktotal.length > 0) {
            // marketSend[i].push(parseInt(marketData[j].entersubtotal))
            marketSend[i].push(marketData[j].stocktotal)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        // marketSend[i] = parseInt(sum / (marketSend[i].length))
        marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    handleClick (tab, event) {
      if (tab.label === '每日出入库数据表') {
        this.currcontdisplay = 'display:block'
        this.Chartdisplay = 'display:none'
      }
      if (tab.label === '每日出入库走势图') {
        this.currcontdisplay = 'display:none'
        this.Chartdisplay = 'display:block'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
}
.layui-side {
  margin-right: 20px;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}
.layui-side-content {
  padding: 20px 0px;
}

/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
#selectBox {
  position: relative;
  width: 450px;
  height: auto;
  padding: 10px;
  background-color: #409eff;
  color: #fff;
}
#selectBox p {
  margin-top: 20px;
}
#selectBox .el-checkbox {
  color: #fff;
}
.fixWidth {
  display: inline-block;
  width: 90px;
  margin-left: 50px;
}
.fixBox {
  font-size: 14px;
  position: absolute;
  right: 40px;
  top: 200px;
  z-index: 2;
}
#offBox {
  position: absolute;
  right: -40px;
  top: 0;
  display: inline-block;
  width: 20px;
  height: auto;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: #409eff;
}
#onBox {
  position: absolute;
  left: 0;
  top: 25px;
  display: inline-block;
  width: 20px;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  height: auto;
  color: #fff;
  background-color: #409eff;
}
.inline_box {
  display: inline-block;
  width: 40%;
  margin-left: 20px;
}
.same_color {
  color: #f56c6c;
}
table {
  margin-top: 20px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e0e6ed;
  text-align: center;
  font-size: 14px;
}
thead {
  background-color: #eef1f6;
}
thead th {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
table td {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e0e6ed;
  border-right: 1px solid #e0e6ed;
}
table tbody tr:hover {
  background-color: #eff2f7;
}
.sort-icon {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  font-size: 12px;
  color: #97a8be;
}
.sort-icon > div {
  vertical-align: middle;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.addr_box {
  padding: 10px 15px;
  background-color: #fff;
}
.price_box {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #fff;
}
.button_box {
  text-align: center;
}
.button_box button {
  width: 80px;
  text-align: center;
  padding: 8px;
  margin: 5px;
}
.red_font {
  color: #aa1010;
}
.green_font {
  color: #10aa10;
}
.yellow_font {
  color: #aaaa10;
}
.sm_width {
  width: 80px;
}
.top_style {
  font-weight: bold;
  width: 99.9%;
  border: 1px solid #dfe6ec;
  display: inline-block;
  background: #eef1f6;
  margin-bottom: 0px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #656f82;
  opacity: 0.8;
  border-left: none;
}

.overflow_box {
  min-width: 1000px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.overflow_box table {
  min-width: 100%;
  width: auto;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.adds_css {
  width: 60%;
  display: inline-block;
}
.empty_css {
  display: inline-block;
}
.table_box {
  margin-top: 20px;
  clear: both;
}
.same_table {
  background: #fff;
  padding: 15px;
  width: 100%;
  margin-top: 20px;
}
.table_box tr {
  width: 100%;
}
.table_box th,
td {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
.table_box th {
  width: 15%;
}
.table_box tr:hover {
  background-color: #e0e6ed;
}
.img_style {
  display: inline-block;
  width: 100px;
  height: auto;
  cursor: pointer;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table thead.is-group th {
  background: #707880;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}
.myChart {
  height: calc(100vh - 250px);
  width: 100%;
  margin-top: 20px;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}
@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 9999;
  }
  .myChart {
    height: calc(100vh - 265px);
    width: 100%;
    margin-top: 20px;
  }
}
.charttitle {
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
}
.layui-tab {
  margin-top: 80px;
}
.layui-tab-title li {
  font-size: 14px;
}
.layui-tab-brief > .layui-tab-title .layui-this {
  color: #1e9fff;
  font-family: '微软雅黑';
  font-size: 14px;
}
.currcont {
  margin-top: 0px;
}
.currChart {
  margin-top: 0px;
}
/deep/ .el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
</style>
