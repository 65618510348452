<template>
  <div class="layui-tab-content">
    <div class="layui-form">
      <div class="layui-form-item"
           id="brand-box">
        <label class="layui-form-label">
          <el-tag class="butwith">日期</el-tag>
        </label>
        <div style="display:none">
          <el-radio-group v-model="is_newest"
                          @change="changeStatus">
            <el-radio label="0">过往日期</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="layui-form-item"
           v-if="is_newest==0">
        <div class="layui-input-inline">
          <el-date-picker v-model="priceDate"
                          type="date"
                          placeholder="选择日期"
                          value-format="yyyy-MM-dd"
                          @change="changeDate"
                          :picker-options="pickerOptions0"> </el-date-picker>
        </div>
        <div class="layui-input-inline"
             style="margin-left: 10px;">
          <el-select v-model="priceTime"
                     @change="changeTime"
                     placeholder="请选择现货价次序">
            <el-option v-for='item in priceTimes'
                       :label='item.time'
                       :key='item.label'
                       :value='item.id'>
            </el-option>
          </el-select>
        </div>
        <div class="layui-input-inline"
             style="">
          <el-select v-model="webpriceTime"
                     @change="changewebTime"
                     placeholder="请选择参考价次序">
            <el-option v-for='item in webpriceTimes'
                       :label='item.date'
                       :key='item.id'
                       :value='item.id'>
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">品牌</el-tag>
        </label>
        <div class="layui-input-block">
          <el-select v-model="filter.selected.brands"
                     multiple
                     filterable
                     remote
                     placeholder="请输入品牌"
                     :loading="loading"
                     @change="selectedbands">
            <el-option v-for="item in brandlist"
                       :key="item.label"
                       :label="item.zname"
                       :disabled="item.status === 0"
                       :value="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="layui-form-item">
        <el-collapse v-model="activeName"
                     @change="changebrand">
          <el-collapse-item>
            <template slot="title">
              <span v-if="isband==false">选择品牌</span>
              <span v-else>
                <font v-for="item in filter.selected.brands"
                      :key="item">{{item}}、</font>
              </span>
            </template>
            <el-checkbox-group v-model="filter.selected.brands"
                               @change="brandlists">
              <el-checkbox-button v-for="item in brandlist"
                                  :label="item.name"
                                  :disabled="item.status === 0"
                                  :key="item.label">{{item.zname}}</el-checkbox-button>
            </el-checkbox-group>
          </el-collapse-item>

        </el-collapse>

      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwiths">螺纹钢</el-tag>
        </label>
        <div class="layui-input-block">
          <el-checkbox-group v-model="filter.selected.LWG_sizes">
            <el-checkbox v-for='item in filter.options.LWG_sizes'
                         :key='item.label'
                         :label='item'
                         :value='item'></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">高线</el-tag>
        </label>
        <div class="layui-input-block">
          <el-checkbox-group v-model="filter.selected.GX_sizes">
            <el-checkbox v-for='item in filter.options.GX_sizes'
                         :key="item.label"
                         :label='item'
                         :value='item'></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">盘螺</el-tag>
        </label>
        <div class="layui-input-block">
          <el-checkbox-group v-model="filter.selected.PL_sizes">
            <el-checkbox v-for='item in filter.options.PL_sizes'
                         :key='item.label'
                         :label='item'
                         :value='item'></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag>排序方式</el-tag>
        </label>
        <div class="layui-input-block">
          <el-radio-group v-model='filter.selected.sortType'>
            <el-radio label="0">到货价升序</el-radio>
            <el-radio label="1">价差降序</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">地址</el-tag>
        </label>
        <div class="layui-input-block">
          <div class="block">
            <el-cascader v-model="addressValue"
                         :options="addressList"
                         @change="changeProvinceCity"
                         :props="addressListProps"></el-cascader>
          </div>
        </div>

      </div>
      <div class="layui-form-item">
        <div class="buttongt">
          <el-button type="primary"
                     @click="searchData"
                     :loading="updateLoading">确定</el-button>
          <el-button type="warning"
                     @click="searchimgData">获取报价截图</el-button>
          <el-button type="info"
                     @click="clearChecked">重置</el-button>
        </div>
      </div>
    </div>
    <div class="retract"
         v-if="isretract==true">
      <el-button type="primary"
                 @click="retract">收起品牌</el-button>
    </div>
    <el-dialog title="实时价格截图"
               append-to-body
               width="80%"
               :visible.sync="dialogTableVisible">
      <div class="fr">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">实时价格</div>
        <div class="titles">地址 : 广东省{{citys}}{{areas}}</div>
        <div class="titles">
          <span v-if="is_newtimes==1">日期 : {{dates}} {{ gettime }} </span> <span v-if="is_newtimes==2">日期 : {{dates}} {{ times }} </span>
        </div>

        <el-table :data="page_items"
                  border
                  :row-class-name="tableRowClassName"
                  style="width: 100%">
          <el-table-column prop="brand"
                           label="品牌">
            <template slot-scope="scope">
              <div><span class="active-column2">{{scope.row.brand}}</span> </div>
            </template>
          </el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              <div>{{scope.row.cate_spec}}{{scope.row.size}} </div>
            </template>
          </el-table-column>
          <el-table-column prop="warehouse"
                           label="仓库">
          </el-table-column>
          <el-table-column prop="price"
                           label="现货价">
          </el-table-column>
          <el-table-column prop="freight"
                           label="运费">
          </el-table-column>
          <el-table-column prop="resultPrice"
                           label="到货价">
            <template slot-scope="scope">
              <div><span class="active-column">{{scope.row.resultPrice}}</span> <span v-html="scope.row.market_float"></span></div>
            </template>
          </el-table-column>
          <el-table-column prop="web_price"
                           label="参考价"
                           width="150">
            <template slot-scope="scope">
              <div><span>{{scope.row.web_price}}</span> <span v-html="scope.row.web_float"
                      style="padding-right: 5px;"></span>
                <el-tag size="mini"
                        v-if="scope.row.web_price">{{area}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="diffPrice"
                           label="价差">
            <template slot-scope="scope">
              <div><span class="active-column">{{scope.row.diffPrice}}</span> <span v-html="scope.row.diff_price_float"></span></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import steps from '@/utils/guiderealtimeprice'
import { getStandardTree, getaddress, getMarketPricePIdAndBrands, getWebArea, getEngineerPrice, getUpdateNum } from '@/api/global'
import globalBus from '@/utils/global-bus'
import domtoimage from 'dom-to-image'
export default {
  data () {
    return {
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e7
        }
      },
      activeName: [],
      is_newest: '0',
      is_newests: false,
      priceDate: '',
      priceTime: null,
      webpriceTime: null,
      priceTimes: [],
      webpriceTimes: [],
      brandlist: [],
      checkList: [],
      page: '1',
      per_page: '20',
      filter: {
        selected: {
          brands: [],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: [],
          sortType: '0',
          address: {
            province: '广东省',
            city: '广州市',
            area: '天河区'
          }
        },
        options: {
          brands: [],
          LWG_sizes: [],
          GX_sizes: [],
          PL_sizes: []
        }
      },
      addressValue: ['广东省', '广州市', '天河区'],
      addressList: [],
      addressListProps: {
        value: 'name',
        label: 'name',
        children: 'children'
      },
      drawer: false,
      direction: 'rtl',
      modals: false,
      isband: false,
      isretract: false,
      page_items: [],
      dates: '',
      area: '广州',
      citys: '广州市',
      areas: '天河区',
      currentTime: null,
      updateLoading: false,
      dialogTableVisible: false,
      driver: null,
      times: '',
      is_newtimes: 1,
      timer: '',
      gettime: '',
      loading: false,
      screeninfo: '1'
    }
  },
  created () {
    // 注册自定义事件
    globalBus.$on('screeninfo', (res) => {
      this.screeninfo = res.status
    })
    // 组件初始化好，请求获取用户资料
    this.loadgetMarketPricePIdAndBrands()
    this.loadgetStandardTree()
    this.loadgetaddress()
    this.loadbrandlist()
    this.getWebArea()
    this.createCurrentTime()
  },
  mounted () {
    this.driver = new Driver({
      className: 'scoped-class',
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: false,
      // stageBackground: '#fff', // 引导对话的背景色
      overlayClickNext: false,
      doneBtnText: '完成',
      closeBtnText: '跳过',
      nextBtnText: '下一步',
      prevBtnText: '上一步',
      onReset: () => {
        window.localStorage.setItem('guiderealtimeprice', 'BVewsJUqylM7BbbX3rkLTcRRuiXNH718')
      }
    })
    if (window.localStorage.getItem('guiderealtimeprice') !== 'BVewsJUqylM7BbbX3rkLTcRRuiXNH718') {
      this.guide()
    }
    this.get()
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.get()
      }, 0)
    }, 60000)
  },
  destroyed () {
    window.clearInterval(this.timer)
  },
  methods: {
    // 获取参考区域
    getWebArea () {
      getWebArea().then(res => {
        this.area = res.data.area
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 加载最新时间
    loadgetMarketPricePIdAndBrands () {
      getMarketPricePIdAndBrands({ need_parent: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.brandlist = res.data.data.brand_group
          this.priceDate = res.data.data.price_date
          this.priceTimes = res.data.data.index
          this.webpriceTimes = res.data.data.index2
          if (res.data.data.index[0].id > 0) {
            this.priceTime = res.data.data.index[0].id
          } else {
            this.priceTime = res.data.data.index[1].id
          }
          if (res.data.data.index2[0].id > 0) {
            this.webpriceTime = res.data.data.index2[0].id
          } else {
            this.webpriceTime = res.data.data.index2[1].id
          }
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择最新
    changeStatus (callback) {
      this.is_newest = callback
      if (callback === '1') {
        this.loadbrandlist()
        this.is_newtimes = 1
        this.searchDatas()
        this.priceTime = ''
        this.timer = window.setInterval(() => {
          setTimeout(() => {
            this.get()
          }, 0)
        }, 60000)
      }
      if (callback === '0') {
        window.clearInterval(this.timer)
        //  发布通知
        globalBus.$emit('searchengineerPrice', { status: 2, data: callback })
        this.is_newtimes = 2
      }
    },
    // 选择日期
    changeDate (callback) {
      if (callback !== null) {
        this.priceDate = callback
        this.priceTime = null
        this.webpriceTime = null
        getMarketPricePIdAndBrands({ date: callback }).then(res => {
          if (res.data.status === 'success') {
            this.priceTimes = res.data.data.index
            this.webpriceTimes = res.data.data.index2
            this.brandlist = res.data.data.brand_group
          } else {
            console.log('获取数据失败')
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      } else {
        this.priceTime = null
        this.priceTimes = null
        this.webpriceTime = null
        this.webpriceTimes = null
      }
    },
    // 选择次序
    changeTime (callback) {
      this.priceTime = callback
    },
    // 选择次序
    changewebTime (callback) {
      this.webpriceTime = callback
    },

    // 进入页面就获取品牌
    loadbrandlist () {
      getMarketPricePIdAndBrands().then(res => {
        if (res.data.status === 'success') {
          // this.priceTimes = res.data.data.index
          this.brandlist = res.data.data.brand_group
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取规格
    loadgetStandardTree () {
      getStandardTree().then(res => {
        if (res.data.status === 'success') {
          for (const item of res.data.data) {
            if (item.cate_spec === '螺纹钢') {
              this.filter.options.LWG_sizes = item.size
            }
            if (item.cate_spec === '盘螺') {
              this.filter.options.PL_sizes = item.size
            }
            if (item.cate_spec === '高线') {
              this.filter.options.GX_sizes = item.size
            }
          }
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取地址
    loadgetaddress () {
      getaddress().then(res => {
        if (res.data.status === 'success') {
          this.addressList = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择地址
    changeProvinceCity (value) {
      this.filter.selected.address.province = value[0]
      this.filter.selected.address.city = value[1]
      this.filter.selected.address.area = value[2]
    },
    // 提交搜索
    searchData () {
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
          return
        }
      }
      if (this.is_newest === '0') {
        this.is_newests = false
      } else {
        this.is_newests = true
      }
      this.updateLoading = true
      const loading = this.$loading({
        lock: true,
        text: '正在获取数据...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      getEngineerPrice({
        cate_spec: [
          { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
          { name: '盘螺', size: this.filter.selected.PL_sizes },
          { name: '高线', size: this.filter.selected.GX_sizes }
        ],
        parent_id: this.priceTime,
        web_parent_id: this.webpriceTime,
        is_newest: this.is_newests,
        brands: this.filter.selected.brands,
        sort_value: this.filter.selected.sortType,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area,
        page: this.page,
        per_page: this.per_page
      }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          globalBus.$emit('searchengineerPrice', {
            status: 1,
            cate_spec: [
              { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
              { name: '盘螺', size: this.filter.selected.PL_sizes },
              { name: '高线', size: this.filter.selected.GX_sizes }
            ],
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            is_newestt: this.is_newest,
            brands: this.filter.selected.brands,
            city: this.filter.selected.address.city,
            area: this.filter.selected.address.area,
            dates: this.priceDate,
            page: this.page,
            is_today: res.data.is_today,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
          this.updateLoading = false
          loading.close()
        } else {
          this.updateLoading = false
          loading.close()
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.updateLoading = false
        loading.close()
        console.log('失获取数据败', err)
      })
    },
    // 提交搜索
    searchDatas () {
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
          return
        }
      }
      if (this.is_newest === '0') {
        this.is_newests = false
      } else {
        this.is_newests = true
      }
      this.updateLoading = true
      getEngineerPrice({
        cate_spec: [
          { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
          { name: '盘螺', size: this.filter.selected.PL_sizes },
          { name: '高线', size: this.filter.selected.GX_sizes }
        ],
        parent_id: this.priceTime,
        web_parent_id: this.webpriceTime,
        is_newest: this.is_newests,
        brands: this.filter.selected.brands,
        sort_value: this.filter.selected.sortType,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area,
        page: this.page,
        per_page: this.per_page
      }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          globalBus.$emit('searchengineerPrice', {
            status: 1,
            cate_spec: [
              { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
              { name: '盘螺', size: this.filter.selected.PL_sizes },
              { name: '高线', size: this.filter.selected.GX_sizes }
            ],
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            is_newestt: this.is_newest,
            is_today: res.data.is_today,
            brands: this.filter.selected.brands,
            city: this.filter.selected.address.city,
            area: this.filter.selected.address.area,
            dates: this.priceDate,
            page: this.page,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
          this.updateLoading = false
        } else {
          this.updateLoading = false
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('失获取数据败', err)
      })
    },
    // 提交搜索截图
    searchimgData () {
      if (this.is_newest === '0') {
        if (this.priceTime === null) {
          this.$message.error('请选择报价次序')
          return
        }
      }
      if (this.is_newest === '0') {
        this.is_newests = false
      } else {
        this.is_newests = true
      }
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      this.gettime = hh + ':' + mf + ':' + ss
      const loading = this.$loading({
        lock: true,
        text: '正在获取数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      getEngineerPrice({
        cate_spec: [
          { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
          { name: '盘螺', size: this.filter.selected.PL_sizes },
          { name: '高线', size: this.filter.selected.GX_sizes }
        ],
        parent_id: this.priceTime,
        web_parent_id: this.webpriceTime,
        is_newest: this.is_newests,
        brands: this.filter.selected.brands,
        sort_value: this.filter.selected.sortType,
        city: this.filter.selected.address.city,
        area: this.filter.selected.address.area
      }).then(res => {
        if (res.data.status === 'success') {
          this.times = res.data.date_time.time
          if (res.data.is_today === 1) {
            this.is_newtimes = 1
            this.dates = res.data.date_time.date
          } else {
            this.is_newtimes = 2
            this.dates = res.data.price_time
          }
          this.page_items = res.data.data.map(val => {
            if (val.warehouse === '钢厂直发') {
              val.freight = '-'
              val.price = '-'
            }
            if (val.web_float > 0) {
              val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float < 0) {
              val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
            }
            if (val.web_float === 0) {
              val.web_float = '<span class="yellow_font">-平</span>'
            }
            if (val.diff_price_float > 0) {
              val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float < 0) {
              val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
            }
            if (val.diff_price_float === 0) {
              val.diff_price_float = '<span class="yellow_font">-平</span>'
            }
            if (val.market_float > 0) {
              val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float < 0) {
              val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
            }
            if (val.market_float === 0) {
              val.market_float = '<span class="yellow_font">-平</span>'
            }
            return val
          })
          //  发布通知
          globalBus.$emit('searchengineerPrice', {
            status: 1,
            cate_spec: [
              { name: '螺纹钢', size: this.filter.selected.LWG_sizes },
              { name: '盘螺', size: this.filter.selected.PL_sizes },
              { name: '高线', size: this.filter.selected.GX_sizes }
            ],
            parent_id: this.priceTime,
            date_time: res.data.date_time,
            is_newest: this.is_newests,
            brands: this.filter.selected.brands,
            city: this.filter.selected.address.city,
            area: this.filter.selected.address.area,
            dates: this.priceDate,
            page: this.page,
            is_today: res.data.is_today,
            pagetotal: res.data.data.page_total,
            data: res.data.data.map(val => {
              if (val.warehouse === '钢厂直发') {
                val.freight = '-'
                val.price = '-'
              }
              if (val.web_float > 0) {
                val.web_float = '<span class="red_font">↑' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float < 0) {
                val.web_float = '<span class="green_font">↓' + Math.abs(val.web_float) + '</span>'
              }
              if (val.web_float === 0) {
                val.web_float = '<span class="yellow_font">-平</span>'
              }
              if (val.diff_price_float > 0) {
                val.diff_price_float = '<span class="red_font">↑' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float < 0) {
                val.diff_price_float = '<span class="green_font">↓' + Math.abs(val.diff_price_float) + '</span>'
              }
              if (val.diff_price_float === 0) {
                val.diff_price_float = '<span class="yellow_font">-平</span>'
              }
              if (val.market_float > 0) {
                val.market_float = '<span class="red_font">↑' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float < 0) {
                val.market_float = '<span class="green_font">↓' + Math.abs(val.market_float) + '</span>'
              }
              if (val.market_float === 0) {
                val.market_float = '<span class="yellow_font">-平</span>'
              }
              return val
            })
          })
          loading.close()
          this.dialogTableVisible = true
        } else {
          this.updateLoading = true
          console.log('获取数据失败')
        }
      }).catch(err => {
        this.updateLoading = true
        console.log('失获取数据败', err)
      })
    },
    // 选择品牌
    brandlists () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
    },
    // 清空搜索条件
    clearChecked () {
      this.filter.selected.brands = []
      this.filter.selected.LWG_sizes = []
      this.filter.selected.GX_sizes = []
      this.filter.selected.PL_sizes = []
    },
    // 展开品牌
    changebrand () {
      if (this.activeName === undefined || this.activeName.length <= 0) {
        this.isretract = false
      } else {
        this.isretract = true
      }
    },
    // 收起品牌
    retract () {
      this.isretract = false
      this.activeName = []
    },
    // 获取截图
    getImg () {
      const loading = this.$loading({
        lock: true,
        text: '正在截图中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      var node = this.$refs.mynode
      var seft = this
      domtoimage.toPng(node).then(function (dataUrl) {
        var img = new Image()
        img.src = dataUrl
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = seft.dates + 'new-steel-data.png' || '下载图片名称'
        a.href = dataUrl
        a.dispatchEvent(event)
        loading.close()
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    },
    // 当前时间
    createCurrentTime () {
      const date = new Date()
      const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      this.currentTime = hours + ':' + minutes + ':' + seconds
      setTimeout(this.createCurrentTime, 1000)
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    // 访问引导页
    guide () {
      this.driver.defineSteps(steps)
      this.driver.start()
    },
    // 轮询
    get () {
      if (this.screeninfo === '1') {
        getUpdateNum().then(res => {
          if (window.localStorage.getItem('UpdateNum') !== res.data.data) {
            if (this.is_newest === 1) {
              this.searchDatas()
            }
          }
          window.localStorage.setItem('UpdateNum', res.data.data)
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      }
    },
    selectedbands () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 195px;
  padding-bottom: 10px;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox {
  margin-right: 20px;
}

/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
/deep/.el-checkbox-group {
  padding-top: 10px;
}
/deep/.el-radio {
  margin-right: 20px;
  margin-top: 15px;
}
/deep/.el-radio__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-radio {
  margin-right: 20px;
}

/deep/.el-radio__inner::after {
  left: 8px;
  top: 8px;
  width: 8px;
  height: 8px;
}
@media screen and (max-width: 1366px) {
  .layui-form-item .layui-input-inline {
    float: left;
    width: 146px;
    margin-left: -10px;
  }
}
.layui-input-block {
  margin-left: 80px;
  min-height: 25px;
  line-height: 25px;
}
.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwiths {
  padding: 0 18px;
}
.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
/deep/.el-switch__label {
  height: 50px;
  line-height: 50px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border: none;
}
/deep/.el-checkbox-button__inner {
  border: none;
  padding: 8px 8px;
  font-size: 12px;
}
/deep/.el-checkbox-button,
.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
  margin: 5px;
}
/deep/.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0px;
}
/deep/.el-drawer__body {
  padding: 30px;
}
/deep/.el-collapse-item__header {
  line-height: 20px;
}
.retract {
  position: fixed;
  bottom: 2%;
  right: 20px;
  z-index: 99999;
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/.el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}
.active-column {
  color: #e6781e;
  font-weight: 600;
}
.active-column2 {
  font-weight: 700;
}
.fr-title {
  font-size: 14px;
  font-weight: 500;
}
.more {
  text-align: center;
  padding: 10px;
  font-size: 14px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

/deep/ .el-table .sort-caret.ascending {
  border-bottom-color: rgb(245, 108, 108);
  top: 5px;
}
/deep/ .el-table .sort-caret.descending {
  border-top-color: rgb(103, 194, 58);
  bottom: 7px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.titles {
  padding: 5px;
  font-size: 16px;
}
.fr {
  float: right;
}
.bgs {
  background-color: #ffffff;
}
.search {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
}
.search input {
  padding: 5px;
  font-style: normal;
  border: none;
}
.search input:focus {
  outline: none;
}
.search .clear_x {
  position: absolute;
  width: 25px;
  top: 3px;
  right: 25px;
  border-right: 1px solid rgb(103, 194, 58);
}
.search i {
  padding: 5px;
}
.search_menu {
  display: none;
  background: rgb(253, 246, 236);
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 9999;
}
.search_menu ul {
  margin-bottom: 0;
}
.search_menu li {
  padding: 5px;
  font-size: 1.1em;
  cursor: pointer;
}
.search_menu li:hover {
  background-color: beige;
}
.search:hover .search_menu {
  display: block;
}
/deep/ .el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
._sdk-help-entrance {
  position: absolute;
  right: 0;
  bottom: 28px;
  width: 10px;
  height: 40px;
  padding: 0;
  color: #fff !important;
  background-color: #006eff !important;
  border-radius: 100px 0 0 100px;
  box-shadow: 0 10px 20px 0 rgba(19, 41, 75, 0.2);
  text-align: left;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: move;
}
.wen {
  position: absolute;
  top: 20px;
  left: 120px;
  font-size: 14px;
}
</style>
