<template>
  <div>
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf ">
          <div class="widget-head fl">
            <div class="widget-title fl">工程指数</div>
            <div class="fr fr-title">
              <div class="fl pr20">地址 : 广东省{{citys}}{{areas}}</div>
              <div class="fl pr20 withfs"><span v-if="is_newtime==1">日期 : {{dates}} {{ currentTime }} </span> <span v-if="is_newtime==2"> 日期 : {{datess}} {{ timess }}</span>
              </div>
              <div class="fl pr20">单位:元/吨</div>
            </div>
          </div>
          <div class="widget-body am-cf">
            <el-table v-loading="loading"
                      :data="page_items"
                      border
                      :row-class-name="tableRowClassName"
                      height="calc(100vh - 190px)"
                      highlight-current-row
                      style="width: 100%">
              <el-table-column prop="brand"
                               label="品牌">
                <template slot-scope="scope">
                  <div><span class="active-column2">{{scope.row.brand}}</span> </div>
                </template>
              </el-table-column>
              <el-table-column label="规格">
                <template slot-scope="scope">
                  <div>{{scope.row.cate_spec}}{{scope.row.size}} </div>
                </template>
              </el-table-column>
              <el-table-column prop="material"
                               label="材质">
              </el-table-column>
              <el-table-column prop="price"
                               label="工程价">
                <template slot-scope="scope">
                  <div><span class="active-column">{{scope.row.price}}</span> </div>
                </template>
              </el-table-column>
              <el-table-column prop="web_price"
                               label="参考价">
                <template slot-scope="scope">
                  <div><span style="padding-right: 5px;">{{scope.row.web_price}}</span>
                    <el-tag size="mini"
                            v-if="scope.row.web_price">{{area}}</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="diffPrice"
                               label="价差">
                <template slot-scope="scope">
                  <div><span class="active-column">{{scope.row.diffPrice}}</span> </div>
                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               id="guide-menu"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <el-tab-pane label="我的关注"
                         name="2"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i>
    </div>
  </div>
</template>

<script>
import { getEngineerPrice, getMarketPricePIdAndBrands, getUserNotice } from '@/api/global'
import Engineer from '@/components/Engineer'
import Myengineer from '@/components/Myengineer'
import globalBus from '@/utils/global-bus'
import { getUserProfile } from '@/api/user'
export default {
  data () {
    return {
      activeName: '1',
      curTabcompnents: {
        1: 'Engineer',
        2: 'Myengineer'
      },
      page: '1',
      per_page: '20',
      page_items: [],
      loading: true,
      is_newtime: 0,
      currentTime: null,
      area: '广州',
      citys: '广州市',
      areas: '天河区',
      dates: '',
      pagetotal: '',
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      cate_spec: [],
      parent_id: '',
      is_newest: '',
      brands: [],
      times: '',
      timess: '',
      datess: '',
      datenow: '',
      topnotice: ''
    }
  },
  components: { Engineer, Myengineer },
  created () {
    // 组件初始化好，
    this.loadgetUserNotice()
    this.loadgetMarketPricePIdAndBrands()
    this.getWebPriceCode()
    this.createCurrentTime()
    // 注册自定义事件
    globalBus.$on('searchengineerPrice', (res) => {
      console.log(res)
      if (res.status === 1) {
        this.ismore = false
        this.page_items = res.data
        this.citys = res.city
        this.areas = res.area
        this.cate_spec = res.cate_spec
        this.parent_id = res.parent_id
        this.is_newest = res.is_newest
        this.brands = res.brands
        this.page = res.page
        // if (res.is_today === 1) {
        // this.is_newtime = 1
        // this.dates = res.date_time.date
        // } else {
        this.is_newtime = 2
        // }
        this.timess = res.date_time.time
        this.datess = res.date_time.date
        this.pagetotal = res.pagetotal
      }
      if (res.status === 2) {
        if (res.data === '0') {
          this.is_newtime = 2
          this.timess = ''
          this.datess = ''
        } else {
          this.is_newtime = 1
          this.dates = this.datenow
        }
      }
    })
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top:92px'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 获取最新次序时间
    loadgetMarketPricePIdAndBrands () {
      getMarketPricePIdAndBrands({ need_parent: 1 }).then(res => {
        if (res.data.data.index[0].id > 0) {
          this.loadgetMarketPrice(res.data.data.index[0].id)
        } else {
          this.loadgetMarketPrice(res.data.data.index[1].id)
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面获取实时价格
    loadgetMarketPrice (parentid) {
      getEngineerPrice({ is_newest: false, parent_id: parentid }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false

          if (res.data.is_today === 1) {
            this.is_newtime = 1
            this.datenow = res.data.date_time.date
          } else {
            this.is_newtime = 2
          }
          this.dates = res.data.date_time.date
          this.times = res.data.date_time.time
          this.datess = res.data.date_time.date
          this.timess = res.data.date_time.time
          this.pagetotal = res.data.data.page_total
          this.page_items = res.data.data
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 当前时间
    createCurrentTime () {
      const date = new Date()
      const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      this.currentTime = hours + ':' + minutes + ':' + seconds
      setTimeout(this.createCurrentTime, 1000)
    },
    // 获取参考区域
    getWebPriceCode () {
      getUserProfile().then(res => {
        this.area = res.data.area
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.realtimeprice {
  position: relative;
  background-color: black;
}
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
  position: relative;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}

@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
    position: relative;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 9999;
  }
}
.layui-side {
  margin-right: 20px;
}
.layui-side-content {
  padding: 20px 0px;
}

/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}
.active-column {
  color: #e6781e;
  font-weight: 600;
}
.active-column2 {
  font-weight: 700;
}
.fr-title {
  font-size: 14px;
  font-weight: 500;
}
.more {
  position: absolute;
  z-index: 9999;
  bottom: 15px;
  left: 50%;
  text-align: center;
  padding: 10px;
  font-size: 14px;
}
.widget {
  min-height: 148px;
  border-radius: 0;
  position: relative;
  padding: 10px 10px 25px 15px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

/deep/ .el-table .sort-caret.ascending {
  border-bottom-color: rgb(245, 108, 108);
  top: 5px;
}
/deep/ .el-table .sort-caret.descending {
  border-top-color: rgb(103, 194, 58);
  bottom: 7px;
}
/deep/ .el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
</style>
