<template>
  <div class="layui-tab-content">
    <div class="layui-form">
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwith">时间</el-tag>
        </label>
        <div class="layui-input-block"
             style="margin-top: 5px">
          <el-date-picker v-model="date"
                          style="width: 80% "
                          type="date"
                          placeholder="选择日期"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions0"> </el-date-picker>
        </div>
      </div>
      <div class="layui-form-item">
        <div class="buttongt">
          <el-button type="primary"
                     @click="steelstore(date)"
                     :loading="updateLoading">确定</el-button>
          <el-button type="warning"
                     @click="searchimgData(date)">获取截图</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="每周库存截图"
               append-to-body
               width="50%"
               :visible.sync="dialogTableVisible">
      <div class="fr">
        <el-button type="primary"
                   @click="getImg">保存截图</el-button>
      </div>
      <div ref="mynode"
           class="bgs">
        <div class="titles">每周库存</div>
        <el-table :data="steelstoredatas"
                  v-loading="loading"
                  border
                  :row-class-name="tableRowClassName"
                  highlight-current-row
                  style="width: 100%;font-weight:bold">
          <el-table-column :label="title">
            <el-table-column prop="warehouse"
                             label="仓库"></el-table-column>
            <el-table-column prop="threadstock"
                             label="螺纹库存"></el-table-column>
            <el-table-column prop="snailstock"
                             label="盘螺库存"></el-table-column>
            <el-table-column prop="highlinestock"
                             label="高线库存"></el-table-column>
            <el-table-column prop="warehousetotal"
                             label="合计"></el-table-column>
          </el-table-column>
        </el-table>
        <div class="bg"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSteelflowWeekDate, getSteelstore } from '@/api/global'
import globalBus from '@/utils/global-bus'
import domtoimage from 'dom-to-image'
export default {
  data () {
    return {
      date: '',
      dateBetween: [],
      loading: true,
      pickerOptions0: {},
      updateLoading: false,
      steelstoredatas: [],
      dialogTableVisible: false,
      title: ''

    }
  },
  created () {
    // 注册自定义事件
    this.getWeekDate()
  },
  mounted () {

  },
  methods: {
    // 获取最新日期
    getWeekDate () {
      getSteelflowWeekDate().then(res => {
        this.date = res.data.date
        this.steelstore(res.data.date)
        this.dateBetween = res.data.data
        this.pickerOptions0 = this.getdatea(res.data.data)
      })
    },
    // 搜索数据
    steelstore (date) {
      this.updateLoading = true
      getSteelstore({ startdate: date }).then(res => {
        if (res.data.status === 'success') {
          //  发布通知
          globalBus.$emit('steelstoredatas', { data: res.data.data, date: date })
          this.updateLoading = false
        } else {
          this.updateLoading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('获取数据失败', err)
      })
    },
    getdatea () {
      const self = this
      return {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        },
        cellClassName (time) {
          let day = time.getDate()
          if (day < 10) {
            day = '0' + day
          };
          let month = time.getMonth() + 1
          if (month < 10) {
            month = '0' + month
          }
          const year = time.getFullYear()
          const res = year + '-' + month + '-' + day
          for (let i = 0; i < self.dateBetween.length; i++) {
            const _time = self.dateBetween[i]
            if (res === _time) {
              return 'red'
            }
          }
        }
      }
    },
    // 提交搜索截图
    searchimgData (date) {
      this.dialogTableVisible = true
      this.updateLoading = true
      getSteelstore({ startdate: date }).then(res => {
        if (res.data.status === 'success') {
          this.steelstoredatas = res.data.data
          this.title = date + ' 广州样本仓库数据统计'
          this.updateLoading = false
          this.loading = false
        } else {
          this.updateLoading = false
          this.loading = false
          this.$message.error('您选择的日期查询不到数据')
        }
      }).catch(err => {
        this.updateLoading = false
        this.loading = false
        console.log('获取数据失败', err)
      })
    },
    // 获取截图
    getImg () {
      const loading = this.$loading({
        lock: true,
        text: '正在截图中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })
      var seft = this
      var node = this.$refs.mynode
      domtoimage.toPng(node).then(function (dataUrl) {
        var img = new Image()
        img.src = dataUrl
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = seft.createPic() + '.png' || '下载图片名称'
        a.href = dataUrl
        a.dispatchEvent(event)
        loading.close()
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    },
    // 产生随机图片名称
    createPic () {
      var now = new Date()
      var year = now.getFullYear()
      var month = now.getMonth()
      var date = now.getDate()
      var hour = now.getHours()
      var minu = now.getMinutes()
      month = month + 1
      if (month < 10) month = '0' + month
      if (date < 10) date = '0' + date
      var number = now.getSeconds() % 43 // 这将产生一个基于目前时间的0到42的整数。
      var time = year + month + date + hour + minu
      return time + '_' + number
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    }
  }
}
</script>
<style lang="less" >
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  font-size: 14px;
}
.layui-form-item {
  margin-bottom: 5px;
  clear: both;
  *zoom: 1;
}
.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}

.layui-form-item .layui-input-inline {
  float: left;
  width: 190px;
  margin-right: 25px;
  margin-left: -10px;
}

.buttongt {
  margin-top: 20px;
  margin-left: 10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px !important;
}
.butwiths {
  padding: 0 18px;
}
.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
.fr {
  float: right;
}
.bgs {
  background-color: #ffffff;
}
.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

.el-table .success-row {
  background: #ffffff !important; // 这里可以修改颜色
}
.el-table th > .cell {
  text-align: center;
}
.el-table .cell {
  text-align: center;
  line-height: 21px;
}
.el-table thead.is-group th {
  background: #707880 !important;
}
.el-table th {
  background-color: #707880 !important;
}
.el-table thead {
  color: #ffffff !important;
  font-weight: bold;
}
.el-table th {
  padding: 4px 0 !important;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.el-table td {
  color: #4d4c4c;
  padding: 6px 0 !important;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

.el-table .sort-caret.ascending {
  border-bottom-color: rgb(245, 108, 108);
  top: 5px;
}
.el-table .sort-caret.descending {
  border-top-color: rgb(103, 194, 58);
  bottom: 7px;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.titles {
  clear: both;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
}
.bg {
  width: 100%;
  height: 90%;
  z-index: 10;
  top: 20%;
  position: absolute;
  background: url('../assets/images/back.png');
}
.red span {
  color: #fff;
  background-color: #e0a80c;
}
</style>
