<template>
  <div class="container">
    <div class="infoMsgHead">
      <div class="layui-container">
        <div class="user-left">
          <div class="user-img preview-image-wrap"
               @mouseover="mouseOver"
               @mouseleave="mouseLeave"
               @click="$refs.file.click()">
            <img class="preview-image"
                 :src="headimgurl">
            <p class="modify-img"
               v-if="isimg==true"
               ref="headimgurl">上传头像</p>
          </div>
          <input id="file"
                 type="file"
                 ref="file"
                 hidden
                 @change="onFileChange">
        </div>
        <div class="user-info">
          <p class="id-msg"><span class="user-id">{{user.name}}</span></p>
          <p class="wealth-msg">
            <span class="push-msg"
                  v-if="user.vip_level == 2"><span class="vip"></span><span class="vip-msg">VIP会员</span></span>
            <span class="addCbb"
                  v-else-if="user.vip_level == 3">永久会员</span>
            <span class="addCb"
                  v-else>普通用户</span>
          </p>
        </div>
      </div>
    </div>
    <div class="layui-container mt15">
      <div class="layui-row">
        <div class="layui-col-md2 layui-col-xs3  bgs mr15">
          <ul class="aside">
            <li class=""><a href="/#/personal"
                 class="zl">个人资料</a></li>
            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  <div class="zls"> 帐号设置</div>
                </template>
                <li class=""><a href="/#/password"
                     class="zl">修改密码</a></li>
                <li class=""><a href="/#/cellphone"
                     class="zl">修改手机号</a></li>
              </el-collapse-item>
            </el-collapse>
            <li class="router-link-exact-active router-link-active"><a href="/#/notice"
                 class="zl">系统通知</a></li>
          </ul>
        </div>
        <div class="layui-col-md9 layui-col-xs8  bgs">
          <div class="msg-list">
            <el-tabs v-model="activeNames"
                     @tab-click="handleClick">
              <el-tab-pane label="未读"
                           name="first"></el-tab-pane>
              <el-tab-pane label="已读"
                           name="second"></el-tab-pane>
            </el-tabs>
            <div class=""
                 :style="weidudisplay">
              <el-collapse @change="clicknotice">
                <el-collapse-item v-for="(item,index) in noticelist"
                                  :key="index"
                                  :name="item.id">
                  <template slot="title">
                    <span class="title">{{item.title}}</span>
                    <span class="fr options-info options-f">
                      <em class="data-time">{{item.created_at}}</em></span>
                  </template>
                  <div class="drop-content">
                    <span>{{item.main_body}}</span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class=""
                 :style="yidudisplay">
              <el-collapse>
                <el-collapse-item v-for="(item,index) in noticelists"
                                  :key="index">
                  <template slot="title">
                    <span class="title">{{item.title}}</span>
                    <span class="fr options-info options-f">
                      <em class="data-time">{{item.created_at}}</em></span>
                  </template>
                  <div class="drop-content">
                    <span>{{item.main_body}}</span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改头像"
               width="30%"
               :visible.sync="dialogVisible"
               append-to-body
               @opened="onDialogOpened"
               @closed="onDialogClosed">
      <div class="preview-image-wrap">
        <img class="preview-image"
             :src="previewImage"
             ref="preview-image">
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   :loading="updatePhotoLoading"
                   @click="onUpdatePhoto">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { formatDate } from '@/utils/date.js'
import { getUserProfile, senduserinfo, updateUserPhoto, getNoticeList, getNoticeIn, getNoticeRead } from '@/api/user'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      user: [],
      userinfo: {
        name: '',
        company_name: '',
        sex: '1'
      },
      sexs: '',
      headimgurl: '',
      isshow: false,
      isimg: false,
      weidudisplay: 'display:block',
      yidudisplay: 'display:none',
      formRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      },
      activeNames: 'first',
      dialogVisible: false,
      previewImage: '', // 预览图片
      cropper: null, // 裁切器示例
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      noticelist: [],
      noticelists: []
    }
  },
  created () {
    // 组件初始化
    this.loadUserProfile()
    this.getNoticeList()
    this.getNoticeRead()
  },

  methods: {
    // 获取用户信息
    loadUserProfile () {
      getUserProfile().then(res => {
        this.user = res.data.data
        this.userinfo.name = res.data.data.name
        this.userinfo.company_name = res.data.data.company_name
        this.userinfo.sex = res.data.data.sex
        if (res.data.data.headimgurl === undefined || res.data.data.headimgurl <= 0) {
          this.headimgurl = 'https://66gangtie.oss-cn-shanghai.aliyuncs.com/web/uploads/20210106/1657895623edb24ea.jpg'
        } else {
          this.headimgurl = 'https://api.66gangtie.com/' + res.data.data.headimgurl
        }
        if (res.data.data.sex === 1) {
          this.sexs = '男'
        } else {
          this.sexs = '女'
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    editinfo () {
      this.isshow = true
    },
    saveinfo () {
      this.$refs['userinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      senduserinfo(this.userinfo).then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.sex === 1) {
            this.sexs = '男'
          } else {
            this.sexs = '女'
          }
          globalBus.$emit('personals', { status: 2, name: res.data.data.name })
          this.user = res.data.data
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.isshow = false
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    // 移入
    mouseOver () {
      // 操作dom 获取p标签改变其样式
      this.isimg = true
    },
    // 移出
    mouseLeave () {
      this.isimg = false
    },
    // 处于图片预览
    onFileChange () {
      const file = this.$refs.file
      const blobData = window.URL.createObjectURL(file.files[0])
      this.previewImage = blobData
      this.dialogVisible = true
      this.$refs.file.value = ''
    },
    // 对话框关闭，销毁裁切器
    onDialogClosed () {
      // this.cropper.destroy()
      this.updatePhotoLoading = false
    },
    // 打开图片剪辑实例
    onDialogOpened () {
      const image = this.$refs['preview-image']
      if (this.cropper) {
        this.cropper.replace(this.previewImage)
        return false
      }
      this.cropper = new Cropper(image, {
        viewMode: 1,
        dragMode: 'none',
        aspectRatio: 1,
        cropBoxResizable: false
      })
    },
    // 上传头像
    onUpdatePhoto () {
      this.updatePhotoLoading = true
      // 获取裁切的图片对象
      this.cropper.getCroppedCanvas().toBlob(file => {
        const fd = new FormData()
        fd.append('headImgUrl', file)
        // 请求更新用户头像请求提交 fd
        this.blobToBase64(file).then(res => {
          // 转化后的base64
          console.log(res)
          updateUserPhoto({ headImgUrl: res }).then(res => {
            // console.log(res.data)
            // 关闭对话框
            this.dialogVisible = false
            // 更新视图展示
            // 直接把裁切结果的文件对象转为 blob 数据本地预览
            this.headimgurl = window.URL.createObjectURL(file)
            // 关闭确定按钮的 loading
            this.updatePhotoLoading = false
            this.$message({
              type: 'success',
              message: '更新头像成功'
            })
            // 更新顶部登录用户的信息
            globalBus.$emit('personals', { status: 1, headimgurl: this.headimgurl })
            // 把服务端返回的图片进行展示有点慢
            // this.user.photo = res.data.data.photo
          })
        })
      })
    },
    // 将blob转为base64
    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          resolve(e.target.result)
        }
        // readAsDataURL
        fileReader.readAsDataURL(blob)
        fileReader.onerror = () => {
          reject(new Error('文件流异常'))
        }
      })
    },
    handleClick (tab, event) {
      if (tab.label === '未读') {
        this.weidudisplay = 'display:block'
        this.yidudisplay = 'display:none'
      }
      if (tab.label === '已读') {
        this.weidudisplay = 'display:none'
        this.yidudisplay = 'display:block'
      }
    },
    getNoticeList () {
      getNoticeList().then(res => {
        if (res.data.status === 'success') {
          this.noticelist = res.data.data.map(val => {
            val.created_at = formatDate(Date.parse(val.created_at))
            return val
          })
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    getNoticeRead () {
      getNoticeRead().then(res => {
        if (res.data.status === 'success') {
          this.noticelists = res.data.data.map(val => {
            val.created_at = formatDate(Date.parse(val.created_at))
            return val
          })
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    clicknotice (val) {
      if (val.length !== 0) {
        getNoticeIn({ id: val[0] }).then(res => {
          if (res.data.status === 'success') {
            globalBus.$emit('personals', { status: 3, num: res.data.num })
          }
        }).catch(err => {
          console.log('失获取数据败', err)
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #f2f2f2;
}
.bgs {
  background-color: #fff;
  border-radius: 2px;
  min-height: 400px;
}
.mr15 {
  margin-right: 15px;
}
.mt15 {
  margin-top: 15px;
}
.zls {
  text-align: center;
  height: 35px;
  line-height: 35px;
  width: 100%;
  padding-left: 10px;
}
.infoMsgHead {
  height: 150px;
  width: 100%;
  background: url(../../assets/images/banner@2x.23abf20.png) no-repeat;
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  background-size: 100% 100%;
  min-width: 1200px;
}
.user-left {
  float: left;
  padding: 35px 48px 0 40px;
}
.user-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px solid #464646;
}
.user-img img {
  width: 100%;
  height: 100%;
}
.user-info {
  float: left;
  padding-top: 40px;
  color: #fff;
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 24px;
}
.user-info span {
  display: inline-block;
  line-height: 32px;
  text-align: center;
  vertical-align: bottom;
  font-weight: 600;
  font-size: 16px;
}
.wealth-msg {
  height: 32px;
  margin-top: 17px;
}
.push-msg {
  padding: 0 16px;
  background: #bb9444;
  border-radius: 16px;
  margin-right: 13px;
  cursor: pointer;
}
.vip {
  width: 24px;
  height: 21px;
  margin-right: 8px;
  position: relative;
  top: -9px;
  background: url(../../assets/images/5f15827.png) no-repeat;
  background-position: -57px -13px;
  background-size: 240px 50px;
}
.vip-msg {
  color: #fff;
}
li {
  display: inline-block;
}
.aside:first-child {
  padding-top: 16px;
}
.aside li {
  min-height: 35px;
  width: 100%;
  line-height: 35px;
  font-size: 14px;
  color: #555666;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .layui-container {
    width: 100%;
  }
}
.aside li .zl {
  font-size: 14px;
  color: #555666;
  display: block;
  height: 35px;
  line-height: 35px;
  width: 128px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  border-radius: 17.5px;
  font-family: PingFangSC-Regular, PingFang SC !important;
}
.aside li.router-link-active .zl {
  background: #f6f7f8;
  color: #222226;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC !important;
}
.aside li .left_box_menu {
  width: 128px;
  line-height: 35px;
  font-size: 14px;
  color: #555666;
  margin: 0 auto;
}
.info-box {
  padding: 0 20px;
}
.info-box header {
  width: 100%;
  padding-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}
.info-box .left-title {
  color: #222226;
  font-size: 16px;
  font-weight: 600;
}
.info-box .left-title .t-icon {
  width: 16px;
  height: 14px;
  display: inline-block;
  background: url(../../assets/images/5f15827.png) no-repeat;
  background-size: 174px 36px;
  background-position: -96px -11px;
  cursor: pointer;
}
.info-box .line-box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  background: #e5e5e5;
  margin-top: 12px;
}
.info-box .right-btn {
  padding: 0 16px;
  text-align: center;
  border: 1px solid #108ee9;
  border-radius: 13px;
  color: #108ee9;
  cursor: pointer;
}
.info-box .left-title {
  color: #222226;
  font-size: 16px;
  font-weight: 600;
}
.contain .msg-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 14px 0;
  color: #555666;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.contain .msg-item .msg-key {
  width: 75px;
  text-align: right;
  float: left;
  word-break: break-all;
}
.contain .msg-item .msg-val {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 16px;
  word-break: break-all;
}
/deep/.el-input {
  width: 50%;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0px;
}
/deep/.el-collapse {
  margin-bottom: 10px;
}
.addCb {
  width: 100px;
  background: #909399;
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}
.addCbb {
  width: 100px;
  background: #67c23a;
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}
.date span {
  font-size: 18px;
  font-weight: 500;
  color: #00be6e;
  vertical-align: middle;
  margin-right: 5px;
}

.right-act {
  background: #108ee9;
  color: #fff !important;
}
.user-img p {
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.8);
}
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
  }
}
.msg-list {
  padding: 0 15px 0 15px;
  min-height: 370px;
}
.msg-list .list-system {
  position: relative;
}
.msg-list ul {
  font-size: 14px;
}
.msg-list ul li {
  position: relative;
  padding: 16px 0;
}
.msg-list .msg-type {
  display: inline-block;
  height: 20px;
  line-height: 18px;
  padding: 0 5px;
  color: #4d4d4d;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  vertical-align: top;
  margin-top: 3px;
}
.msg-list ul li .msg-title {
  width: 90%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: #3399ea;
}
.msg-list .list li .msg-title .title {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.msg-list .list li .options-f {
  width: 14%;
}
.msg-list .list li .options-info {
  position: relative;
  display: inline-block;
  width: 10%;
}
.msg-list em {
  display: inline-block;
  font-style: normal;
  color: #ccc;
}
.title {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: #333333;
}
.drop-content {
  margin-top: 15px;
  margin-bottom: -6px;
  padding: 15px;
  background-color: #f7f7f7;
  font-size: 14px;
  color: #4d4d4d;
  line-height: 22px;
}
</style>
