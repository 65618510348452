<template>
  <div class="layui-tab-content">
    <div class="layui-form">
      <div class="layui-form-item">
        <label class="layui-form-label">
          <el-tag class="butwiths">品牌</el-tag>
        </label>
        <div class="layui-input-block">
          <el-select
            v-model="filter.selected.brands"
            filterable
            placeholder="请输入品牌"
            :loading="loading"
            @change="selectedbands"
          >
            <el-option
              v-for="item in brandlist"
              :key="item.label"
              :label="item.zname"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="">
          <el-collapse v-model="activeName" @change="changebrand">
            <el-collapse-item>
              <template slot="title">
                <span v-if="isband == false">选择品牌</span>
                <span v-else>
                  <font v-for="item in filter.selected.brands" :key="item">{{
                    item
                  }}</font>
                </span>
              </template>
              <el-radio-group
                v-model="filter.selected.brands"
                @change="brandlists"
              >
                <el-radio-button
                  v-for="item in brandlist"
                  :label="item.name"
                  :key="item.label"
                  >{{ item.zname }}</el-radio-button
                >
              </el-radio-group>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="list">
          <div v-for="item in brandtype" :key="item.id">
            <el-button @click="basicdata(item.id, item.isimg, item.title)">{{
              item.title
            }}</el-button
            ><span class="fr"><i class="el-icon-arrow-right"></i></span>
            <el-divider></el-divider>
          </div>
        </div>
      </div>
    </div>
    <div class="retract" v-if="isretract == true">
      <el-button type="primary" @click="retract">收起品牌</el-button>
    </div>
  </div>
</template>

<script>
import { getMarketPricePIdAndBrands, getBrandInfo } from '@/api/global'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      activeName: [],
      brandlist: [],
      filter: {
        selected: {
          brands: '广钢'
        }
      },
      isband: false,
      isretract: false,
      loading: false,
      brands: [],
      brandtype: [
        {
          id: 1,
          isimg: false,
          title: '品牌介绍'
        }, {
          id: 2,
          isimg: false,
          title: '生产规格'
        }, {
          id: 3,
          isimg: true,
          title: '钢牌/钢标样式'
        }, {
          id: 4,
          isimg: true,
          title: '理计重量标准'
        }, {
          id: 5,
          isimg: false,
          title: '承运方式'
        }, {
          id: 6,
          isimg: true,
          title: '钢厂三证'
        }, {
          id: 7,
          isimg: true,
          title: '质量管理体系认证书'
        }, {
          id: 8,
          isimg: true,
          title: '生产许可证'
        }, {
          id: 9,
          isimg: true,
          title: '环境管理体系认证书'
        }, {
          id: 10,
          isimg: true,
          title: '职业健康安全管理体系认证书'
        }, {
          id: 11,
          isimg: true,
          title: '国检报告'
        }, {
          id: 12,
          isimg: true,
          title: '其他'
        }
      ]
    }
  },
  created () {
    this.loadbrandlist()
    this.loadbrandinfo()
  },
  methods: {
    // 进入页面就获取品牌
    loadbrandlist () {
      getMarketPricePIdAndBrands().then(res => {
        if (res.data.status === 'success') {
          this.priceTimes = res.data.data.index
          this.brandlist = res.data.data.brand_group
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 进入页面就获取一个品牌内容
    loadbrandinfo () {
      this.isband = true
      getBrandInfo({ brand: this.filter.selected.brands }).then(res => {
        // console.log(res.data)
        if (res.data.status === 'success') {
          this.brands = res.data.data
          globalBus.$emit('brandinfo', { id: 1, isimg: false, title: '品牌介绍', data: this.brands, brand: this.filter.selected.brands })
          // console.log(this.brands)
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 选择品牌
    brandlists () {
      this.isband = true
      getBrandInfo({ brand: this.filter.selected.brands }).then(res => {
        // console.log(res.data)
        if (res.data.status === 'success') {
          this.brands = res.data.data
          globalBus.$emit('brandinfo', { status: 1, data: res.data.data, brand: this.filter.selected.brands })
          this.retract()
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 展开品牌
    changebrand () {
      if (this.activeName === undefined || this.activeName.length <= 0) {
        this.isretract = false
      } else {
        this.isretract = true
      }
    },
    // 收起品牌
    retract () {
      this.isretract = false
      this.activeName = []
    },
    // 基础资料
    basicdata (id, isimg, title) {
      //  发布通知
      if (this.brands.length === 0) {
        this.$message.error('请选择品牌')
        return
      }
      globalBus.$emit('brandinfo', { status: 2, id: id, isimg: isimg, title: title, data: this.brands })
    },
    selectedbands () {
      this.isband = true
      if (this.filter.selected.brands.length === 0) {
        this.isband = false
      }
      getBrandInfo({ brand: this.filter.selected.brands }).then(res => {
        // console.log(res.data)
        if (res.data.status === 'success') {
          this.brands = res.data.data
          globalBus.$emit('brandinfo', { status: 1, data: res.data.data })
          this.retract()
        } else {
          console.log('获取数据失败')
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.layui-tab-content {
  padding-left: 25px;
  margin-top: -15px;
}
.layui-form-label {
  width: auto;
  margin-left: -25px;
  font-size: 14px;
}

.el-switch {
  line-height: 36px;
  height: 36px;
}
.el-badge {
  padding: 5px;
}
/deep/.el-checkbox__inner {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
/deep/.el-checkbox__inner::after {
  left: 5px;
  top: 2px;
}
/deep/.el-select {
  width: 100%;
  padding: 0px 0;
}
.layui-input-block {
  margin-left: 80px;
  min-height: 50px;
  line-height: 50px;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 190px;
  margin-right: 25px;
  margin-left: -10px;
}

.buttongt {
  margin-top: 20px;
  margin-left: -10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.butwith {
  padding: 0 23px;
}
.butwiths {
  padding: 0 18px;
}
.el-tabs__content {
  overflow: auto !important;
  position: relative;
}
/deep/.el-switch__label {
  height: 50px;
  line-height: 50px;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border: none;
}
/deep/.el-radio-button__inner {
  border: none;
  padding: 8px 8px;
  font-size: 12px;
}
/deep/.el-radio-button,
.el-radio-button__inner {
  border: 1px solid #dcdfe6;
  margin: 5px;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 0px;
}
/deep/.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0px;
}
/deep/.el-drawer__body {
  padding: 30px;
}
/deep/.el-collapse-item__header {
  line-height: 20px;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  box-shadow: 0 0 0 0 #409eff;
}
/deep/.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 5px 0;
}
.list {
  margin-top: 30px;
}
.fr {
  float: right;
  line-height: 40px;
}
/deep/.el-button {
  border: 1px solid #fff;
  font-size: 14px;
  width: 95%;
  text-align: left;
}
.retract {
  position: fixed;
  bottom: 2%;
  right: 20px;
}
</style>
