<template>
  <div class="container">
    <div class="infoMsgHead">
      <div class="layui-container">
        <div class="user-left">
          <div class="user-img preview-image-wrap"
               @mouseover="mouseOver"
               @mouseleave="mouseLeave"
               @click="$refs.file.click()">
            <img class="preview-image"
                 :src="headimgurl">
            <p class="modify-img"
               v-if="isimg==true"
               ref="headimgurl">上传头像</p>
          </div>
          <input id="file"
                 type="file"
                 ref="file"
                 hidden
                 @change="onFileChange">
        </div>
        <div class="user-info">
          <p class="id-msg"><span class="user-id">{{user.name}}</span></p>
          <p class="wealth-msg">
            <span class="push-msg"
                  v-if="user.vip_level == 2"><span class="vip"></span><span class="vip-msg">VIP会员</span></span>
            <span class="addCbb"
                  v-else-if="user.vip_level == 3">永久会员</span>
            <span class="addCb"
                  v-else>普通用户</span>
          </p>
        </div>
      </div>
    </div>
    <div class="layui-container mt15">
      <div class="layui-row">
        <div class="layui-col-md2 layui-col-xs3 bgs mr15">
          <ul class="aside">
            <li class="router-link-exact-active router-link-active"><a href="/#/personal"
                 class="zl">个人资料</a></li>
            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  <div class="zls"> 帐号设置</div>
                </template>
                <li class=""><a href="/#/password"
                     class="zl">修改密码</a></li>
                <li class=""><a href="/#/cellphone"
                     class="zl">修改手机号</a></li>
              </el-collapse-item>
            </el-collapse>
            <li class=""><a href="/#/notice"
                 class="zl">系统通知</a></li>
          </ul>
        </div>
        <div class="layui-col-md9  layui-col-xs8 bgs">
          <div class="info-box">
            <header>
              <div class="left-title"><span class="title">个人信息</span> <span class="t-icon t-kicon"></span>
              </div>
              <div class="line-box"></div>
              <div class="right-btn clearTpaErr right-noa"
                   v-if="isshow==false"
                   @click="editinfo">修改信息</div>
              <div class="right-btn clearTpaErr right-act"
                   v-else
                   @click="saveinfo">保存</div>
            </header>
            <div class="contain"
                 v-if="isshow==false">
              <div class="msg-item">
                <div class="msg-key">姓名：</div>
                <div class="msg-val">{{user.name}}</div>
              </div>
              <div class="msg-item">
                <div class="msg-key">公司：</div>
                <div class="msg-val"><span>{{user.company_name}}</span></div>
              </div>
              <div class="msg-item">
                <div class="msg-key">手机：</div>
                <div class="msg-val"><span>{{user.mobile}}</span></div>
              </div>
              <div class="msg-item">
                <div class="msg-key">邮箱：</div>
                <div class="msg-val"><span>{{user.email}}</span>
                </div>
              </div>
              <div class="msg-item">
                <div class="msg-key">性别：</div>
                <div class="msg-val"><span>{{sexs}}</span></div>
              </div>
              <div class="msg-item"
                   v-if="user.vip_level == 2">
                <div class="msg-key">到期时间：</div>
                <div class="msg-val date"><span>{{user.end_date}}</span></div>
              </div>
            </div>
            <div class="contain"
                 v-else>
              <el-form ref="userinfo-form"
                       :model="userinfo"
                       :rules="formRules">
                <div class="msg-item">
                  <div class="msg-key">姓名：</div>
                  <div class="msg-val">
                    <el-form-item prop="name">
                      <el-input v-model="userinfo.name"
                                placeholder="请输入内容"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="msg-item">
                  <div class="msg-key">公司：</div>
                  <div class="msg-val">
                    <el-form-item prop="company_name">
                      <el-input v-model="userinfo.company_name"
                                placeholder="请输入内容"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="msg-item">
                  <div class="msg-key">性别：</div>
                  <div class="msg-val">
                    <el-radio-group v-model="userinfo.sex">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改头像"
               width="30%"
               :visible.sync="dialogVisible"
               append-to-body
               @opened="onDialogOpened"
               @closed="onDialogClosed">
      <div class="preview-image-wrap">
        <img class="preview-image"
             :src="previewImage"
             ref="preview-image">
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   :loading="updatePhotoLoading"
                   @click="onUpdatePhoto">确 定</el-button>
      </span>
    </el-dialog>
    <div class="clear"></div>
    <div class="copyright-box">
      <div id="csdn-copyright-footer"
           class="column dark">
        <ul class="footer-column-t">
          <li>
            <img src="../../assets/images/tel.png"
                 alt="">
            <span> 020-83887466-8056</span>
          </li>
          <li>
            <img src="../../assets/images/email.png"
                 alt="">
            <a href="mailto:gzgwzg@yeah.net"
               target="_blank"> gzgwzg@yeah.net</a>
          </li>
          <li>
            工作时间&nbsp;9:00-17:30
          </li>
          <li>
            <a href="javascript:;"
               @click="about"> 关于我们</a>
          </li>
        </ul>
        <ul class="footer-column-b">
          <li><img src="../../assets/images/beian.png"
                 alt=""><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010402002322"
               rel="noreferrer"
               target="_blank">粤公网安备 44010402002322号</a></li>
          <li><a href="http://beian.miit.gov.cn/publish/query/indexFirst.action"
               rel="noreferrer"
               target="_blank">粤ICP备17117697号</a></li>
          <li>©2016-2020 智钢数据服务（广州）有限公司</li>
        </ul>
      </div>
    </div>
    <el-dialog title="关于我们"
               append-to-body
               :visible.sync="dialogTableVisible">
      <div class="about"> 智钢数据服务（广州）有限公司（简称：智钢），2018年成立于中国国际商贸中心——广州。基于母公司八年的行业经验，乘着“互联网+”的时代浪潮，为传统钢铁行业转型升级贡献一份力量。
        智钢致力于打造华南地区钢铁行业的专业服务平台。旗下目前主打产品有“六六钢铁”和“一拍即点”，六六钢铁——以真实交易数据构建华南现货价格体系，利用大数据提供交易、仓储、物流全方位数据服务，为用户提供高效、安全、透明的钢铁在线交易平台；一拍即点——引入AI智能识别技术代替人工数钢筋，用数字技术提升工地整体精细化管理水平。通过互联网真正实现钢铁行业上下游信息化、数据化、智能化的转型升级。</div>
    </el-dialog>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { getUserProfile, senduserinfo, updateUserPhoto } from '@/api/user'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      user: [],
      userinfo: {
        name: '',
        company_name: '',
        sex: ''
      },
      sexs: '',
      headimgurl: '',
      dialogTableVisible: false,
      isshow: false,
      isimg: false,
      formRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      previewImage: '', // 预览图片
      cropper: null, // 裁切器示例
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false // 更新基本信息的 loading 状态
    }
  },
  created () {
    // 组件初始化
    this.loadUserProfile()
  },
  methods: {
    // 获取用户信息
    loadUserProfile () {
      getUserProfile().then(res => {
        this.user = res.data.data
        this.userinfo.name = res.data.data.name
        this.userinfo.company_name = res.data.data.company_name
        if (res.data.data.headimgurl === undefined || res.data.data.headimgurl <= 0) {
          this.headimgurl = 'https://66gangtie.oss-cn-shanghai.aliyuncs.com/web/uploads/20210106/1657895623edb24ea.jpg'
        } else {
          this.headimgurl = 'https://api.66gangtie.com/' + res.data.data.headimgurl
        }
        if (res.data.data.sex === '1') {
          this.sexs = '男'
          this.userinfo.sex = 1
        } else {
          this.sexs = '女'
          this.userinfo.sex = 2
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    editinfo () {
      this.isshow = true
    },
    saveinfo () {
      this.$refs['userinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      senduserinfo(this.userinfo).then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.sex === '1') {
            this.sexs = '男'
            this.userinfo.sex = 1
          } else {
            this.sexs = '女'
            this.userinfo.sex = 2
          }
          globalBus.$emit('personals', { status: 2, name: res.data.data.name })
          this.user = res.data.data
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.isshow = false
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    // 移入
    mouseOver () {
      // 操作dom 获取p标签改变其样式
      this.isimg = true
    },
    // 移出
    mouseLeave () {
      this.isimg = false
    },
    // 处于图片预览
    onFileChange () {
      const file = this.$refs.file
      const blobData = window.URL.createObjectURL(file.files[0])
      this.previewImage = blobData
      this.dialogVisible = true
      this.$refs.file.value = ''
    },
    // 对话框关闭，销毁裁切器
    onDialogClosed () {
      // this.cropper.destroy()
      this.updatePhotoLoading = false
    },
    // 打开图片剪辑实例
    onDialogOpened () {
      const image = this.$refs['preview-image']
      if (this.cropper) {
        this.cropper.replace(this.previewImage)
        return false
      }
      this.cropper = new Cropper(image, {
        viewMode: 1,
        dragMode: 'none',
        aspectRatio: 1,
        cropBoxResizable: false
      })
    },
    // 上传头像
    onUpdatePhoto () {
      this.updatePhotoLoading = true
      // 获取裁切的图片对象
      this.cropper.getCroppedCanvas().toBlob(file => {
        const fd = new FormData()
        fd.append('headImgUrl', file)
        // 请求更新用户头像请求提交 fd
        this.blobToBase64(file).then(res => {
          // 转化后的base64
          console.log(res)
          updateUserPhoto({ headImgUrl: res }).then(res => {
            // console.log(res.data)
            // 关闭对话框
            this.dialogVisible = false
            // 更新视图展示
            // 直接把裁切结果的文件对象转为 blob 数据本地预览
            this.headimgurl = window.URL.createObjectURL(file)
            // 关闭确定按钮的 loading
            this.updatePhotoLoading = false
            this.$message({
              type: 'success',
              message: '更新头像成功'
            })
            // 更新顶部登录用户的信息
            globalBus.$emit('personals', { status: 1, headimgurl: this.headimgurl })
            // 把服务端返回的图片进行展示有点慢
            // this.user.photo = res.data.data.photo
          })
        })
      })
    },
    // 将blob转为base64
    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          resolve(e.target.result)
        }
        // readAsDataURL
        fileReader.readAsDataURL(blob)
        fileReader.onerror = () => {
          reject(new Error('文件流异常'))
        }
      })
    },
    about () {
      this.dialogTableVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #f2f2f2;
}
.bgs {
  background-color: #fff;
  border-radius: 2px;
  min-height: 604px;
}
.mr15 {
  margin-right: 15px;
}
.mt15 {
  margin-top: 15px;
}
.zls {
  text-align: center;
  height: 35px;
  line-height: 35px;
  width: 100%;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .layui-container {
    width: 100%;
  }
}

.infoMsgHead {
  height: 150px;
  width: 100%;
  background: url(../../assets/images/banner@2x.23abf20.png) no-repeat;
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  background-size: 100% 100%;
  min-width: 1200px;
}
.user-left {
  float: left;
  padding: 35px 48px 0 40px;
}
.user-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px solid #464646;
}
.user-img img {
  width: 100%;
  height: 100%;
}
.user-info {
  float: left;
  padding-top: 40px;
  color: #fff;
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 24px;
}
.user-info span {
  display: inline-block;
  line-height: 32px;
  text-align: center;
  vertical-align: bottom;
  font-weight: 600;
  font-size: 16px;
}
.wealth-msg {
  height: 32px;
  margin-top: 17px;
}
.push-msg {
  padding: 0 16px;
  background: #bb9444;
  border-radius: 16px;
  margin-right: 13px;
  cursor: pointer;
}
.vip {
  width: 24px;
  height: 21px;
  margin-right: 8px;
  position: relative;
  top: -9px;
  background: url(../../assets/images/5f15827.png) no-repeat;
  background-position: -57px -13px;
  background-size: 240px 50px;
}
.vip-msg {
  color: #fff;
}
li {
  display: inline-block;
}
.aside:first-child {
  padding-top: 16px;
}
.aside li {
  min-height: 35px;
  width: 100%;
  line-height: 35px;
  font-size: 14px;
  color: #555666;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-weight: 400;
}
.aside li .zl {
  font-size: 14px;
  color: #555666;
  display: block;
  height: 35px;
  line-height: 35px;
  width: 128px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  border-radius: 17.5px;
  font-family: PingFangSC-Regular, PingFang SC !important;
}
.aside li.router-link-active .zl {
  background: #f6f7f8;
  color: #222226;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC !important;
}
.aside li .left_box_menu {
  width: 128px;
  line-height: 35px;
  font-size: 14px;
  color: #555666;
  margin: 0 auto;
}
.info-box {
  padding: 0 20px;
}
.info-box header {
  width: 100%;
  padding-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}
.info-box .left-title {
  color: #222226;
  font-size: 16px;
  font-weight: 600;
}
.info-box .left-title .t-icon {
  width: 16px;
  height: 14px;
  display: inline-block;
  background: url(../../assets/images/5f15827.png) no-repeat;
  background-size: 174px 36px;
  background-position: -96px -11px;
  cursor: pointer;
}
.info-box .line-box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  background: #e5e5e5;
  margin-top: 12px;
}
.info-box .right-btn {
  padding: 0 16px;
  text-align: center;
  border: 1px solid #108ee9;
  border-radius: 13px;
  color: #108ee9;
  cursor: pointer;
}
.info-box .left-title {
  color: #222226;
  font-size: 16px;
  font-weight: 600;
}
.contain .msg-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 14px 0;
  color: #555666;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.contain .msg-item .msg-key {
  width: 75px;
  text-align: right;
  float: left;
  word-break: break-all;
}
.contain .msg-item .msg-val {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 16px;
  word-break: break-all;
}
/deep/.el-input {
  width: 50%;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0px;
}
/deep/.el-collapse {
  margin-bottom: 10px;
}
.addCb {
  width: 100px;
  background: #909399;
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}
.addCbb {
  width: 100px;
  background: #67c23a;
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}
.date span {
  font-size: 18px;
  font-weight: 500;
  color: #00be6e;
  vertical-align: middle;
  margin-right: 5px;
}

.right-act {
  background: #108ee9;
  color: #fff !important;
}
.user-img p {
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.8);
}
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
  }
}
.copyright-box {
  width: 100%;
  background: #ffffff;
  margin-top: 15px;
}
.column {
  margin: 0 auto;
  -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  padding: 24px 34px 20px;
}
.column .footer-column-t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.column .footer-column-t li {
  margin: 0 8px;
  color: #999aaa;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column a {
  text-decoration: none;
  color: inherit;
}
.column .footer-column-b {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.column .footer-column-b li {
  font-size: 12px;
  color: #999aaa;
  height: 16px;
  line-height: 16px;
  margin: 4px 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.column .footer-column-b li img {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 4px;
}
.about {
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
}
</style>
