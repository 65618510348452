<template>
  <div class="realtimeprice">
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf">
          <div class="widget-head fl">
            <div class="widget-title fl">期现基差</div>
            <div class="fr fr-title">
              <div class="fl pr20"></div>
            </div>
          </div>
          <div class="widget-body am-cf">
            <el-tabs type="card"
                     v-model="activeNames"
                     @tab-click="handleClick">
              <el-tab-pane label="期现基差数据表"
                           name="first"></el-tab-pane>
              <el-tab-pane label="期现基差走势图"
                           name="second"></el-tab-pane>
            </el-tabs>
            <div class="currcont"
                 :style="currcontdisplay">
              <p>期螺虚拟成本=铁矿收盘价*1.6+焦炭收盘价*0.5+1050
                <span style="padding-left:20px ;">盘面利润=螺纹收盘价-期螺虚拟成本</span>
              </p>
              <el-table :data="currentbasisdata"
                        v-loading="loading"
                        border
                        :row-class-name="tableRowClassName"
                        height="calc(100vh - 265px)"
                        highlight-current-row
                        style="width: 100%"
                        :cell-style="columnStyle">
                <el-table-column prop="date"
                                 sortable
                                 label="日期"></el-table-column>
                <el-table-column label="螺纹"
                                 header-align="center"
                                 align="center">
                  <el-table-column prop="spotprice"
                                   sortable
                                   label="螺纹现货价"></el-table-column>
                  <el-table-column prop="threadclosingprice"
                                   sortable
                                   label="螺纹收盘价"></el-table-column>
                  <el-table-column prop="currentbasis"
                                   sortable
                                   label="期现基差"></el-table-column>
                  <el-table-column prop="hotcoilclosingprice"
                                   sortable
                                   label="热卷收盘价"></el-table-column>
                  <el-table-column prop="oncomelaniabasis"
                                   sortable
                                   label="卷螺基差"></el-table-column>
                  <el-table-column prop="threadhighest"
                                   sortable
                                   label="螺纹最高"></el-table-column>
                  <el-table-column prop="threadlowest"
                                   sortable
                                   label="螺纹最低"></el-table-column>
                  <el-table-column prop="range"
                                   sortable
                                   label="波动值"></el-table-column>
                </el-table-column>
                <el-table-column label="相关黑色金属"
                                 header-align="center"
                                 align="center">
                  <el-table-column prop="ironoreclosingprice"
                                   sortable
                                   label="铁矿收盘价"></el-table-column>
                  <el-table-column prop="cokingcoalclosingprice"
                                   sortable
                                   label="焦煤收盘价"></el-table-column>
                  <el-table-column prop="cokeclosingprice"
                                   sortable
                                   label="焦炭收盘价"></el-table-column>
                  <el-table-column prop="virtualcost"
                                   sortable
                                   label="期螺虚拟成本"></el-table-column>
                  <el-table-column prop="diskprofit"
                                   sortable
                                   label="盘面利润"></el-table-column>
                </el-table-column>
              </el-table>
            </div>
            <div class="currChart"
                 :style="Chartdisplay">
              <div ref="myChart"
                   class="myChart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>

          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i></div>
  </div>
</template>

<script>
import { getUserNotice } from '@/api/global'
import Currentbasisc from '@/components/Currentbasisc'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      activeName: '1',
      curTabcompnents: {
        1: 'Currentbasisc'
      },
      activeNames: 'first',
      currcontdisplay: 'display:block',
      Chartdisplay: 'display:none',
      loading: true,
      updateLoading: false,
      currentbasisdata: [],
      dateBetween: [],
      spotpricemin: '',
      currentbasismax: '',
      currentData: [
        {
          name: '螺纹现货价',
          data: [],
          type: 'line',
          smooth: true,
          smoothMonotone: 'x',
          cursor: 'pointer',
          showSymbol: false,
          yAxisIndex: 0,
          lineStyle: {
            shadowColor: 'rgba(18,61,172,0.5)',
            shadowBlur: 10
          }
        },
        {
          name: '螺纹收盘价',
          data: [],
          type: 'line',
          smooth: true,
          smoothMonotone: 'x',
          cursor: 'pointer',
          showSymbol: false,
          yAxisIndex: 0,
          lineStyle: {
            shadowColor: 'rgba(115,226,226,0.5)',
            shadowBlur: 10
          }
        },
        {
          name: '期现基差',
          data: [],
          type: 'line',
          smooth: true,
          smoothMonotone: 'x',
          cursor: 'pointer',
          showSymbol: false,
          yAxisIndex: 1,
          lineStyle: {
            shadowColor: 'rgba(255,126,133,0.5)',
            shadowBlur: 10
          }
        }
      ],
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      currentbasis_min_max: [],
      price_min_max: [],
      topnotice: '',
      isstatus: 0
    }
  },
  components: { Currentbasisc },
  created () {
    // 注册自定义事件
    globalBus.$on('currentBasisData', (res) => {
      this.loading = false
      this.currentbasisdata = res.data
      this.dateBetween = res.date
      this.spotpricemin = res.spotpricemin
      this.price_min_max = res.price_min_max
      this.currentbasis_min_max = res.currentbasis_min_max
      this.getChartSpotprice(res.data)
      this.getChartThreadclosingprice(res.data)
      this.getChartCurrentbasis(res.data)
      this.drawLine()
    })
  },
  mounted () {
    this.loadgetUserNotice()
    // 初始化数据
    setTimeout(() => {
      this.drawLine()
    }, 1000)
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top:92px'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    },
    columnStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 4 || columnIndex === 6 || columnIndex === 7) {
        return 'background:rgba(225, 243, 216,0.3);'
      }
      if (columnIndex === 8) {
        return 'background:rgba(253, 226, 226,0.3);'
      }
      if (columnIndex === 3 || columnIndex === 5) {
        return 'background:rgba(192, 196, 204,0.3);'
      }
      if (columnIndex === 9 || columnIndex === 10 || columnIndex === 11 || columnIndex === 12 || columnIndex === 13) {
        return 'background:rgba(217, 236, 255,0.3);'
      }
    },
    drawLine () {
      var echarts = require('echarts')
      var myChart = echarts.init(this.$refs.myChart)
      const xAxisData = this.dateBetween
      const option = {
        textStyle: {
          fontFamily: 'Din-Light'
        },
        color: ['#123dac', '#73e2e2', '#ff7e85', '#9b52ff', '#fac524', '#46caff', '#a1e867', '#10b2b2', '#ec87f7', '#f4905a', '#00baba', '#facf24', '#e89d67', '#23c6c6', '#fa8699', '#40b7fc', '#006d75', '#595959', '#f4764f', '#a640fc', '#fda23f', '#2d7ae4', '#5092ff', '#9351ed', '#8a89fe', '#df89e8', '#2797ff', '#6ad089', '#7c92e8'],
        title: {
          text: '',
          left: '47%',
          textStyle: {
            fontSize: 32
          }
        },
        legend: {
          data: [
            {
              name: '螺纹现货价'
            },
            {
              name: '螺纹收盘价'
            },
            {
              name: '期现基差'
            }
          ],
          selected: {
            螺纹现货价: true,
            螺纹收盘价: true,
            期现基差: true
          },
          itemWidth: 20,
          itemHeight: 10,
          itemGap: 10,
          textStyle: {
            color: '#898989',
            lineHeight: 15
          },
          type: 'scroll'
        },
        // 工具栏，内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        toolbox: {
          show: true,
          // toolbox的配置项
          feature: {
            // 辅助线的开关
            mark: { show: true },
            // 数据视图
            dataView: {
              show: false,
              readOnly: false
            },
            // 动态类型切换
            magicType: {
              show: true,
              // line为折线图，bar为柱状图
              type: ['line', 'bar']
            },
            // 配置项还原
            restore: { show: false },
            // 将图标保存为图片
            saveAsImage: { show: true }
          }
        },
        // 可计算特性
        calculable: true,
        tooltip: {
          backgroundColor: '#fff',
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          textStyle: {
            color: '#565656',
            lineHeight: 28
          },
          confine: true,
          padding: 12,
          extraCssText:
            'box-shadow: 0px 2px 8px 0px #cacaca;border-radius: 4px;opacity: 0.9;max-height: 100%;',
          formatter: {
            _custom: {
              type: 'function',
              display: '<span>ƒ</span> (params)'
            }
          }
        },
        grid: {
          left: '3%',
          right: '2%',
          top: 100,
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLabel: {
            color: '#2a2a2a'
            // X轴标签 label 做了特殊处理，防止左右溢出
            // formatter: (value, index) => {
            // if (index === 0 || index === xAxisData.length - 1) {
            // return ''
            // }
            // return value
            // },
            // interval：0 //横轴信息全部显示
          },
          axisLine: {
            lineStyle: {
              color: '#2a2a2a'
            }
          }
        },
        yAxis: [
          {
            name: '螺纹期货/现货',
            nameTextStyle: {
              color: '#2a2a2a'
            },
            type: 'value',
            scale: true,
            max: this.price_min_max[1] + 100,
            min: this.price_min_max[0] - 1000,
            splitNumber: 10,
            boundaryGap: [0, 0.6],
            axisLabel: {
              color: '#2a2a2a',
              inside: true,
              margin: 0,
              verticalAlign: 'bottom'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }, {
            name: '期现基差',
            nameTextStyle: {
              color: '#2a2a2a'
            },
            type: 'value',
            scale: true,
            max: this.currentbasis_min_max[1] + 500,
            min: this.currentbasis_min_max[0] - 50,
            splitNumber: 10,
            boundaryGap: [0, 0.6],
            axisLabel: {
              color: '#2a2a2a',
              inside: true,
              margin: 0,
              verticalAlign: 'bottom'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                width: 2,
                color: 'rgba(255,126,133,0.5)'
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: this.currentData
      }
      myChart.setOption(option)
      // 监听点击事件
      window.addEventListener('click', function () {
        myChart.resize()
      })
      // 监听浏览器页面缩放事件
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 获取统计图现货数据
    getChartSpotprice (marketData) {
      const marketSend = this.currentData[0].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].spotprice.length > 0) {
            marketSend[i] = marketData[j].spotprice
          }
        }
      }
      return marketSend
    },
    // 获取统计图期货数据
    getChartThreadclosingprice (marketData) {
      const marketSend = this.currentData[1].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].threadclosingprice.length > 0) {
            marketSend[i] = marketData[j].threadclosingprice
          }
        }
      }
      return marketSend
    },
    // 获取统计图期现基差数据
    getChartCurrentbasis (marketData) {
      const marketSend = this.currentData[2].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].currentbasis.length > 0) {
            marketSend[i] = marketData[j].currentbasis
          }
        }
      }
      return marketSend
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    handleClick (tab, event) {
      if (tab.label === '期现基差数据表') {
        this.currcontdisplay = 'display:block'
        this.Chartdisplay = 'display:none'
      }
      if (tab.label === '期现基差走势图') {
        this.currcontdisplay = 'display:none'
        this.Chartdisplay = 'display:block'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
}
.layui-side {
  margin-right: 20px;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}
.layui-side-content {
  padding: 20px 0px;
}

/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
#selectBox {
  position: relative;
  width: 450px;
  height: auto;
  padding: 10px;
  background-color: #409eff;
  color: #fff;
}
#selectBox p {
  margin-top: 20px;
}
#selectBox .el-checkbox {
  color: #fff;
}
.fixWidth {
  display: inline-block;
  width: 90px;
  margin-left: 50px;
}
.fixBox {
  font-size: 14px;
  position: absolute;
  right: 40px;
  top: 200px;
  z-index: 2;
}
#offBox {
  position: absolute;
  right: -40px;
  top: 0;
  display: inline-block;
  width: 20px;
  height: auto;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: #409eff;
}
#onBox {
  position: absolute;
  left: 0;
  top: 25px;
  display: inline-block;
  width: 20px;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  height: auto;
  color: #fff;
  background-color: #409eff;
}
.inline_box {
  display: inline-block;
  width: 40%;
  margin-left: 20px;
}
.same_color {
  color: #f56c6c;
}
table {
  margin-top: 20px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e0e6ed;
  text-align: center;
  font-size: 14px;
}
thead {
  background-color: #eef1f6;
}
thead th {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
table td {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e0e6ed;
  border-right: 1px solid #e0e6ed;
}
table tbody tr:hover {
  background-color: #eff2f7;
}
.sort-icon {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  font-size: 12px;
  color: #97a8be;
}
.sort-icon > div {
  vertical-align: middle;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.addr_box {
  padding: 10px 15px;
  background-color: #fff;
}
.price_box {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #fff;
}
.button_box {
  text-align: center;
}
.button_box button {
  width: 80px;
  text-align: center;
  padding: 8px;
  margin: 5px;
}
.red_font {
  color: #aa1010;
}
.green_font {
  color: #10aa10;
}
.yellow_font {
  color: #aaaa10;
}
.sm_width {
  width: 80px;
}
.top_style {
  font-weight: bold;
  width: 99.9%;
  border: 1px solid #dfe6ec;
  display: inline-block;
  background: #eef1f6;
  margin-bottom: 0px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #656f82;
  opacity: 0.8;
  border-left: none;
}

.overflow_box {
  min-width: 1000px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.overflow_box table {
  min-width: 100%;
  width: auto;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.adds_css {
  width: 60%;
  display: inline-block;
}
.empty_css {
  display: inline-block;
}
.table_box {
  margin-top: 20px;
  clear: both;
}
.same_table {
  background: #fff;
  padding: 15px;
  width: 100%;
  margin-top: 20px;
}
.table_box tr {
  width: 100%;
}
.table_box th,
td {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
.table_box th {
  width: 15%;
}
.table_box tr:hover {
  background-color: #e0e6ed;
}
.img_style {
  display: inline-block;
  width: 100px;
  height: auto;
  cursor: pointer;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 500px;
  margin: 0;
  position: relative;
}

.el-carousel__item:nth-child(2n) {
  background-color: #f2f2f2;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #f2f2f2;
}
/deep/ .el-carousel--horizontal {
  overflow-x: visible;
  margin: 100px 20px 0 20px;
}
.content {
  line-height: 32px;
  font-size: 16px;
  min-height: 812px;
}
.title {
  padding: 20px 0;
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table thead.is-group th {
  background: #707880;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: 500;
}
.myChart {
  height: calc(100vh - 252px);
  width: 100%;
  margin-top: 20px;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}
@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 9999;
  }
  .myChart {
    height: calc(100vh - 265px);
    width: 100%;
    margin-top: 20px;
  }
}
.charttitle {
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
}
.layui-tab {
  margin-top: 80px;
}
.layui-tab-title li {
  font-size: 14px;
}
.layui-tab-brief > .layui-tab-title .layui-this {
  color: #1e9fff;
  font-family: '微软雅黑';
  font-size: 14px;
}
.currcont {
  margin-top: 0px;
}
.currChart {
  margin-top: 0px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/ .el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
</style>
