<template>
  <div>
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf ">
          <div class="widget-head fl">
            <div class="widget-title fl"
                 id="recommendname">资源比价</div>
          </div>
          <div class="widget-body am-cf">

            <div class="am-cf"></div>
            <el-tabs type="card"
                     v-model="activeNames"
                     @tab-click="handleClick">
              <el-tab-pane label="螺纹钢 / 18-25E"
                           name="first"></el-tab-pane>
              <el-tab-pane label="盘螺 / 8-10E"
                           name="second"></el-tab-pane>
              <el-tab-pane label="高线 / 8-10"
                           name="three"></el-tab-pane>
            </el-tabs>
            <div class="xin">
              <div class="fl pr20">参考价 : {{webarea}}</div>
              <div class="fl pr20">日期 : {{StartDate}} 至 {{EndDate}}</div>
              <div class="fl pr20">单位:元/吨</div>
            </div>
            <div class="currcont"
                 :style="luowengangdisplay">
              <el-table :data="luowengangData"
                        v-loading="loading"
                        border
                        :row-class-name="tableRowClassName"
                        height="calc(100vh - 260px)"
                        highlight-current-row
                        style="width: 100%">
                <el-table-column prop="brand"
                                 label="品牌">
                </el-table-column>
                <el-table-column prop="result_price_avg"
                                 sortable
                                 label="平均到货价">
                </el-table-column>
                <el-table-column prop="web_price_avg"
                                 sortable
                                 label="平均参考价">
                </el-table-column>
                <el-table-column prop="diff_price_avg"
                                 sortable
                                 label="平均价差">
                </el-table-column>
                <el-table-column prop="newest_result_price"
                                 sortable
                                 label="最新到货价">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_result_price}}</span> <span v-html="scope.row.newest_result_price_float"></span></div>
                  </template>
                </el-table-column>
                <el-table-column prop="newest_web_price"
                                 sortable
                                 label="最新参考价">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_web_price}}</span> <span v-html="scope.row.newest_web_price_float"></span></div>
                  </template>
                </el-table-column>
                <el-table-column prop="newest_diff_price"
                                 sortable
                                 label="最新价差">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_diff_price}}</span> <span v-html="scope.row.newest_diff_price_float"></span></div>
                  </template>
                </el-table-column>
              </el-table>
              <p style=" padding-top:10px">注：1.因个别日期现货市场缺货，此处可能存在“平均价差≠平均参考价-平均到货价”的偏差，敬请留意。<span class="cred">2.“最新”表示 {{newest_price_time}} 截取的数据，“平均”表示所选时间段内的平均数。</span></p>
            </div>
            <div class="currChart"
                 :style="panluodisplay">
              <el-table :data="panluoData"
                        v-loading="loading"
                        border
                        :row-class-name="tableRowClassName"
                        height="calc(100vh - 260px)"
                        highlight-current-row
                        style="width: 100%">
                <el-table-column prop="brand"
                                 label="品牌">
                </el-table-column>
                <el-table-column prop="result_price_avg"
                                 sortable
                                 label="平均到货价">
                </el-table-column>
                <el-table-column prop="web_price_avg"
                                 sortable
                                 label="平均参考价">
                </el-table-column>
                <el-table-column prop="diff_price_avg"
                                 sortable
                                 label="平均价差">
                </el-table-column>
                <el-table-column prop="newest_result_price"
                                 sortable
                                 label="最新到货价">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_result_price}}</span> <span v-html="scope.row.newest_result_price_float"></span></div>
                  </template>
                </el-table-column>
                <el-table-column prop="newest_web_price"
                                 sortable
                                 label="最新参考价">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_web_price}}</span> <span v-html="scope.row.newest_web_price_float"></span></div>
                  </template>
                </el-table-column>
                <el-table-column prop="newest_diff_price"
                                 sortable
                                 label="最新价差">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_diff_price}}</span> <span v-html="scope.row.newest_diff_price_float"></span></div>
                  </template>
                </el-table-column>
              </el-table>
              <p style=" padding-top:10px">注：1.因个别日期现货市场缺货，此处可能存在“平均价差≠平均参考价-平均到货价”的偏差，敬请留意。<span class="cred">2.“最新”表示 {{newest_price_time}} 截取的数据，“平均”表示所选时间段内的平均数。</span></p>
            </div>
            <div class="currChart"
                 :style="gaoxiandisplay">
              <el-table :data="gaoxianData"
                        v-loading="loading"
                        border
                        :row-class-name="tableRowClassName"
                        height="calc(100vh - 260px)"
                        highlight-current-row
                        style="width: 100%">
                <el-table-column prop="brand"
                                 label="品牌">
                </el-table-column>
                <el-table-column prop="result_price_avg"
                                 sortable
                                 label="平均到货价">
                </el-table-column>
                <el-table-column prop="web_price_avg"
                                 sortable
                                 label="平均参考价">
                </el-table-column>
                <el-table-column prop="diff_price_avg"
                                 sortable
                                 label="平均价差">
                </el-table-column>
                <el-table-column prop="newest_result_price"
                                 sortable
                                 label="最新到货价">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_result_price}}</span> <span v-html="scope.row.newest_result_price_float"></span></div>
                  </template>
                </el-table-column>
                <el-table-column prop="newest_web_price"
                                 sortable
                                 label="最新参考价">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_web_price}}</span> <span v-html="scope.row.newest_web_price_float"></span></div>
                  </template>
                </el-table-column>
                <el-table-column prop="newest_diff_price"
                                 sortable
                                 label="最新价差">
                  <template slot-scope="scope">
                    <div><span>{{scope.row.newest_diff_price}}</span> <span v-html="scope.row.newest_diff_price_float"></span></div>
                  </template>
                </el-table-column>
              </el-table>
              <p style=" padding-top:10px">注：1.因个别日期现货市场缺货，此处可能存在“平均价差≠平均参考价-平均到货价”的偏差，敬请留意。<span class="cred">2.“最新”表示 {{newest_price_time}} 截取的数据，“平均”表示所选时间段内的平均数。</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               id="guide-menu"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i></div>
  </div>
</template>

<script>
import { getUserNotice, getWebArea } from '@/api/global'
import Recommend from '@/components/Recommend'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      activeName: '1',
      curTabcompnents: {
        1: 'Recommend'
      },
      StartDate: '',
      EndDate: '',
      newest_price_time: '',
      loading: true,
      luowengangData: [],
      panluoData: [],
      gaoxianData: [],
      activeNames: 'first',
      luowengangdisplay: 'display:block',
      panluodisplay: 'display:none',
      gaoxiandisplay: 'display:none',
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      topnotice: '',
      isstatus: 0,
      webarea: ''
    }
  },
  components: { Recommend },
  created () {
    // 注册自定义事件
    globalBus.$on('recommendstatus', (res) => {
      if (res.status === 1) {
        this.luowengangData = []
        this.panluoData = []
        this.gaoxianData = []
      }
    })
    globalBus.$on('recommend', (res) => {
      this.loading = false
      this.newest_price_time = res.newest_price_time
      this.luowengangData = res.luowengang_data.map(val => {
        if (val.newest_result_price_float > 0) {
          val.newest_result_price_float = '<span class="red_font">↑' + val.newest_result_price_float + '</span>'
        }
        if (val.newest_result_price_float < 0) {
          val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
        }
        if (val.newest_result_price_float === 0) {
          val.newest_result_price_float = ''
        }
        if (val.newest_web_price_float > 0) {
          val.newest_web_price_float = '<span class="red_font">↑' + val.newest_web_price_float + '</span>'
        }
        if (val.newest_web_price_float < 0) {
          val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
        }
        if (val.newest_web_price_float === '-') {
          val.newest_web_price_float = '<span class="yellow_font">-平</span>'
        }
        if (val.newest_diff_price_float > 0) {
          val.newest_diff_price_float = '<span class="red_font">↑' + val.newest_diff_price_float + '</span>'
        }
        if (val.newest_diff_price_float < 0) {
          val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
        }
        if (val.newest_diff_price_float === 0) {
          val.newest_diff_price_float = ''
        }
        return val
      })

      this.panluoData = res.panluo_data.map(val => {
        if (val.newest_result_price_float > 0) {
          val.newest_result_price_float = '<span class="red_font">↑' + val.newest_result_price_float + '</span>'
        }
        if (val.newest_result_price_float < 0) {
          val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
        }
        if (val.newest_result_price_float === 0) {
          val.newest_result_price_float = ''
        }
        if (val.newest_web_price_float > 0) {
          val.newest_web_price_float = '<span class="red_font">↑' + val.newest_web_price_float + '</span>'
        }
        if (val.newest_web_price_float < 0) {
          val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
        }
        if (val.newest_web_price_float === '-') {
          val.newest_web_price_float = '<span class="yellow_font">-平</span>'
        }
        if (val.newest_diff_price_float > 0) {
          val.newest_diff_price_float = '<span class="red_font">↑' + val.newest_diff_price_float + '</span>'
        }
        if (val.newest_diff_price_float < 0) {
          val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
        }
        if (val.newest_diff_price_float === 0) {
          val.newest_diff_price_float = ''
        }
        return val
      })
      this.gaoxianData = res.gaoxian_data.map(val => {
        if (val.newest_result_price_float > 0) {
          val.newest_result_price_float = '<span class="red_font">↑' + val.newest_result_price_float + '</span>'
        }
        if (val.newest_result_price_float < 0) {
          val.newest_result_price_float = '<span class="green_font">↓' + Math.abs(val.newest_result_price_float) + '</span>'
        }
        if (val.newest_result_price_float === 0) {
          val.newest_result_price_float = ''
        }
        if (val.newest_web_price_float > 0) {
          val.newest_web_price_float = '<span class="red_font">↑' + val.newest_web_price_float + '</span>'
        }
        if (val.newest_web_price_float < 0) {
          val.newest_web_price_float = '<span class="green_font">↓' + Math.abs(val.newest_web_price_float) + '</span>'
        }
        if (val.newest_web_price_float === '-') {
          val.newest_web_price_float = '<span class="yellow_font">-平</span>'
        }
        if (val.newest_diff_price_float > 0) {
          val.newest_diff_price_float = '<span class="red_font">↑' + val.newest_diff_price_float + '</span>'
        }
        if (val.newest_diff_price_float < 0) {
          val.newest_diff_price_float = '<span class="green_font">↓' + Math.abs(val.newest_diff_price_float) + '</span>'
        }
        if (val.newest_diff_price_float === 0) {
          val.newest_diff_price_float = ''
        }
        return val
      })
      this.StartDate = res.StartDate
      this.EndDate = res.EndDate
    })
  },
  mounted () {
    this.loadgetWebArea()
    this.loadgetUserNotice()
  },
  methods: {
    loadgetWebArea () {
      getWebArea().then(res => {
        if (res.data.status === 'success') {
          this.webarea = res.data.area
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top: 92px;'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    handleClick (tab, event) {
      if (tab.label === '螺纹钢 / 18-25E') {
        this.luowengangdisplay = 'display:block'
        this.panluodisplay = 'display:none'
        this.gaoxiandisplay = 'display:none'
      }
      if (tab.label === '盘螺 / 8-10E') {
        this.luowengangdisplay = 'display:none'
        this.panluodisplay = 'display:block'
        this.gaoxiandisplay = 'display:none'
      }
      if (tab.label === '高线 / 8-10') {
        this.luowengangdisplay = 'display:none'
        this.panluodisplay = 'display:none'
        this.gaoxiandisplay = 'display:block'
      }
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
}
.layui-side {
  margin-right: 20px;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}
.layui-side-content {
  padding: 20px 0px;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}
@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 9999;
  }
}
/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
#selectBox {
  position: relative;
  width: 450px;
  height: auto;
  padding: 10px;
  background-color: #409eff;
  color: #fff;
}
#selectBox p {
  margin-top: 20px;
}
#selectBox .el-checkbox {
  color: #fff;
}
.fixWidth {
  display: inline-block;
  width: 90px;
  margin-left: 50px;
}
.fixBox {
  font-size: 14px;
  position: absolute;
  right: 40px;
  top: 200px;
  z-index: 2;
}
#offBox {
  position: absolute;
  right: -40px;
  top: 0;
  display: inline-block;
  width: 20px;
  height: auto;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: #409eff;
}
#onBox {
  position: absolute;
  left: 0;
  top: 25px;
  display: inline-block;
  width: 20px;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  height: auto;
  color: #fff;
  background-color: #409eff;
}
.inline_box {
  display: inline-block;
  width: 40%;
  margin-left: 20px;
}
.same_color {
  color: #f56c6c;
}
table {
  margin-top: 20px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e0e6ed;
  text-align: center;
  font-size: 14px;
}
thead {
  background-color: #eef1f6;
}
thead th {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
table td {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e0e6ed;
  border-right: 1px solid #e0e6ed;
}
table tbody tr:hover {
  background-color: #eff2f7;
}
.sort-icon {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  font-size: 12px;
  color: #97a8be;
}
.sort-icon > div {
  vertical-align: middle;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.addr_box {
  padding: 10px 15px;
  background-color: #fff;
}
.price_box {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #fff;
}
.button_box {
  text-align: center;
}
.button_box button {
  width: 80px;
  text-align: center;
  padding: 8px;
  margin: 5px;
}
.red_font {
  color: #aa1010;
}
.green_font {
  color: #10aa10;
}
.yellow_font {
  color: #aaaa10;
}
.sm_width {
  width: 80px;
}
.top_style {
  font-weight: bold;
  width: 99.9%;
  border: 1px solid #dfe6ec;
  display: inline-block;
  background: #eef1f6;
  margin-bottom: 0px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #656f82;
  opacity: 0.8;
  border-left: none;
}

.overflow_box {
  min-width: 1000px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.overflow_box table {
  min-width: 100%;
  width: auto;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.adds_css {
  width: 60%;
  display: inline-block;
}
.empty_css {
  display: inline-block;
}
.table_box {
  margin-top: 20px;
  clear: both;
}
.same_table {
  background: #fff;
  padding: 15px;
  width: 100%;
  margin-top: 20px;
}
.table_box tr {
  width: 100%;
}
.table_box th,
td {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
.table_box th {
  width: 15%;
}
.table_box tr:hover {
  background-color: #e0e6ed;
}
.img_style {
  display: inline-block;
  width: 100px;
  height: auto;
  cursor: pointer;
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table th {
  background-color: #707880;
}
/deep/ .el-table thead {
  color: #ffffff;
  font-weight: bold;
}

/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}

.layui-tab {
  margin-top: 10px;
}
.layui-tab-title li {
  font-size: 14px;
}
.layui-tab-brief > .layui-tab-title .layui-this {
  color: #1e9fff;
  font-family: '微软雅黑';
  font-size: 14px;
}
.currcont {
  margin-top: 0px;
}
.currChart {
  margin-top: 0px;
}

/deep/ .el-table .cell .red_font {
  color: #aa1010;
}
/deep/ .el-table .cell .green_font {
  color: #10aa10;
}
/deep/ .el-table .cell .yellow_font {
  color: #aaaa10;
}
.xin {
  position: absolute;
  z-index: 9999;
  top: 75px;
  right: 15px;
  line-height: 25px;
  font-size: 14px;
}
/deep/ .el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/ .el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.title {
  font-size: 14px;
  font-weight: 600;
}
.cred {
  color: #e6781e;
}
</style>
