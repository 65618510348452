import Vue from 'vue'
import App from './App.vue'
import router from './router'
import echarts from 'echarts'
import './assets/css/layui.css'
import './assets/css/login.css'
import './assets/css/berapp.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import directives from './api/directives'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Viewer)
Vue.use(directives)
Vue.prototype.$echarts = echarts
Vue.use(Viewer)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
