<template>
  <div class="realtimeprice">
    <div class="layui-body"
         :style="layuibody">
      <div class="layadmin-tabsbody-item layui-show">
        <!-- 正文 -->
        <div class="widget am-cf">
          <div class="widget-head fl">
            <div class="widget-title fl">每周库存</div>
            <div class="fr fr-title">
              <div class="fl pr20">日期：</div>
            </div>
          </div>
          <div class="widget-body am-cf">
            <el-tabs type="card"
                     v-model="activeNames"
                     @tab-click="handleClick">
              <el-tab-pane label="每周库存数据表"
                           name="first"></el-tab-pane>
              <el-tab-pane label="每周库存走势图"
                           name="second"></el-tab-pane>
            </el-tabs>
            <div class="currChart">
              <div ref="myChart"
                   class="myChart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layui-side layui-side-menu"
         :style="layuiside">
      <div class="layui-side-scroll">
        <div class="layui-side-content">
          <div class="autopbn"
               v-if="isshrink==true">
            <i class="layui-icon layui-icon-shrink-right"
               @click="shrink"><span class="shrink">收起</span></i>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="筛选"
                         name="1"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class=" layui-sidemen"
         :style="topnotice"
         v-if="isshrink==false"> <i class="layui-icon layui-icon-spread-left"
         @click="opens"><span class="opens">展开</span></i></div>
  </div>
</template>

<script>
import { getUserNotice } from '@/api/global'
import Steelstorechart from '@/components/Steelstorechartc'
import globalBus from '@/utils/global-bus'
export default {
  data () {
    return {
      activeName: '1',
      activeNames: 'second',
      curTabcompnents: {
        1: 'Steelstorechart'
      },
      loading: true,
      updateLoading: false,
      dateBetween: [],
      min: '',
      steelstorelist: [{
        data: []
      }, {
        data: []
      }, {
        data: []
      }, {
        data: []
      }],
      isshrink: true,
      layuibody: '',
      layuiside: 'display:block;',
      topnotice: '',
      isstatus: 0
    }
  },
  components: { Steelstorechart },
  created () {
    // 注册自定义事件
    globalBus.$on('Steelstorechart', (res) => {
      if (res.data.length === 0) {
        this.$message.error('日期范围内没有数据')
      }
      this.loading = false
      this.dateBetween = res.date
      this.min = res.warehousetotalmin
      this.getChartthreadstock(res.data)
      this.getChartsnailstock(res.data)
      this.getCharthighlinestock(res.data)
      this.getCharwarehousetotal(res.data)
      this.drawLine()
    })
  },
  mounted () {
    this.loadgetUserNotice()
    // 初始化数据
    setTimeout(() => {
      this.drawLine()
    }, 1000)
  },
  methods: {
    // 获取用户过期
    loadgetUserNotice () {
      getUserNotice().then(res => {
        if (res.data.status === 'success') {
          if (res.data.data.display === 1) {
            this.isstatus = 1
            this.layuibody = 'top:92px'
            this.layuiside = 'top:92px'
            this.topnotice = 'top:92px'
          } else {
            this.isstatus = 0
            this.layuibody = ''
            this.layuiside = ''
            this.topnotice = ''
          }
        }
      }).catch(err => {
        console.log('失获取数据败', err)
      })
    },
    // 展开
    opens () {
      this.isshrink = true
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px'
        this.layuiside = 'top:92px;display:block;'
      } else {
        this.layuibody = ''
        this.layuiside = 'display:block;'
      }
    },
    // 收起
    shrink () {
      this.isshrink = false
      if (this.isstatus === 1) {
        this.layuibody = 'top:92px;right: 40px;'
        this.layuiside = 'top:92px;display:none;'
      } else {
        this.layuibody = 'right: 40px;'
        this.layuiside = 'display:none;'
      }
    },
    drawLine () {
      var echarts = require('echarts')
      var myChart = echarts.init(this.$refs.myChart)
      const color = ['#3e6ec2', '#ec792b', '#a2a2a2', '#ffbe00']
      const xAxisData = this.dateBetween
      const option = {
        backgroundColor: '#fff',
        color: color,
        legend: {
          top: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let html = ''
            params.forEach(v => {
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                ${v.seriesName} ${v.name}  
                <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px;margin-left:5px">${v.value}</span>
                吨`
            })
            return html
          },
          extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;'
          // axisPointer: {
          //  type: 'shadow',
          //  shadowStyle: {
          //    color: '#ffffff',
          //    shadowColor: 'rgba(225,225,225,1)',
          //   shadowBlur: 5
          // }
          // }
        },
        // 工具栏，内置有导出图片、数据视图、动态类型切换、数据区域缩放、重置五个工具。
        toolbox: {
          show: true,
          // toolbox的配置项
          feature: {
            // 辅助线的开关
            mark: { show: true },
            // 数据视图
            dataView: {
              show: false,
              readOnly: false
            },
            // 动态类型切换
            magicType: {
              show: true,
              // line为折线图，bar为柱状图
              type: ['line', 'bar']
            },
            // 配置项还原
            restore: { show: false },
            // 将图标保存为图片
            saveAsImage: { show: true }
          }
        },
        // 可计算特性
        calculable: true,
        grid: {
          left: '3%',
          right: '2%',
          top: 100,
          bottom: '8%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#2a2a2a'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#2a2a2a'
            }
          },
          data: xAxisData
        }],
        yAxis: [{
          type: 'value',
          name: '单位：吨',
          // min: this.min,
          // splitNumber: 8,
          // boundaryGap: [0.2, 0.2],
          axisLabel: {
            textStyle: {
              color: '#2a2a2a'
            }
          },
          nameTextStyle: {
            color: '#2a2a2a',
            fontSize: 12,
            lineHeight: 40
          },
          // 分割线
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E9E9E9'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '螺纹库存',
          type: 'line',
          smooth: true,
          symbolSize: 8,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式
          data: this.steelstorelist[0].data
        }, {
          name: '盘螺库存',
          type: 'line',
          smooth: true,
          symbolSize: 8,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式 (实心点)
          data: this.steelstorelist[1].data
        }, {
          name: '高线库存',
          type: 'line',
          smooth: true,
          symbolSize: 8,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式 (实心点)
          data: this.steelstorelist[2].data
        }, {
          name: '合计',
          type: 'line',
          smooth: true,
          symbolSize: 8,
          zlevel: 3,
          symbol: 'none', // 数据交叉点样式 (实心点)
          data: this.steelstorelist[3].data
        }]
      }
      myChart.setOption(option)
      // 监听点击事件
      window.addEventListener('click', function () {
        myChart.resize()
      })
      // 监听浏览器页面缩放事件
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // 获取统计图螺纹库存数据
    getChartthreadstock (marketData) {
      const marketSend = this.steelstorelist[0].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].week_threadstocktotal.length > 0) {
            marketSend[i].push(parseInt(marketData[j].week_threadstocktotal))
            // marketSend[i].push(marketData[j].threadstock)
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = parseInt(sum / (marketSend[i].length))
        // marketSend[i] = sum / (marketSend[i].length)
      }
      return marketSend
    },
    // 获取统计图盘螺库存数据
    getChartsnailstock (marketData) {
      const marketSend = this.steelstorelist[1].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].week_snailstocktotal.length > 0) {
            marketSend[i].push(parseInt(marketData[j].week_snailstocktotal))
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = parseInt(sum / (marketSend[i].length))
      }
      return marketSend
    },
    // 获取统计图高线库存数据
    getCharthighlinestock (marketData) {
      const marketSend = this.steelstorelist[2].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].week_highlinestocktotal.length > 0) {
            marketSend[i].push(parseInt(marketData[j].week_highlinestocktotal))
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = parseInt(sum / (marketSend[i].length))
      }
      return marketSend
    },
    // 获取统计图单仓合计数据
    getCharwarehousetotal (marketData) {
      const marketSend = this.steelstorelist[3].data = []
      for (var i = 0; i < this.dateBetween.length; i++) {
        marketSend.push([])
        for (var j = 0; j < marketData.length; j++) {
          if (this.dateBetween[i] === marketData[j].date && marketData[j].week_warehousetotal.length > 0) {
            marketSend[i].push(parseInt(marketData[j].week_warehousetotal))
          }
        }
      }
      for (i = 0; i < marketSend.length; i++) {
        var sum = 0
        for (j = 0; j < marketSend[i].length; j++) {
          sum += marketSend[i][j]
        }
        marketSend[i] = parseInt(sum / (marketSend[i].length))
      }
      return marketSend
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    handleClick (tab, event) {
      if (tab.label === '每周库存数据表') {
        this.$router.push({
          name: 'Steelstore'
        })
      }
      if (tab.label === '每周库存走势图') {
        this.$router.push({
          name: 'Steelstorechart'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.layui-layout-admin .layui-body {
  position: fixed;
  top: 80px;
  bottom: 0px;
}
.layui-body {
  left: 20px;
  right: 490px;
}
.layui-side-scroll {
  width: 450px;
  height: 97.5%;
  background-color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
}
.layui-side {
  margin-right: 20px;
}
.layui-layout-admin .layui-side {
  top: 80px;
  width: 450px;
  overflow-x: hidden;
}

.layui-side-content {
  padding: 20px 0px;
}

/deep/.el-tabs__nav-wrap {
  padding-left: 20px;
}

.layui-table td {
  text-align: center;
}
.layui-table th {
  position: relative;
  padding: 9px 15px;
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

div::-webkit-scrollbar {
  width: 0;
  display: none;
}
div {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.active-column {
  color: #01c0c8;
  font-weight: 700;
}
.layui-form-onswitch {
  border-color: #0072d2;
  background-color: #0072d2;
}
.layui-form-checked[lay-skin='primary'] i {
  border-color: #0072d2 !important;
  background-color: #0072d2;
  color: #fff;
}
.pr20 {
  padding-right: 20px;
}
.layui-btn {
  height: 22px;
  line-height: 22px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
#selectBox {
  position: relative;
  width: 450px;
  height: auto;
  padding: 10px;
  background-color: #409eff;
  color: #fff;
}
#selectBox p {
  margin-top: 20px;
}
#selectBox .el-checkbox {
  color: #fff;
}
.fixWidth {
  display: inline-block;
  width: 90px;
  margin-left: 50px;
}
.fixBox {
  font-size: 14px;
  position: absolute;
  right: 40px;
  top: 200px;
  z-index: 2;
}
#offBox {
  position: absolute;
  right: -40px;
  top: 0;
  display: inline-block;
  width: 20px;
  height: auto;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: #409eff;
}
#onBox {
  position: absolute;
  left: 0;
  top: 25px;
  display: inline-block;
  width: 20px;
  border: none;
  padding: 20px 30px 10px 10px;
  text-align: center;
  font-size: 18px;
  height: auto;
  color: #fff;
  background-color: #409eff;
}
.inline_box {
  display: inline-block;
  width: 40%;
  margin-left: 20px;
}
.same_color {
  color: #f56c6c;
}
table {
  margin-top: 20px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e0e6ed;
  text-align: center;
  font-size: 14px;
}
thead {
  background-color: #eef1f6;
}
thead th {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
table td {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #e0e6ed;
  border-right: 1px solid #e0e6ed;
}
table tbody tr:hover {
  background-color: #eff2f7;
}
.sort-icon {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  font-size: 12px;
  color: #97a8be;
}
.sort-icon > div {
  vertical-align: middle;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.addr_box {
  padding: 10px 15px;
  background-color: #fff;
}
.price_box {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #fff;
}
.button_box {
  text-align: center;
}
.button_box button {
  width: 80px;
  text-align: center;
  padding: 8px;
  margin: 5px;
}
.red_font {
  color: #aa1010;
}
.green_font {
  color: #10aa10;
}
.yellow_font {
  color: #aaaa10;
}
.sm_width {
  width: 80px;
}
.top_style {
  font-weight: bold;
  width: 99.9%;
  border: 1px solid #dfe6ec;
  display: inline-block;
  background: #eef1f6;
  margin-bottom: 0px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #656f82;
  opacity: 0.8;
  border-left: none;
}

.overflow_box {
  min-width: 1000px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.overflow_box table {
  min-width: 100%;
  width: auto;
}
.sort-icon .same_top {
  color: rgb(103, 194, 58);
  margin-top: -5px;
}
.same_red {
  color: rgb(245, 108, 108);
}
.adds_css {
  width: 60%;
  display: inline-block;
}
.empty_css {
  display: inline-block;
}
.table_box {
  margin-top: 20px;
  clear: both;
}
.same_table {
  background: #fff;
  padding: 15px;
  width: 100%;
  margin-top: 20px;
}
.table_box tr {
  width: 100%;
}
.table_box th,
td {
  text-align: center;
  padding: 10px 0px;
  border: 1px solid #dfe6ec;
}
.table_box th {
  width: 15%;
}
.table_box tr:hover {
  background-color: #e0e6ed;
}
.img_style {
  display: inline-block;
  width: 100px;
  height: auto;
  cursor: pointer;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 500px;
  margin: 0;
  position: relative;
}

.el-carousel__item:nth-child(2n) {
  background-color: #f2f2f2;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #f2f2f2;
}
/deep/ .el-carousel--horizontal {
  overflow-x: visible;
  margin: 100px 20px 0 20px;
}
.content {
  line-height: 32px;
  font-size: 16px;
  min-height: 812px;
}
.title {
  padding: 20px 0;
}
/deep/.el-table th > .cell {
  text-align: center;
}
/deep/.el-table .cell {
  text-align: center;
}
/deep/.el-table th {
  background-color: #f2f6fc;
}
/deep/.el-table th {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
/deep/.el-table td {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
.myChart {
  height: calc(100vh - 260px);
  width: 100%;
  margin-top: 20px;
}
.layui-sidemen {
  position: fixed;
  top: 80px;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  right: 0;
  width: 30px;
}
.layui-icon-shrink-right {
  color: #e6781e;
}
.layui-icon-spread-left {
  color: #e6781e;
  padding-left: 5px;
}
.shrink {
  font-size: 12px;
  padding-left: 5px;
}
.opens {
  font-size: 10px;
  padding-left: 5px;
}
@media screen and (max-width: 1366px) {
  .layui-body {
    left: 20px;
    right: 390px;
  }
  .layui-side-scroll {
    width: 350px;
    height: 97.5%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: hidden;
  }
  .layui-layout-admin .layui-side {
    top: 80px;
    width: 350px;
    overflow-x: hidden;
  }
  .autopbn {
    position: relative;
    z-index: 9999;
  }
  .myChart {
    height: calc(100vh - 265px);
    width: 100%;
    margin-top: 20px;
  }
}
.charttitle {
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
}
.layui-tab {
  margin-top: 80px;
}
.layui-tab-title li {
  font-size: 14px;
}
.layui-tab-brief > .layui-tab-title .layui-this {
  color: #1e9fff;
  font-family: '微软雅黑';
  font-size: 14px;
}
.currcont {
  margin-top: 30px;
}
.currChart {
  margin-top: 30px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
</style>
